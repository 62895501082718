import { Sections } from '../sections';
import { ShortcutIds } from '../shortcutIds';
import type { ShortcutConfig } from '../types';

export const profilesSection: ShortcutConfig = [
  {
    id: ShortcutIds.CREATE_COMPOUND_DRUG,
    description: 'Create Compound Drug',
    shortcut: ['alt', 'shift', 'KeyC'],
    trigger: true,
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles/drugs'
  },
  {
    id: ShortcutIds.CREATE_NEW_PRESCRIBER,
    description: 'Create New Prescriber',
    shortcut: ['alt', 'shift', 'KeyP'],
    trigger: true,
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles/prescribers'
  },
  {
    id: ShortcutIds.OPEN_PATIENT_PROFILE,
    description: 'Navigate to Patient Profile',
    quickFindDescription: 'Profiles > Patient',
    shortcut: ['ctrl', 'KeyE'],
    navigate: '/rx/pharmacy-sc/profiles/patients/list',
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profile'
  },
  {
    id: ShortcutIds.OPEN_PRESCRIBER_PROFILE,
    description: 'Navigate to Prescriber Profile',
    quickFindDescription: 'Profiles > Prescriber',
    navigate: '/rx/pharmacy-sc/profiles/prescribers/list',
    shortcut: ['ctrl', 'KeyR'],
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles'
  },
  {
    id: ShortcutIds.OPEN_DRUG_PROFILE,
    description: 'Navigate to Drug Profile',
    quickFindDescription: 'Profiles > Drug',
    navigate: '/rx/pharmacy-sc/profiles/drugs/list',
    shortcut: ['ctrl', 'KeyD'],
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles'
  },
  {
    id: ShortcutIds.OPEN_INSURANCE_PROFILE,
    description: 'Navigate to Insurance Profile',
    quickFindDescription: 'Profiles > Insurance',
    navigate: '/rx/pharmacy-sc/profiles/insurances/list',
    shortcut: ['ctrl', 'KeyI'],
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles'
  },
  {
    id: ShortcutIds.OPEN_SIG_PROFILE,
    description: 'Navigate to SIG Profile',
    quickFindDescription: 'Profiles > SIG',
    navigate: '/rx/pharmacy-sc/profiles/sigs/list',
    shortcut: ['ctrl', 'KeyS'],
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles'
  },
  {
    id: ShortcutIds.OPEN_FACILITIES_PROFILE,
    description: 'Navigate to Facilities Profile',
    quickFindDescription: 'Profiles > Facilities',
    navigate: '/rx/pharmacy-sc/profiles/facilities/list',
    shortcut: ['ctrl', 'KeyF'],
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles'
  },

  {
    id: ShortcutIds.OPEN_PRICE_PLAN_PROFILE,
    description: 'Navigate to Price Plan Profile',
    quickFindDescription: 'Profiles > Price Plans',
    navigate: '/rx/pharmacy-sc/profiles/price-plans/list',
    shortcut: ['ctrl', 'KeyL'],
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles'
  }
];
