import { numberTextFieldChangeHandler } from '@linx-ui/shared/utils';
import { NumberTextFieldStyled } from './NumberTextField.styled';
import type { NumberTextFieldProps } from './types';

const invalidChars = ['-', '+', 'e'];

export const NumberTextField = (props: NumberTextFieldProps) => {
  return (
    <NumberTextFieldStyled
      {...props}
      type="number"
      onKeyDown={(e) => {
        const isInvalidChar = invalidChars.includes(e.key);
        const value = (e.target as HTMLInputElement).value;
        const isReachedMinValue = (value === '0' || value === '') && e.key === 'ArrowDown';
        if (isInvalidChar || isReachedMinValue) e.preventDefault();
        props.onKeyDown?.(e);
      }}
      onChange={numberTextFieldChangeHandler(props.onChange)}
    />
  );
};
