import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { useEffect } from 'react';
import { type FormStepperProps } from './types';

const FormStepper: React.FC<FormStepperProps> = ({
  activeStep,
  stepStates,
  scrollPrefix,
  scrollIndex
}: FormStepperProps) => {
  const scrollToSection = (index: number) => () =>
    document.getElementById(`${scrollPrefix}-${index}`)?.scrollIntoView();

  useEffect(() => {
    scrollIndex && scrollToSection(scrollIndex)();
  }, [scrollIndex]);

  return (
    <Stepper nonLinear activeStep={activeStep} orientation="vertical">
      {stepStates.map((step, index) => (
        <Step key={step.label} completed={step.isComplete}>
          <StepButton color="inherit" onClick={scrollToSection(index)}>
            <StepLabel {...step.labelProps}>{step.label}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export { FormStepper };
