import { TextStyled } from '../styles';
import { GreetingWrapper } from './Greeting.styled';

const Greeting: React.FC<{ title: string; subTitle: string }> = ({ title, subTitle }) => {
  return (
    <GreetingWrapper>
      <TextStyled variant="h5" data-testid="greeting">
        {title}
      </TextStyled>
      {subTitle && <TextStyled variant="subtitle1">{subTitle}</TextStyled>}
    </GreetingWrapper>
  );
};
export { Greeting };
