import { Sections } from '../sections';
import { ShortcutIds } from '../shortcutIds';
import type { ShortcutConfig } from '../types';

const PHARMACY_SC_ROUTE = '/rx/pharmacy-sc';
const PHARMACY_N_ADMIN_ROUTE = '/rx';

export const GeneralSection: ShortcutConfig = [
  {
    id: ShortcutIds.OPEN_DASHBOARD,
    description: 'Open Dashboard',
    quickFindDescription: 'Dashboard',
    shortcut: ['alt', 'KeyD'],
    navigate: '/rx/pharmacy-sc/dashboard',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_QUEUES,
    description: 'Open Queues',
    quickFindDescription: 'Queues',
    shortcut: ['alt', 'KeyQ'],
    navigate: '/rx/pharmacy-sc/queues',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_PROFILES,
    description: 'Open Profiles',
    quickFindDescription: 'Profiles',
    shortcut: ['alt', 'KeyP'],
    navigate: '/rx/pharmacy-sc/profiles',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_PRESCRIPTIONS,
    description: 'Open Prescriptions',
    quickFindDescription: 'Prescriptions',
    shortcut: ['alt', 'KeyE'],
    navigate: '/rx/pharmacy-sc/prescriptions',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.CURRENT_INVENTORY,
    description: 'Open Current Inventory',
    quickFindDescription: 'Current Inventory',
    shortcut: ['alt', 'KeyI'],
    navigate: '/rx/pharmacy-sc/inventory/current-inventory',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.ERX_MESSAGES,
    description: 'Open ERx Messages',
    quickFindDescription: 'ERx Messages',
    shortcut: ['alt', 'KeyX'],
    navigate: '/rx/pharmacy-sc/erx-messages',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_DELIVERIES,
    description: 'Open Deliveries',
    quickFindDescription: 'Deliveries',
    shortcut: ['alt', 'KeyL'],
    navigate: '/rx/pharmacy-sc/deliveries',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_RECYCLE_BIN,
    description: 'Open Recycle Bin',
    quickFindDescription: 'Recycle Bin',
    shortcut: ['alt', 'KeyR'],
    navigate: '/rx/pharmacy-sc/recycle-bin',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_USER_MANAGEMENT,
    description: 'Open User Management',
    quickFindDescription: 'User Management',
    shortcut: ['alt', 'KeyU'],
    navigate: '/rx/pharmacy-sc/user-management',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_CONFIGURATIONS,
    description: 'Open Configurations',
    quickFindDescription: 'Configurations',
    shortcut: ['alt', 'KeyG'],
    navigate: '/rx/pharmacy-sc/configurations',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.SHORTCUT_HELP_MENU,
    description: 'Show shortucts help',
    shortcut: ['ctrl', 'Slash'],
    trigger: true,
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_REPORTS,
    description: 'Open Reports',
    quickFindDescription: 'Reports',
    shortcut: ['alt', 'KeyO'],
    navigate: '/rx/pharmacy-sc/reports',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.OPEN_SUPPORT,
    description: 'Open Support',
    quickFindDescription: 'Support',
    shortcut: ['alt', 'KeyS'],
    navigate: '/rx/pharmacy-sc/support',
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.CREATE_NEW_ENTITY,
    description: 'Create Entity',
    shortcut: ['alt', 'KeyN'],
    trigger: true,
    section: Sections.GENERAL,
    enableFor: PHARMACY_N_ADMIN_ROUTE
  },
  {
    id: ShortcutIds.UPDATE_ENTITY,
    description: 'Update Entity',
    shortcut: ['alt', 'shift', 'KeyE'],
    trigger: true,
    section: Sections.GENERAL,
    enableFor: PHARMACY_N_ADMIN_ROUTE
  },
  {
    id: ShortcutIds.OPEN_NOTES_AND_DOCUMENTS,
    description: 'Open Notes and Documents',
    shortcut: ['alt', 'shift', 'KeyN'],
    trigger: true,
    section: Sections.GENERAL,
    enableFor: PHARMACY_SC_ROUTE
  },
  {
    id: ShortcutIds.QUICK_FIND,
    description: 'Quick Find Shortcuts',
    shortcut: ['ctrl', 'KeyP'],
    trigger: true,
    section: Sections.GENERAL,
    enableFor: PHARMACY_N_ADMIN_ROUTE
  }
];
