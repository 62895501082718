import { Replay, SettingsOutlined } from '@mui/icons-material';
import { Checkbox, IconButton, ListSubheader, MenuItem, Tooltip } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { theme } from '@linx-ui/shared/theming';
import { IconContainer, MenuStyled } from './ColumnSettings.styled';
import { type Props } from './types';

export const ColumnSettings: React.FC<Props> = memo(
  ({
    getAllColumns,
    HideColumnsIcon = <SettingsOutlined fontSize="medium" />,
    enableHideColumns,
    resetColumns,
    toggleColumnVisibility
  }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleResetColumns = useCallback(() => {
      resetColumns();
      setAnchorEl(null);
    }, [resetColumns]);

    return (
      <IconContainer>
        <Tooltip title="Hide columns">
          <IconButton sx={{ color: 'light.action.active' }} onClick={handleClick} data-testid="hide-columns-btn">
            {HideColumnsIcon}
          </IconButton>
        </Tooltip>
        <MenuStyled
          id="show-hide-columns-ctrl-menu"
          data-testid="show-hide-columuns-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'show-hide-columns'
          }}
        >
          <ListSubheader sx={{ backgroundColor: 'background.default' }}>Column Order/Visibility Settings</ListSubheader>
          <MenuItem dense onClick={handleResetColumns}>
            <Replay fontSize="small" sx={{ mr: theme.spacing(2) }} /> Reset
          </MenuItem>
          {enableHideColumns && (
            <ListSubheader sx={{ backgroundColor: 'background.default' }}>Column Visibility</ListSubheader>
          )}
          {enableHideColumns &&
            getAllColumns()
              .filter((leaf) => leaf.getCanHide())
              .map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;
                const header = value.columnDef.header as string;
                return (
                  <MenuItem dense key={value.id} onClick={toggleColumnVisibility(value)}>
                    <Checkbox
                      checked={value.getIsVisible()}
                      onChange={toggleColumnVisibility(value)}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                    {header}
                  </MenuItem>
                );
              })}
        </MenuStyled>
      </IconContainer>
    );
  }
);

ColumnSettings.displayName = 'ColumnSettings';
