import get from 'lodash/get';
import { nanoid } from 'nanoid';
import { Dialog } from '@linx-ui/shared/components/dialog';
import { DetailsGridButtonStyled, DetailsGridWrapper } from './DetailsGrid.styles';
import { GridItem } from './gridItem';
import { labels } from './labels';
import { type GridProps } from './types';

export const getGridItems = (items: any[], data: any = {}) => {
  return items.map((item) => {
    return (
      <GridItem
        key={nanoid()}
        formatter={item.formatter}
        label={item.label}
        value={
          item.accessor ? item.accessor(data) : typeof item.field === 'string' ? get(data, item.field) : item.field
        }
        width={item.width}
        labelStyle={item.labelStyle}
        getStyle={item.getStyle}
        testId={item.testId}
      />
    );
  });
};

const DetailsGrid: React.FC<GridProps> = ({ fields, data, remove, isArray, style }: GridProps) => (
  <DetailsGridWrapper style={style}>
    {!isArray && getGridItems(fields, data)}
    {isArray && data?.map((dataItem: any) => getGridItems(fields, dataItem))}
    {remove && (
      <Dialog
        title={remove.confirmationTitle}
        confirmBtnText={labels.remove}
        confirmBtnColor="error"
        onConfirm={remove.onClick}
        identifier={data.id}
        TriggerComponent={(props: { onClick: () => void }) => (
          <DetailsGridButtonStyled size="small" onClick={props.onClick} data-testid="remove-btn">
            {labels.remove}
          </DetailsGridButtonStyled>
        )}
      >
        <>{remove.confirmationMessage}</>
      </Dialog>
    )}
  </DetailsGridWrapper>
);

export { DetailsGrid };
