import { Button } from '@mui/material';

const CancelAction = ({
  onClick,
  btnText = 'Cancel',
  disabled = false
}: {
  onClick: () => void;
  btnText?: string;
  disabled?: boolean;
}) => {
  return (
    <Button data-testid="cancel-btn" onClick={onClick} disabled={disabled} size="large">
      {btnText}
    </Button>
  );
};

const SubmitAction = ({
  onClick,
  btnText = 'Add',
  disabled = false
}: {
  onClick: () => void;
  btnText?: string;
  disabled?: boolean;
}) => {
  return (
    <Button data-testid="submit-btn" variant="contained" size="large" disabled={disabled} onClick={onClick}>
      {btnText}
    </Button>
  );
};

export { CancelAction, SubmitAction };
