import { type DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { type Column, type Table } from '@tanstack/react-table';
import { intersection } from 'lodash';
import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { getFromLocalStorage, saveToLocalStorage } from '@linx-ui/shared/utils';

export const useColumnOrder = (name: string, table: Table<any>) => {
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({});

  const updateColumnVisiblity = (visiblityObj: Record<string, boolean>) => {
    setColumnVisibility(() => {
      table.setColumnVisibility(visiblityObj);
      return visiblityObj;
    });
  };

  const updateColumnOrder = (order: string[]) => {
    setColumnOrder(() => {
      table.setColumnOrder(order);
      return order;
    });
  };

  useEffect(() => {
    if (columnOrder.length === 0 && table) {
      const savedColumnOrder = getFromLocalStorage(`${name}-column-order`) as string[] | undefined;
      const defaultColumnOrder = table.getAllLeafColumns().map((column) => column.id);
      if (savedColumnOrder && intersection(savedColumnOrder, defaultColumnOrder).length === defaultColumnOrder.length) {
        updateColumnOrder(savedColumnOrder);
      } else {
        updateColumnOrder(defaultColumnOrder);
      }
      const savedColumnVisibility = getFromLocalStorage(`${name}-column-visibility`) as
        | Record<string, boolean>
        | undefined;
      const defaultColumnVisibility = table.getAllLeafColumns().reduce<Record<string, boolean>>((acc, column) => {
        acc[column.id] = true;
        return acc;
      }, {});

      if (
        savedColumnVisibility &&
        intersection(Object.keys(savedColumnVisibility), defaultColumnOrder).length === defaultColumnOrder.length
      ) {
        updateColumnVisiblity(savedColumnVisibility);
      } else {
        updateColumnVisiblity(defaultColumnVisibility);
      }
    }
  }, [table]);

  const resetColumns = () => {
    flushSync(() => table.resetColumnOrder(true));
    setColumnOrder(() => {
      const defaultColumnOrder = table.getAllLeafColumns().map((column) => column.id);
      saveToLocalStorage(`${name}-column-order`, defaultColumnOrder);
      return defaultColumnOrder;
    });

    const defaultColumnVisibility = table.getAllLeafColumns().reduce<Record<string, boolean>>((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {});

    flushSync(() => table.setColumnVisibility(defaultColumnVisibility));
    setColumnVisibility(() => {
      saveToLocalStorage(`${name}-column-visibility`, defaultColumnVisibility);
      return defaultColumnVisibility;
    });
  };

  const toggleColumnVisibility = (column: Column<unknown, unknown>) => () => {
    const isVisible = column.getIsVisible();
    setColumnVisibility((columnVisibility) => {
      const newColumnVisibility = { ...columnVisibility, [column.id]: !isVisible };
      saveToLocalStorage(`${name}-column-visibility`, newColumnVisibility);
      table.setColumnVisibility(newColumnVisibility);
      return newColumnVisibility;
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = over.id === 'selection-col' ? 1 : columnOrder.indexOf(over.id as string);
        const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
        saveToLocalStorage(`${name}-column-order`, newColumnOrder);
        table.setColumnOrder(newColumnOrder);
        return newColumnOrder;
      });
    }
  };

  return { columnOrder, handleDragEnd, resetColumns, columnVisibility, toggleColumnVisibility };
};
