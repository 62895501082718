import { styled } from '@mui/material';

export const NotificationItemCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 2, 1.5, 2),
  background: theme.palette.background.paper,
  flexDirection: 'column',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider
}));
