import moment from 'moment-timezone';
import { dashYYYYMMDDToSlashMMDDYYYY, getDisplayDateTimeWithSlashFormatter } from '../dateTimeUtils';
import { type ParsedNode } from './types';

export const parseXML = (xmlString = ''): ParsedNode => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  const rootElement = xmlDoc.documentElement;

  return parseNode(rootElement);
};

const valueFormatter = (value: string) => {
  if (moment(value, 'YYYY-MM-DD', true).isValid()) {
    return dashYYYYMMDDToSlashMMDDYYYY(value);
  } else if (!isFinite(Number(value)) && moment(value, moment.ISO_8601, true).isValid()) {
    return getDisplayDateTimeWithSlashFormatter(value);
  }
  return value;
};

export const parseNode = (node: Element): ParsedNode => {
  const parsedNode: ParsedNode = {
    name: node.nodeName
  };

  // Parse attributes
  if (node.attributes.length > 0) {
    parsedNode.attributes = {};
    for (let i = 0; i < node.attributes.length; i++) {
      const attribute = node.attributes[i];
      parsedNode.attributes[attribute.name] = attribute.value;
    }
  }

  // Parse children
  if (node.childNodes.length > 0) {
    parsedNode.children = [];
    for (let i = 0; i < node.childNodes.length; i++) {
      const child = node.childNodes[i];
      if (child.nodeType === Node.ELEMENT_NODE) {
        parsedNode.children.push(parseNode(child as Element));
      } else if (child.nodeType === Node.TEXT_NODE && child.nodeValue) {
        parsedNode.value = valueFormatter(child.nodeValue);
      }
    }
  }

  return parsedNode;
};
