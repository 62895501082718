import {
  GetPlaceCommand,
  LocationClient,
  SearchPlaceIndexForSuggestionsCommand,
  type Place,
  type SearchForSuggestionsResult
} from '@aws-sdk/client-location';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { ADDRESS_AUTOCOMPLETE } from '@linx-ui/shared/constants';
import { zipFormatter } from '@linx-ui/shared/utils';
import { type Address } from '../types';

const { region, indexName, identityPoolId, filterCountries } = ADDRESS_AUTOCOMPLETE;

const transformPlaceData = (place: Place | undefined): Address => {
  const address = {
    addressLine1: getAddressLine1(place),
    city: place?.Municipality,
    state: place?.Region,
    zip: zipFormatter(place?.PostalCode)
  };

  return address;
};

export const getAddressLine1 = (place: Place | undefined) => {
  const addressNumber = place?.AddressNumber;
  const street = place?.Street;

  if (addressNumber && street) {
    return `${addressNumber} ${street}`;
  }
  if (street) {
    return street;
  }
  if (addressNumber) {
    return addressNumber;
  }

  return '';
};

const client = new LocationClient({
  region,
  credentials: fromCognitoIdentityPool({
    identityPoolId: identityPoolId!,
    clientConfig: { region }
  })
});

const getSuggestions = async (keyword: string): Promise<SearchForSuggestionsResult[] | undefined> => {
  const params = {
    IndexName: indexName,
    Text: keyword,
    FilterCountries: filterCountries,
    MaxResults: 15
  };

  const command = new SearchPlaceIndexForSuggestionsCommand(params);
  try {
    return (await client.send(command)).Results;
  } catch {
    return [];
  }
};

const getAddressDetails = async (placeId: string): Promise<Address> => {
  const getPlaceCommand = new GetPlaceCommand({
    IndexName: indexName,
    PlaceId: placeId
  });
  try {
    return transformPlaceData((await client.send(getPlaceCommand)).Place);
  } catch {
    return {} as unknown as Address;
  }
};

export { getAddressDetails, getSuggestions };
