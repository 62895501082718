import { useAuth0 } from '@auth0/auth0-react';
import axios, {
  type AxiosRequestConfig,
  type AxiosRequestHeaders,
  type AxiosResponseHeaders,
  type RawAxiosResponseHeaders
} from 'axios';
import { useState } from 'react';
import { API_URL } from '@linx-ui/shared/constants';
import { useGlobalContext } from '@linx-ui/shared/context';
import { triggerDownload } from '@linx-ui/shared/utils/helpers';
import { getHeadersFromKeys } from '../../utils';

export const useDownloadFile = ({
  url,
  headerKeys = [],
  params = {},
  accept,
  fileName,
  isAuthTokenRequired
}: {
  url?: string;
  headerKeys?: string[];
  params?: AxiosRequestConfig['params'];
  accept?: AxiosRequestHeaders['Accept'];
  fileName?: string | ((headers: RawAxiosResponseHeaders | AxiosResponseHeaders) => string);
  isAuthTokenRequired?: boolean;
}) => {
  const [downloading, setDownloading] = useState(false);
  const headers = getHeadersFromKeys(headerKeys);
  const { setSnackBarState } = useGlobalContext();
  const auth = useAuth0();

  const download = async (paramUrl?: string) => {
    const finalUrl = paramUrl || url;
    const authHeader = isAuthTokenRequired ? { Authorization: `Bearer ${await auth.getAccessTokenSilently()}` } : {};
    setDownloading(true);
    finalUrl &&
      fileName &&
      axios
        .get(finalUrl, {
          baseURL: API_URL,
          params,
          responseType: 'arraybuffer',
          headers: {
            ...headers,
            ...authHeader,
            Accept: accept
          }
        })
        .then((response) => {
          triggerDownload(response.data, typeof fileName === 'string' ? fileName : fileName(response.headers));
          return null;
        })
        .catch((e) => {
          setSnackBarState({
            open: true,
            severity: 'error',
            title: 'File Download Failed',
            messages: ['Error downloading file. Please try again!']
          });
        })
        .finally(() => {
          setDownloading(false);
        });
  };

  return { downloading, download };
};
