import { useEffect, useState } from 'react';
import { type StepperProps } from './types';

export const useStepperWithForm = <T,>({
  data,
  onFormComplete,
  stepsDefaultState,
  setIsFormComplete
}: StepperProps<T>) => {
  const formDataInit: T = data ?? ({} as unknown as T);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepStates, setStepStates] = useState(stepsDefaultState);
  const [formData, setFormData] = useState<T>(formDataInit);

  const setIsStepComplete = (index: number, isComplete: boolean) => {
    setStepStates((previousState) => previousState.map((v, k) => (k === index ? { ...v, isComplete } : v)));
  };

  const setActiveStepCallback = (index: number): void => {
    setActiveStep(index);
  };

  const setErrorStep = (index: number, hasError: boolean) => {
    setStepStates((previousState) =>
      previousState.map((v, k) => (k === index ? { ...v, labelProps: { ...v.labelProps, error: hasError } } : v))
    );
  };

  const isNestedObject = (obj: object, property: string) => {
    const key = property as keyof typeof obj;
    return typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key]);
  };
  const mergeObjects = (obj1: any, obj2: any) => {
    const merged = { ...obj1 };

    for (const key in obj2) {
      if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        if (isNestedObject(obj2, key)) {
          if (isNestedObject(merged, key)) {
            merged[key] = mergeObjects(merged[key], obj2[key]);
          } else {
            merged[key] = { ...obj2[key] };
          }
        } else {
          merged[key] = obj2[key];
        }
      }
    }

    return merged;
  };

  const onChange = (data: T) => {
    setFormData((previousData) => mergeObjects(previousData, data));
  };

  useEffect(() => {
    if (stepStates.reduce((p, v) => p && v.isComplete, true)) {
      onFormComplete(formData);
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [JSON.stringify(stepStates.map((v) => v.isComplete)), JSON.stringify(formData)]);

  return {
    formData,
    setIsStepComplete,
    setActiveStepCallback,
    setErrorStep,
    onChange,
    stepStates,
    activeStep
  };
};
