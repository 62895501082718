import type { FC } from 'react';
import { ContainerContent, ContainerHeader, HeaderWrapper, StyledCard } from './Container.styled';
import type { Props } from './types';

const Container: FC<Props> = ({ id, title, children, style, contentStyle, header, variant = 'secondary', ...rest }) => {
  return (
    <StyledCard variant="outlined" style={style} id={id} {...rest}>
      {header ?? (
        <HeaderWrapper>
          <ContainerHeader title={title} variant={variant} />
        </HeaderWrapper>
      )}
      <ContainerContent style={contentStyle}>{children}</ContainerContent>
    </StyledCard>
  );
};

export { Container };
