import { East } from '@mui/icons-material';
import capitalize from 'lodash/capitalize';
import type { FC } from 'react';
import { NotificationThemedText } from '../styled';
import { QueueStatusCnt } from './NotificationQueueStatus.styled';

export const NotificationQueueStatus: FC<{ from: string; to: string }> = ({ from, to }) => {
  return (
    <QueueStatusCnt>
      <NotificationThemedText>{capitalize(from)}</NotificationThemedText>
      <East style={{ fontSize: '14px' }} color="disabled" />
      <NotificationThemedText>{capitalize(to)}</NotificationThemedText>
    </QueueStatusCnt>
  );
};
