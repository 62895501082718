import { getToken } from 'firebase/messaging';
import { useEffect } from 'react';
import { urls } from '@linx-ui/shared/constants';
import { useGlobalContext } from '@linx-ui/shared/context';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import { isLinxAdmin } from '@linx-ui/shared/utils';
import { messaging } from './app';

export const useFirebaseRegisterClient = (appName: string) => {
  const { auth } = useGlobalContext();
  const linxAdmin = isLinxAdmin(auth);

  const { mutate } = useReactMutation({
    url: urls.registerFirebaseClient,
    method: 'POST'
  });

  useEffect(() => {
    if (messaging && !linxAdmin && 'serviceWorker' in navigator) {
      const fbServiceWorkerUrl =
        process.env.NODE_ENV === 'production' ? `/${appName}/firebase-messaging-sw.js` : `/firebase-messaging-sw.js`;
      navigator.serviceWorker
        .register(fbServiceWorkerUrl, { scope: `/${appName}/firebase-cloud-messaging-push-scope` })
        .then(
          async (registration) =>
            await getToken(messaging!, {
              vapidKey: process.env.FIREBASE_VAPID_KEY,
              serviceWorkerRegistration: registration
            })
        )
        .then((currentToken) => {
          mutate({ token: currentToken });
          console.log('Successfully registered firebase service worker and able to fetch token also.');
          return null;
        })
        .catch((registrationError) => console.log('Unable to fetch the firebase token: ', registrationError));
    }
  }, []);
};
