export const dateValidator =
  ({ disablePast = false, disableFuture = false }: { disablePast?: boolean; disableFuture?: boolean } = {}) =>
  (value: any) => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const inputDate = new Date(value).setHours(0, 0, 0, 0);

    if (value === 'Invalid date') {
      return 'Please enter valid format - MM/DD/YYYY';
    }

    if (disablePast && inputDate < currentDate) {
      return 'Past dates are not allowed';
    }

    if (disableFuture && inputDate > currentDate) {
      return 'Future dates are not allowed';
    } else return true;
  };

export const timeValidator = (value: any) => {
  if (value === 'Invalid date') {
    return 'Please enter valid format - HH:MM AM/PM';
  } else return true;
};
