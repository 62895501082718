const states = [
  {
    code: 'AL',
    name: 'Alabama - AL'
  },
  {
    code: 'AK',
    name: 'Alaska - AK'
  },
  {
    code: 'AZ',
    name: 'Arizona - AZ'
  },
  {
    code: 'AR',
    name: 'Arkansas - AR'
  },
  {
    code: 'CA',
    name: 'California - CA'
  },
  {
    code: 'CO',
    name: 'Colorado - CO'
  },
  {
    code: 'CT',
    name: 'Connecticut - CT'
  },
  {
    code: 'DC',
    name: 'District of Columbia - DC'
  },
  {
    code: 'DE',
    name: 'Delaware - DE'
  },
  {
    code: 'FL',
    name: 'Florida - FL'
  },
  {
    code: 'GA',
    name: 'Georgia - GA'
  },
  {
    code: 'HI',
    name: 'Hawaii - HI'
  },
  {
    code: 'ID',
    name: 'Idaho - ID'
  },
  {
    code: 'IL',
    name: 'Illinois - IL'
  },
  {
    code: 'IN',
    name: 'Indiana - IN'
  },
  {
    code: 'IA',
    name: 'Iowa - IA'
  },
  {
    code: 'KS',
    name: 'Kansas - KS'
  },
  {
    code: 'KY',
    name: 'Kentucky - KY'
  },
  {
    code: 'LA',
    name: 'Louisiana - LA'
  },
  {
    code: 'ME',
    name: 'Maine - ME'
  },
  {
    code: 'MD',
    name: 'Maryland - MD'
  },
  {
    code: 'MA',
    name: 'Massachusetts - MA'
  },
  {
    code: 'MI',
    name: 'Michigan - MI'
  },
  {
    code: 'MN',
    name: 'Minnesota - MN'
  },
  {
    code: 'MS',
    name: 'Mississippi - MS'
  },
  {
    code: 'MO',
    name: 'Missouri - MO'
  },
  {
    code: 'MT',
    name: 'Montana - MT'
  },
  {
    code: 'NE',
    name: 'Nebraska - NE'
  },
  {
    code: 'NV',
    name: 'Nevada - NV'
  },
  {
    code: 'NH',
    name: 'New Hampshire - NH'
  },
  {
    code: 'NJ',
    name: 'New Jersey - NJ'
  },
  {
    code: 'NM',
    name: 'New Mexico - NM'
  },
  {
    code: 'NY',
    name: 'New York - NY'
  },
  {
    code: 'NC',
    name: 'North Carolina - NC'
  },
  {
    code: 'ND',
    name: 'North Dakota - ND'
  },
  {
    code: 'OH',
    name: 'Ohio - OH'
  },
  {
    code: 'OK',
    name: 'Oklahoma - OK'
  },
  {
    code: 'OR',
    name: 'Oregon - OR'
  },
  {
    code: 'PA',
    name: 'Pennsylvania - PA'
  },
  {
    code: 'RI',
    name: 'Rhode Island - RI'
  },
  {
    code: 'SC',
    name: 'South Carolina - SC'
  },
  {
    code: 'SD',
    name: 'South Dakota - SD'
  },
  {
    code: 'TN',
    name: 'Tennessee - TN'
  },
  {
    code: 'TX',
    name: 'Texas - TX'
  },
  {
    code: 'UT',
    name: 'Utah - UT'
  },
  {
    code: 'VT',
    name: 'Vermont - VT'
  },
  {
    code: 'VA',
    name: 'Virginia - VA'
  },
  {
    code: 'WA',
    name: 'Washington - WA'
  },
  {
    code: 'WV',
    name: 'West Virginia - WV'
  },
  {
    code: 'WI',
    name: 'Wisconsin - WI'
  },
  {
    code: 'WY',
    name: 'Wyoming - WY'
  }
];

const getOptionLabel = (option: string) => states.find((o) => o.code === option)?.name ?? '';

export { states, getOptionLabel };
