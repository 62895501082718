import { Box, styled } from '@mui/material';

export const LoaderWrapper = styled(Box)(() => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});
