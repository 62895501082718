import { flattenObj, removeEmptyValues } from '@linx-ui/shared/utils';

const hasChanged = (formData: object, initialData: object = {}) => {
  const initialDataObj = flattenObj(initialData);
  const newDataObj = removeEmptyValues(flattenObj(formData));
  if (Object.keys(initialDataObj).length !== Object.keys(newDataObj ?? {}).length) {
    return true;
  }
  return Object.keys(newDataObj ?? {}).some((c) => JSON.stringify(initialDataObj[c]) !== JSON.stringify(newDataObj[c]));
};

export { hasChanged };
