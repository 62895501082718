import { Delete, FileDownloadOutlined, FileOpenOutlined } from '@mui/icons-material';
import { CircularProgress, styled, Typography } from '@mui/material';
import React from 'react';
import { theme } from '@linx-ui/shared/theming';

const ListItemCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type'
})(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  width: '100%'
}));

const DownloadIcon = styled(FileDownloadOutlined)(({ theme }) => ({
  color: theme.palette.secondary.main
}));

const DeleteIcon = styled(Delete)(({ theme }) => ({
  color: theme.palette.error.main
}));

const FileIndicatorIcon = styled(FileOpenOutlined)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer'
}));

const CancelIconCnt = styled('div')(({ theme }) => ({
  flex: 2,
  gap: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));

const ProgressCnt = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%'
}));

const FileInfoCnt = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  flex: 3,
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden'
}));

const FileName = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      style={{
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        width: '85%',
        maxWidth: '85%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      variant="body1"
    >
      {children}
    </Typography>
  );
};

const CreatedBy = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography style={{ color: theme.palette.text.secondary }} variant="caption">
      {children}
    </Typography>
  );
};

const SmallCircularProgress = styled(CircularProgress)(({ theme }) => ({
  fontSize: theme.spacing(3)
}));

export {
  CancelIconCnt,
  CreatedBy,
  DeleteIcon,
  DownloadIcon,
  FileIndicatorIcon,
  FileInfoCnt,
  FileName,
  ListItemCnt,
  ProgressCnt,
  SmallCircularProgress
};
