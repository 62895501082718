import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '@linx-ui/shared/utils/axios';
import { getHeadersFromKeys } from '../../../utils';
import type { ApiErrorResponse } from '../useQueryHelpers/types';
import { useQueryHelpers } from '../useQueryHelpers/useQueryHelpers';
import type { UseReactMutationParams } from './types';

export const useReactMutation = <Data, PayloadData = any, InterceptedData = Data, Error = ApiErrorResponse>(
  config: UseReactMutationParams<Data, PayloadData, InterceptedData, Error>
) => {
  const {
    url,
    params,
    method,
    successMsg,
    successTitle,
    showErrorToast = true,
    showSuccessToast = true,
    errorMsg,
    severity,
    select,
    headerKeys = [],
    onSuccess,
    onError,
    shouldRedirectToErrorPage = true,
    headers: defaultHeaders = { 'Content-Type': 'application/json' },
    ...restParams
  } = config;
  const auth = useAuth0();
  const queryClient = useQueryClient();
  const headers = getHeadersFromKeys(headerKeys);
  const { raiseSuccessToast, raiseErrorToast, navigateToErrorPage } = useQueryHelpers({
    successTitle,
    successMsg,
    errorMsg,
    severity,
    showErrorToast,
    showSuccessToast
  });

  const queryResult = useMutation<InterceptedData, Error, PayloadData>({
    ...restParams,
    mutationFn: async (payload) => {
      const response = await axios<Data>({
        url,
        method,
        params,
        data: payload,
        headers: {
          ...{ ...headers, ...defaultHeaders },
          Authorization: defaultHeaders.Authorization ?? `Bearer ${await auth.getAccessTokenSilently()}`
        }
      });

      if ((response as unknown as ApiErrorResponse)?.error) {
        return await Promise.reject(response);
      }
      return (select ? select(response as Data) : response) as InterceptedData;
    },
    onError: (error, variables, context) => {
      raiseErrorToast(error);
      onError?.(error, variables, context);
      shouldRedirectToErrorPage && navigateToErrorPage(error);
    },
    onSuccess: (data, variables, context) => {
      raiseSuccessToast(data);
      // This is to invalidate the cache of the list or details page query
      switch (method) {
        case 'PATCH':
          void queryClient.invalidateQueries({ queryKey: [url, headers] }); // Details page query
          void queryClient.invalidateQueries({
            queryKey: [url.replace(/\/[^/]+$/, ''), headers] // List page query
          });
          break;
        default:
          break;
      }
      onSuccess?.(data, variables, context);
    }
  });

  return queryResult;
};
