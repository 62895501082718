import { pharmacistIdKey } from '@linx-ui/shared/constants';
import type { User } from '@linx-ui/shared/types';
import { getFromLocalStorage } from '@linx-ui/shared/utils';
import { useUsers } from '../useUsers';

export const useSelectedPharmacist = () => {
  const { users, isLoading } = useUsers();

  return { pharmacist: users?.find((user: User) => user.id === getFromLocalStorage(pharmacistIdKey)), isLoading };
};
