import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { type Permissions } from '@linx-ui/shared/context';
import { type Authentication, type Token } from './types';

const defaultAllPermissions = {
  create: true,
  read: true,
  update: true,
  delete: true
};

const usePermissions = (): Authentication => {
  const [token, setToken] = useState<string>('');
  const [isLinxAdmin, setIsLinxAdmin] = useState(false);
  const [isContractAdmin, setIsContractAdmin] = useState(false);
  const [userName, setUserName] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [typeId, setTypeId] = useState<string>('');
  const [permissions, setPermissions] = useState<Permissions>({} as unknown as Permissions);
  const [contractId, setContractId] = useState<string | undefined>();
  const [authorizedPharmacies, setAuthroizedPharmacies] = useState<string[] | undefined>();

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const translatePermissions = (permissions: string[]): Permissions =>
    permissions.reduce((acc: any, curr: string) => {
      const [action, entityName] = curr.split(':');
      acc[entityName] = {
        ...(acc[entityName] ?? {}),
        ...(action === '*' ? defaultAllPermissions : { [action]: true })
      };
      return acc;
    }, {});

  useEffect(() => {
    getAccessTokenSilently()
      .then((value) => {
        setToken(value);
        const decodedToken: Token = jwtDecode(value);
        setContractId(decodedToken.contractId ?? '');
        setAuthroizedPharmacies(decodedToken.pharmacies);
        setTypeId(decodedToken.typeId);
        setUserId(decodedToken.userId);
        setUserName(decodedToken.username);
        setPermissions(translatePermissions(decodedToken.permissions));
        setIsLinxAdmin(decodedToken.permissions.includes('*:admin'));
        setIsContractAdmin(!!decodedToken.contractId);
        return null;
      })
      .catch(() => {
        setToken('');
      });
  }, []);

  const authInformation = {
    isAuthenticated,
    isLinxAdmin,
    isContractAdmin,
    permissions,
    typeId,
    userName,
    userId,
    contractId,
    authorizedPharmacies,
    token
  };

  return authInformation;
};

export { usePermissions };
