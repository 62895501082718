import { ArrowForward, NotificationsNone, NotificationsOffOutlined } from '@mui/icons-material';
import { Button, Skeleton } from '@mui/material';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Notification } from '@linx-ui/shared/components/notifications/types';
import { pharmacyIdKey, urls } from '@linx-ui/shared/constants';
import { useToggleContext } from '@linx-ui/shared/context/ToggleContext';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import { useReactQuery } from '@linx-ui/shared/hooks/api/useReactQuery';
import { useSetQueryData } from '@linx-ui/shared/hooks/api/useSetQueryData';
import type { UserPreferences } from '@linx-ui/shared/types/userPreferences';
import { NotificationActionCnt, SubscribedButton, UnsubscribedButton } from './NotificationActions.styled';

export const NotificationActions: FC<{
  rxDetailsLink: string;
  color?: 'success' | 'warning';
  notification: Notification;
}> = ({ rxDetailsLink, notification, color = 'success' }) => {
  const navigate = useNavigate();
  const setQueryData = useSetQueryData();
  const [, toggleDrawer] = useToggleContext();

  const { mutate, isPending } = useReactMutation({
    url: urls.userPreferences,
    method: 'PATCH',
    headerKeys: [pharmacyIdKey],
    headers: { 'Content-Type': 'application/json-patch+json' },
    onSuccess: (data) => setQueryData({ url: urls.userPreferences, data, headerKeys: [pharmacyIdKey] })
  });

  const { data: userPreferences, isLoading: isLoadingUserPreferences } = useReactQuery<UserPreferences>({
    url: urls.userPreferences,
    headerKeys: [pharmacyIdKey]
  });

  const handlerRxDetailsClick = () => {
    toggleDrawer();
    navigate(rxDetailsLink);
  };

  const handleSubscribeBtnClick = (value: boolean) => () =>
    mutate({
      op: 'add',
      path: `/notification/${notification.type.toLocaleLowerCase()}/${[notification.category, notification.subCategory]
        .filter(Boolean)
        .join('~1')}`,
      value
    });

  return (
    <NotificationActionCnt>
      {isLoadingUserPreferences ? (
        <Skeleton width={100} />
      ) : userPreferences?.notification?.prescription?.[`${notification.category}/${notification.subCategory}`] ? (
        <UnsubscribedButton
          size="small"
          disabled={isPending}
          startIcon={<NotificationsOffOutlined />}
          onClick={handleSubscribeBtnClick(false)}
        >
          UNSUBSCRIBE
        </UnsubscribedButton>
      ) : (
        <SubscribedButton
          size="small"
          disabled={isPending}
          startIcon={<NotificationsNone />}
          onClick={handleSubscribeBtnClick(true)}
        >
          SUBSCRIBE
        </SubscribedButton>
      )}
      <Button size="small" color={color} endIcon={<ArrowForward />} onClick={handlerRxDetailsClick}>
        RX DETAILS
      </Button>
    </NotificationActionCnt>
  );
};
