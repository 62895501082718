import { useLocation } from 'react-router-dom';
import { pharmacyIdKey, urls, type UserTypes } from '@linx-ui/shared/constants';
import type { UseReactQueryParams } from '@linx-ui/shared/hooks/api/useReactQuery/types';
import { useReactQueryList } from '@linx-ui/shared/hooks/api/useReactQueryList';
import { type PaginatedResult, type UserType } from '@linx-ui/shared/types';

type Options = Partial<UseReactQueryParams<PaginatedResult<UserType>>>;

export const useUserTypes = (options?: Options) => {
  const { pathname } = useLocation();
  const isAdminDashboard = pathname.includes('admin-sc');
  const { data, ...restResult } = useReactQueryList<UserType>({
    url: urls.userTypes,
    headerKeys: isAdminDashboard ? [] : [pharmacyIdKey],
    ...options
  });

  const getUserTypeName = (id?: string) => data?.items?.find((type: UserType) => type.id === id)?.name as UserTypes;

  return {
    ...restResult,
    getUserTypeName,
    userTypes: data?.items,
    isLoading: restResult.isFetching && (data?.items?.length || 0) === 0
  };
};
