import { alpha, styled } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view';

export const TreeViewItemWithLeftBorder = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[200],
  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: '1rem',
      fontWeight: 600
    }
  },
  [`& .${`${treeItemClasses.content}.Mui-selected`}`]: {
    backgroundColor:
      theme.palette.mode === 'light' ? alpha(theme.palette.secondary.main, 0.25) : theme.palette.secondary.dark
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: '50%',
    backgroundColor:
      theme.palette.mode === 'light' ? alpha(theme.palette.secondary.main, 0.25) : theme.palette.secondary.dark,
    color: theme.palette.mode === 'dark' && theme.palette.secondary.contrastText,
    padding: theme.spacing(0, 1.2)
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.secondary, 0.4)}`
  }
}));
