import { useQueryClient, type InfiniteData } from '@tanstack/react-query';
import { getHeadersFromKeys } from '@linx-ui/shared/utils';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import type { PaginatedResult } from '@linx-ui/shared/types';
import { pharmacyIdKey, urls } from '@linx-ui/shared/constants';
import { QUERY_PARAMS_TO_FETCH } from '../constants';
import type { Notification } from '../types';

export const useNotificationMarkAllRead = () => {
  const queryClient = useQueryClient();
  const headers = getHeadersFromKeys([pharmacyIdKey]);
  const queryKeys = Object.values(QUERY_PARAMS_TO_FETCH).map((params) => [urls.notifications, headers, params]);

  const { mutate } = useReactMutation({
    url: urls.notificationsMarkAllAsRead,
    method: 'POST',
    headerKeys: [pharmacyIdKey],
    onMutate: async () => {
      await Promise.all(queryKeys.map(async (queryKey) => await queryClient.cancelQueries({ queryKey })));
      const previousData = queryKeys.map((queryKey) => queryClient.getQueryData(queryKey));
      queryKeys.forEach((queryKey) =>
        queryClient.setQueryData<InfiniteData<PaginatedResult<Notification>>>(queryKey, (data) => ({
          pageParams: data?.pageParams || [],
          pages:
            data?.pages?.map((page) => ({
              ...page,
              items: page.items.map((item) => ({ ...item, isRead: true })),
              metaData: { ...page.metaData, unreadCount: 0 }
            })) || []
        }))
      );
      return { previousData };
    },
    onError: (_error, _variables, context: any) =>
      queryKeys.forEach((queryKey, index) => queryClient.setQueryData(queryKey, context.previousData[index]))
  });

  return () => mutate({});
};
