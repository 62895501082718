import { Box, styled } from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.action.active,
  letterSpacing: '0.17px',
  height: '100%',
  width: '100%'
}));

const TableWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'subtractHeight'
})<{ subtractHeight: number }>(({ subtractHeight }) => ({
  width: '100%',
  height: `calc(100% - ${subtractHeight}px)`
}));

export { Wrapper, TableWrapper };
