import moment from 'moment-timezone';
import { CAPITAL_LETTER_OR_NUMBER, PHONE_NUMBER } from './validations';

const initCapFormatter = (value = '') => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

const underscoreToCapitalised = (value = '', escapeStrings: string[] = [], overrideValue = '') => {
  const tempVal = overrideValue || value;
  return tempVal
    ?.split('_')
    .map((val) => (escapeStrings.includes(val) ? val : initCapFormatter(val)))
    .join(' ');
};

const pascalToCapitalised = (str: string | undefined) => {
  if (!str) return null;
  let result = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] === str[i].toUpperCase() && str[i - 1] !== ' ' && str[i + 1] !== undefined) {
      if (str[i + 1] === str[i + 1].toUpperCase()) {
        return str;
      } else {
        result += ' ';
      }
    }
    result += str[i];
  }
  return result;
};

const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(PHONE_NUMBER);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

const capitalizedToSpaceSeperated = (value = '') => value.split(/(?=[A-Z])/).join(' ');

const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

const getInitials = (str: string) => {
  const names = str.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const ageFormatter = (date: string) => {
  const diff = moment().diff(moment(date), 'milliseconds');
  const duration = moment.duration(diff);
  return `${duration.years()}yr ${duration.months()}m`;
};

const escapeStringRegex = (value: string) => {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const camelCaseToSpaceSeperated = (value: string) => {
  const result = value.replace(CAPITAL_LETTER_OR_NUMBER, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export {
  ageFormatter,
  camelCaseToSpaceSeperated,
  camelize,
  capitalizedToSpaceSeperated,
  escapeStringRegex,
  formatPhoneNumber,
  getInitials,
  initCapFormatter,
  pascalToCapitalised,
  underscoreToCapitalised
};
