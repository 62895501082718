import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { TableBody, TableRow } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import React, { memo } from 'react';
import { isPositive } from '@linx-ui/shared/utils';
import {
  NoResultFound,
  TabelCellPadder,
  TableCellContent,
  TableCellLoading,
  TableCellNotFound,
  TableRowStyled
} from './CustomDataGrid.styled';
import { DragAlongCell } from './DragAlongCell';
import { LoadingSkeleton } from './Loading.component';
import type { DataGridBodyProps } from './types';

const _CustomDataGridBody: React.FC<DataGridBodyProps> = ({
  topPadding,
  bottomPadding,
  isRowClickable,
  virtualRows,
  rows,
  loading,
  useLoadingState,
  onRowClicked,
  getExpandedRowComponent,
  noDataFound,
  table,
  columnOrder,
  NoDataFound = 'No data found'
}) => {
  const loader = useLoadingState && loading;
  return (
    <TableBody>
      {!loader && isPositive(topPadding) && (
        <TableRow>
          <TabelCellPadder height={topPadding} />
        </TableRow>
      )}

      {!loader &&
        virtualRows.map((virtualRow) => {
          const row = rows[virtualRow.index];
          return (
            <React.Fragment key={'row-container-fragment-' + row.id}>
              <TableRowStyled
                clickable={isRowClickable}
                onClick={(event) => {
                  onRowClicked(row.original, event);
                }}
                key={row.id}
                hover
              >
                {row.getVisibleCells().map((cell) => (
                  <SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
                    <DragAlongCell cell={cell}>
                      <TableCellContent width={cell.column.getSize()}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCellContent>
                    </DragAlongCell>
                  </SortableContext>
                ))}
              </TableRowStyled>

              {getExpandedRowComponent(row, virtualRow)}
            </React.Fragment>
          );
        })}

      {!loader && isPositive(bottomPadding) && (
        <TableRow>
          <TabelCellPadder height={bottomPadding} />
        </TableRow>
      )}

      {noDataFound && !loading && (
        <TableRow data-testid="pms-data-grid-no-data">
          <TableCellNotFound colSpan={table.getAllLeafColumns().length}>
            <NoResultFound>{NoDataFound}</NoResultFound>
          </TableCellNotFound>
        </TableRow>
      )}

      {loading && (
        <TableRow data-testid="pms-data-grid-loader">
          <TableCellLoading colSpan={table.getAllLeafColumns().length}>
            <LoadingSkeleton />
          </TableCellLoading>
        </TableRow>
      )}
    </TableBody>
  );
};

export const CustomDataGridBody = memo(_CustomDataGridBody);
