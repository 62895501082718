import { Checkbox, Skeleton } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { memo, useCallback, useMemo, type FC } from 'react';
import { pharmacyIdKey, statusLabels, urls } from '@linx-ui/shared/constants';
import { useReactQuery } from '@linx-ui/shared/hooks/api/useReactQuery';
import type { StatusOption } from '@linx-ui/shared/types/changeStatus';
import { CheckboxGroupWrapper } from '../../../checkboxGroupWrapper';
import { CheckboxLabelWrapper } from '../../../checkboxGroupWrapper/CheckboxGroupWrapper.styled';
import type { WorkflowPreferencesProps } from './types';
import { CheckboxGroupListWrapper } from './WorkflowPreferences.styled';

export const WorkflowPreferences: FC<WorkflowPreferencesProps> = memo(
  ({ selectedValues, onSingleCheckboxChange, onGroupCheckboxChange }) => {
    const { data: statusOptions, isLoading: isStatusOptionsLoading } = useReactQuery<StatusOption[]>({
      url: urls.prescriptionStatusOptions,
      headerKeys: [pharmacyIdKey]
    });

    const isAllPrescriptionStatusesSelected = useMemo(
      () =>
        statusOptions?.every(({ statuses }) =>
          statuses.every(({ status }) => selectedValues.includes(`WORKFLOW_STATUS/${status}`))
        ),
      [statusOptions, selectedValues]
    );

    const isIndeterminate = useMemo(
      () =>
        !isAllPrescriptionStatusesSelected &&
        !!statusOptions?.find(
          ({ statuses }) => !!statuses.find(({ status }) => selectedValues.includes(`WORKFLOW_STATUS/${status}`))
        ),
      [statusOptions, selectedValues, statusOptions]
    );

    const handleWorkflowCheckboxChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) =>
        onGroupCheckboxChange(
          event.target.checked,
          statusOptions?.map(({ statuses }) => statuses.map(({ status }) => `WORKFLOW_STATUS/${status}`)).flat() || []
        ),
      [statusOptions]
    );

    if (isStatusOptionsLoading)
      return (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      );

    return (
      <>
        <CheckboxLabelWrapper
          fullWidth
          label="Workflow notifications"
          control={
            <Checkbox
              color="secondary"
              checked={isAllPrescriptionStatusesSelected}
              indeterminate={isIndeterminate}
              onChange={handleWorkflowCheckboxChange}
            />
          }
        />
        <CheckboxGroupListWrapper>
          {statusOptions?.map(({ queue, statuses }) => (
            <CheckboxGroupWrapper
              key={queue}
              selectedValues={selectedValues}
              label={`${capitalize(queue.toLocaleLowerCase().replace(/[^A-Z0-9]+/gi, ' '))} Queue`}
              onGroupCheckboxChange={onGroupCheckboxChange}
              onSingleCheckboxChange={onSingleCheckboxChange}
              options={statuses.map(({ status }) => ({
                value: `WORKFLOW_STATUS/${status}`,
                label: statusLabels[status]
              }))}
            />
          ))}
        </CheckboxGroupListWrapper>
      </>
    );
  }
);

WorkflowPreferences.displayName = 'PrescriptionPreferences';
