import { FormControlLabel, alpha, styled } from '@mui/material';

export const CheckboxLabelWrapper = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'fullWidth'
})<{ readOnly?: boolean; fullWidth?: boolean }>(({ theme, fullWidth, readOnly }) => ({
  flex: 1,
  minWidth: '172px',
  width: fullWidth ? '100%' : 'auto',
  cursor: readOnly ? 'default' : 'pointer',
  pointerEvents: readOnly ? 'none' : 'auto',
  userSelect: 'none',
  background: alpha(theme.palette.secondary.main, 0.04),
  margin: 0,
  paddingRight: theme.spacing(2),
  '.MuiFormControlLabel-label': {
    padding: '10px 0',
    textTransform: 'capitalize'
  }
}));

export const CheckboxListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingLeft: theme.spacing(4),
  flexWrap: 'wrap',
  marginTop: theme.spacing(1)
}));
