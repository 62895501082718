import { useEffect, useState, type MouseEventHandler } from 'react';
import { StyledImg, StyledInfoImg, StyledPopover } from './ImageWithHover.styled';
import { type ImageWithHoverProps } from './types';

const ImageWithHover = ({ src, action = 'hover', ...rest }: ImageWithHoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  let events: {
    onMouseEnter?: MouseEventHandler<HTMLImageElement>;
    onMouseLeave?: MouseEventHandler<HTMLImageElement>;
    onClick?: MouseEventHandler<HTMLImageElement>;
  } = {
    onMouseEnter: handlePopoverOpen,
    onMouseLeave: handlePopoverClose
  };

  const open = Boolean(anchorEl);

  const onClose = {
    onClose: handlePopoverClose
  };

  useEffect(() => {
    if (action === 'click') {
      events = {
        onClick: handlePopoverOpen
      };
    }
  }, [action]);

  const id = open ? 'img-over-popover' : undefined;

  return (
    <>
      <StyledImg aria-owns={id} aria-haspopup="true" src={src} {...events} {...rest} alt="original image" />
      <StyledPopover id={id} type={action} open={open} anchorEl={anchorEl} {...onClose}>
        <StyledInfoImg test-id="img-over-popover" src={src} alt="popover image" />
      </StyledPopover>
    </>
  );
};

export { ImageWithHover };
