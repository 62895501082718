import { Check } from '@mui/icons-material';
import { Button, Chip, Typography } from '@mui/material';
import { memo, type FC } from 'react';
import { CenterAlignedCnt, NotificationHeaderCnt } from './NotificationHeader.styled';
import { useNotificationMarkAllRead } from './useNotificationMarkAllRead';

export const NotificationHeader: FC<{ unreadCount: number }> = memo(({ unreadCount }) => {
  const markAllRead = useNotificationMarkAllRead();

  return (
    <NotificationHeaderCnt>
      <CenterAlignedCnt>
        <Typography variant="h5">List of all Notifications</Typography>
        {unreadCount > 0 && <Chip data-testid="unread-count-chip" color="primary" label={unreadCount} />}
      </CenterAlignedCnt>
      {unreadCount > 0 && (
        <Button variant="outlined" startIcon={<Check />} onClick={markAllRead}>
          MARK ALL READ
        </Button>
      )}
    </NotificationHeaderCnt>
  );
});
NotificationHeader.displayName = 'NotificationHeader';
