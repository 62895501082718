import { useEffect } from 'react';
import { useForm, type DefaultValues, type FieldErrors, type FieldValues } from 'react-hook-form';
import { type SectionFormProps } from '@linx-ui/shared/components/create';
import { type ValidationError } from '@linx-ui/shared/types';
import { hasHighlightedErrors } from '@linx-ui/shared/utils/helpers';

export const useFormWrapperWithSteps = <T extends FieldValues>({
  formData,
  stepIndex,
  onChange,
  setIsStepComplete,
  setErrorStep,
  setActiveStep,
  customIsValid
}: SectionFormProps<T>) => {
  const formValues = useForm<T>({ mode: 'onTouched', defaultValues: formData as DefaultValues<T> });
  const {
    watch,
    formState: { errors, touchedFields, isValid }
  } = formValues;

  useEffect(() => {
    setIsStepComplete(stepIndex, customIsValid !== undefined ? isValid && customIsValid : isValid);
  }, [isValid, customIsValid]);

  useEffect(() => {
    setErrorStep(stepIndex, hasHighlightedErrors(touchedFields, errors));
  }, [JSON.stringify(touchedFields), JSON.stringify(errors)]);

  useEffect(() => {
    onChange(watch());
  }, [JSON.stringify(watch())]);

  const onFocus = (): void => {
    setActiveStep(stepIndex);
  };

  const getValidationError = (key: string): ValidationError => {
    let hasError = false;
    let message = '';
    const keys = key.split('.');

    const error = keys.reduce((acc, curr) => {
      return acc?.[curr] as FieldErrors<T>;
    }, errors);

    hasError = !!error;
    message = (error as any)?.message;
    return { hasError, message };
  };

  return {
    onFocus,
    getValidationError,
    ...formValues
  };
};
