import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeaturePermissions } from '@linx-ui/shared/core';
import { getMatchedAbsRoute } from '@linx-ui/shared/hooks/useRouteMatch';
import type { NavigationItem, NavigationOption } from '../types';
import { getNavigationItems } from '../utils';
import { filterNavigationOption } from './filterNavigationOption';

export const useAppNavigation = (navigationOptions: NavigationOption[]) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hasPermissionToView = useFeaturePermissions();
  const [subItems, setSubItems] = useState<NavigationItem[]>([]);

  const filteredNavigationOptions = useMemo(() => filterNavigationOption(navigationOptions, hasPermissionToView), []);
  const allNavigationItems = useMemo(() => getNavigationItems(filteredNavigationOptions), [filteredNavigationOptions]);

  // Update subItems and handle edge cases when the pathname changes
  useEffect(() => {
    const selectedSideNavItemAbsRoute = getMatchedAbsRoute(
      allNavigationItems.map((el) => el.absRoute),
      pathname
    );

    const subItems = allNavigationItems.find((item) => item.absRoute === selectedSideNavItemAbsRoute)?.subItems || [];
    const selectedSubItemAbsRoute = getMatchedAbsRoute(
      subItems?.map((el) => el.route),
      pathname
    );

    // Move user to first tag if the current route is not found
    if (selectedSubItemAbsRoute === null && subItems.length > 0) {
      navigate(subItems[0].route);
    }

    setSubItems(subItems);
  }, [pathname, allNavigationItems]);

  return { subItems, filteredNavigationOptions };
};
