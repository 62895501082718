interface FileProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: React.ReactNode;
  type: UploadStatuses;
}

interface UploaderProps<Result> {
  file: File;
  url: string;
  entityId: string;
  headerKeys: string[];
  onCancel?: (index: number) => void;
  onSuccess?: (result: Result) => void;
  index: number;
  folderName?: string;
}

interface GetSignedUrlResult {
  fileId: string;
  uploadUrl: string;
}

enum UploadStatuses {
  WARNING = 'warning',
  SUCCESS = 'success',
  SECONDARY = 'secondary'
}

export { UploadStatuses, type FileProps, type GetSignedUrlResult, type MessageProps, type UploaderProps };
