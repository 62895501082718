import { Alert, Divider, Snackbar, styled, type AlertColor } from '@mui/material';

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '&.MuiSnackbar-anchorOriginBottomRight': {
    bottom: `${theme.spacing(10)}`,
    right: `${theme.spacing(2)}`
  },
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
  borderRadius: '4px'
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: '320px'
}));

const StyledDivider = styled(Divider)<{ severity: AlertColor }>(({ theme, severity }) => ({
  margin: theme.spacing(0.5, 0),
  borderColor: theme.palette[severity].dark
}));

export { StyledAlert, StyledDivider, StyledSnackbar };
