export const CLAIM_STATUS = {
  PAID: 'PAID',
  PAID_DUR: 'PAID_DUR',
  DUPLICATE_OF_PAID: 'DUPLICATE_OF_PAID',
  DUPLICATE_OF_PAID_DUR: 'DUPLICATE_OF_PAID_DUR',
  REJECTED: 'REJECTED',
  REJECTED_DUR: 'REJECTED_DUR',
  APPROVED: 'APPROVED',
  DUPLICATE_OF_APPROVED: 'DUPLICATE_OF_APPROVED',
  CAPTURED: 'CAPTURED',
  CAPTURED_DUR: 'CAPTURED_DUR',
  DUPLICATE_OF_CAPTURE: 'DUPLICATE_OF_CAPTURE',
  DUPLICATE_OF_CAPTURE_DUR: 'DUPLICATE_OF_CAPTURE_DUR',
  BENEFIT: 'BENEFIT',
  FAILED: 'FAILED',
  GATEWAY_ERROR: 'GATEWAY_ERROR',
  PA_DIFFERED: 'PA_DIFFERED'
};

export const APPROVED_CLAIM_STATUSES = [CLAIM_STATUS.APPROVED, CLAIM_STATUS.DUPLICATE_OF_APPROVED];

export const REJECTED_CLAIM_STATUSES = [CLAIM_STATUS.REJECTED, CLAIM_STATUS.REJECTED_DUR];

export const ERRORED_CLAIM_STATUSES = [CLAIM_STATUS.GATEWAY_ERROR, CLAIM_STATUS.FAILED, undefined];

export const CLAIM_TYPES = { B1: 'B1', B2: 'B2' };

export const PAID_CLAIM_STATUSES = [
  CLAIM_STATUS.PAID,
  CLAIM_STATUS.DUPLICATE_OF_PAID,
  CLAIM_STATUS.PAID_DUR,
  CLAIM_STATUS.DUPLICATE_OF_PAID_DUR
];
