import { styled } from '@mui/material';

export const ScrollableCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  overflow: 'auto',
  padding: theme.spacing(0, 1, 1, 1),
  '& > div': {
    overflow: 'visible',
    marginTop: theme.spacing(1)
  }
}));
