import { matchSorter } from 'match-sorter';
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  type FilterOptionsState
} from '@mui/material';
import { useCallback, type FC, type HTMLAttributes } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useLocation } from 'react-router-dom';
import { useKeyboard, useKeyboardTrigger } from '@linx-ui/shared/context';
import {
  codeToCharMap,
  ShortcutIds,
  type KeyboardShortcut,
  type KeyboardShortcutWithNavigate,
  type KeyboardShortcutWithTrigger,
  type ShortcutConfig
} from '@linx-ui/shared/constants';

export const ShortcutsQuickFind: FC = () => {
  const { getActiveShortcuts, activateShortcut } = useKeyboard();
  const { value: isOpen, toggle: toggleDialog, setFalse: closeDialog } = useBoolean(false);
  const location = useLocation();

  const handleShortcutSelect = (value: KeyboardShortcut | null) => {
    if (value) {
      activateShortcut(value.id);
    }
    closeDialog();
  };

  useKeyboardTrigger(ShortcutIds.QUICK_FIND, toggleDialog);

  const getShortcutKeys = (shortcut: KeyboardShortcut) => {
    return shortcut.shortcut.map((x) => codeToCharMap[x]?.toUpperCase() ?? x.toUpperCase()).join(' + ');
  };

  const isNavigationShortcut = (
    shortcut: KeyboardShortcutWithTrigger | KeyboardShortcutWithNavigate
  ): shortcut is KeyboardShortcutWithNavigate => {
    return !(shortcut as KeyboardShortcutWithTrigger).trigger;
  };

  const getOptionLabel = useCallback((option: KeyboardShortcutWithNavigate | KeyboardShortcutWithTrigger) => {
    if (isNavigationShortcut(option)) {
      return option.quickFindDescription;
    }
    return option.description;
  }, []);

  const getOptionElement = useCallback(
    (params: HTMLAttributes<HTMLLIElement>, option: KeyboardShortcutWithNavigate | KeyboardShortcutWithTrigger) => {
      return (
        <Grid component="li" p={1} pl={2} container alignItems="center" {...params}>
          <Grid xs={2} item>
            <Typography variant="body2" color="grey">
              {isNavigationShortcut(option) ? 'Navigate:' : 'Action:'}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="body2">{getOptionLabel(option)}</Typography>
          </Grid>
          {location.pathname.includes(option.enableFor) && (
            <Grid item xs={true} container justifyContent="flex-end">
              <Chip size="small" label={getShortcutKeys(option)} />
            </Grid>
          )}
        </Grid>
      );
    },
    [location]
  );

  const filterOptions = useCallback(
    (
      options: ShortcutConfig,
      { inputValue }: FilterOptionsState<KeyboardShortcutWithTrigger | KeyboardShortcutWithNavigate>
    ) => {
      const matched = matchSorter(options, inputValue, { keys: ['description', 'quickFindDescription'] });
      return matched;
    },
    []
  );

  return (
    <Dialog open={isOpen} onClose={closeDialog} fullWidth>
      <DialogContent>
        <Autocomplete
          data-testid="quick-find-autocomplete"
          autoHighlight
          options={getActiveShortcuts(true).filter((shortcut) => shortcut.id !== ShortcutIds.QUICK_FIND)}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => <TextField {...params} label="Quick Find" autoFocus />}
          onChange={(_, value) => {
            handleShortcutSelect(value);
          }}
          renderOption={getOptionElement}
          filterOptions={filterOptions}
        />
      </DialogContent>
    </Dialog>
  );
};
