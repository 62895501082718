import { Close, FileUploadOutlined } from '@mui/icons-material';
import { alpha, styled, Typography } from '@mui/material';
import { theme } from '@linx-ui/shared/theming';
import { UploadStatuses, type FileProps, type MessageProps } from './types';

const UploaderCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type'
})<{ type: UploadStatuses }>(({ theme, type }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor:
    type === UploadStatuses.SUCCESS
      ? alpha(theme.palette.success.light, 0.1)
      : type === UploadStatuses.WARNING
        ? alpha(theme.palette.warning.light, 0.1)
        : alpha(theme.palette.secondary.light, 0.1)
}));

const CancelIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.error.main,
  cursor: 'pointer'
}));

const FileRetryIcon = styled(FileUploadOutlined)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer'
}));

const CancelIconCnt = styled('div')(({ theme }) => ({
  flex: 2,
  gap: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));

const ProgressCnt = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%'
}));

const FileInfoCnt = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  flex: 2,
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden'
}));

const FileName = ({ children }: FileProps) => {
  return (
    <Typography
      style={{
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        width: '85%',
        maxWidth: '85%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      variant="body2"
    >
      {children}
    </Typography>
  );
};

const Message = ({ message, type }: MessageProps) => {
  const color =
    type === UploadStatuses.WARNING
      ? theme.palette.warning.dark
      : type === UploadStatuses.SUCCESS
        ? theme.palette.success.dark
        : theme.palette.secondary.dark;

  return (
    <Typography style={{ color, padding: theme.spacing(0, 1, 0, 7.5) }} variant="body2">
      {message}
    </Typography>
  );
};

export { CancelIcon, CancelIconCnt, FileInfoCnt, FileName, FileRetryIcon, Message, ProgressCnt, UploaderCnt };
