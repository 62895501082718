import { matchPath, useLocation } from 'react-router-dom';

export const getMatchedAbsRoute = (patterns: string[], pathname: string): string | null => {
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath({ path: pattern, end: false }, pathname);
    if (possibleMatch !== null) {
      const currentTab = possibleMatch?.pattern?.path;
      return currentTab;
    }
  }
  return null;
};

export const useRouteMatch = (patterns: string[]): string | null => {
  const { pathname } = useLocation();
  return getMatchedAbsRoute(patterns, pathname);
};
