export const PRESCRIPTION_CATEGORY_MAPPING = {
  WORKFLOW_STATUS: 'Workflow',
  PRESCRIPTION_STATUS: 'Status',
  REJECTION: 'Rejection'
} as const;

export const SYSTEM_CATEGORY_MAPPING = {
  SCHEDULED_MAINTENANCE: 'System Maintenance',
  SERVICE_OUTAGE: 'Service Outage'
} as const;

export const QUERY_PARAMS_TO_FETCH = [
  { isArchived: false },
  { type: 'PRESCRIPTION', isArchived: false },
  { type: 'SYSTEM', isArchived: false }
];
