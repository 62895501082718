import { CircularProgress, type CircularProgressProps } from '@mui/material';
import { LoaderWrapper } from './CircularLoader.styled';

const CircularLoader = ({ size = 40, ...rest }: CircularProgressProps) => {
  return (
    <LoaderWrapper>
      <CircularProgress size={size} {...rest} />
    </LoaderWrapper>
  );
};

export { CircularLoader };
