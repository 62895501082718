import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '@linx-ui/shared/context';
import type { ApiErrorResponse, UseQueryHelpersParams } from './types';

export const useQueryHelpers = <Data, Error = ApiErrorResponse, InterceptedData = Data>({
  successMsg,
  successTitle,
  errorMsg,
  showErrorToast,
  showSuccessToast,
  severity
}: UseQueryHelpersParams<InterceptedData, Error>) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setSnackBarState } = useGlobalContext();

  const raiseSuccessToast = (result: InterceptedData = {} as unknown as InterceptedData) => {
    const snackbarTitle = typeof successTitle === 'function' ? successTitle(result) : successTitle;
    const snackbarMessage = typeof successMsg === 'function' ? successMsg(result) : successMsg;
    (snackbarMessage || snackbarTitle) &&
      showSuccessToast &&
      setSnackBarState({
        open: true,
        severity: severity ?? 'success',
        title: snackbarTitle,
        messages: snackbarMessage ? [snackbarMessage] : []
      });
  };

  const raiseErrorToast = (error: Error) => {
    const errorMessage = typeof errorMsg === 'function' ? errorMsg(error) : errorMsg;
    showErrorToast &&
      setSnackBarState({
        open: true,
        severity: 'error',
        messages: errorMessage
          ? [errorMessage]
          : typeof (error as ApiErrorResponse).validationErrors === 'object'
            ? Object.values((error as ApiErrorResponse).validationErrors!)
            : [(error as ApiErrorResponse).message]
      });
  };

  const navigateToErrorPage = (error: Error) => {
    const dashboardUrl = pathname.split('/')[1];
    const { status } = error as ApiErrorResponse;

    if (status === 403) {
      navigate(`/${dashboardUrl}/not-allowed`);
    }

    if (status === 404) {
      navigate(`/${dashboardUrl}/not-found`, { state: error });
    }
  };

  return { raiseErrorToast, raiseSuccessToast, navigateToErrorPage };
};
