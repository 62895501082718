const ALPHA_NUMERIC = /^[a-z0-9]+$/i;
const THREE_DECIMAL_PLACES = /^\d*(\.\d{1,3})?$/;
const TWO_DECIMAL_PLACES = /^\d*(\.\d{1,2})?$/;
const SIX_DIGIT_4_DECIMAL_PLACES = /^\d{1,6}(\.\d{1,4})?$/;
const EIGHT_DIGIT_2_DECIMAL_PLACES = /^\d{1,8}(\.\d{1,2})?$/;
const WORDS = /^.*\w.*$/;
const SIX_DIGITS = /^\d{6}$/;
const NUMERIC = /^[0-9]+$/i;
const TEN_DIGITS = /^\d{10}$/;
const SEVEN_DIGITS = /^\d{7}$/;
const NINE_DIGITS = /^\d{9}$/;
const ELEVEN_DIGITS = /^\d{11}$/;
const ZIP_CODE = /^\d{5}(?:-\d{4})?$/;
const PHONE_NUMBER = /^(\d{3})(\d{3})(\d{4})$/;
const EMAIL =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
const CAPITAL_LETTER_OR_NUMBER = /([A-Z1-9])/g;
const NUMBER_FIELD_REGEX = /^\d*(\.\d*)?$/;
const SPECIAL_CHARACTERS = /[^A-Za-z0-9\s]+/;
const UPPER_CASE = /[A-Z]+/;
const LOWER_CASE = /[a-z]+/;
const NUMBERS = /[0-9]+/;
const IPV4_ADDRESS =
  /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/;
const IPV6_ADDRESS = /((([0-9a-fA-F]){1,4}):){7}([0-9a-fA-F]){1,4}/;
const TIME_STRING = /\d+-\d+-[[T\d]+:\d+:\d+\.\d+Z/;

export {
  ALPHA_NUMERIC,
  CAPITAL_LETTER_OR_NUMBER,
  ELEVEN_DIGITS,
  EMAIL,
  IPV4_ADDRESS,
  IPV6_ADDRESS,
  LOWER_CASE,
  NINE_DIGITS,
  NUMBERS,
  NUMBER_FIELD_REGEX,
  NUMERIC,
  PHONE_NUMBER,
  SEVEN_DIGITS,
  SIX_DIGITS,
  SIX_DIGIT_4_DECIMAL_PLACES,
  SPECIAL_CHARACTERS,
  TEN_DIGITS,
  THREE_DECIMAL_PLACES,
  TIME_STRING,
  TWO_DECIMAL_PLACES,
  UPPER_CASE,
  WORDS,
  ZIP_CODE,
  EIGHT_DIGIT_2_DECIMAL_PLACES
};
