import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { FC, PropsWithChildren } from 'react';
import { RowTableCell } from './CustomDataGrid.styled';
import { type DragAlongCellProps } from './types';

export const DragAlongCell: FC<PropsWithChildren<DragAlongCellProps>> = ({ cell, children }) => {
  const { setNodeRef, transform } = useSortable({
    id: cell.column.id
  });

  return (
    <RowTableCell
      key={cell.id}
      width={cell.column.getSize()}
      ref={setNodeRef}
      transform={cell.column.id !== 'selection-col' ? CSS.Translate.toString(transform) : undefined}
    >
      {children}
    </RowTableCell>
  );
};
