import { type PaginatedResult } from '@linx-ui/shared/types';
import type { ApiErrorResponse } from '../useQueryHelpers/types';
import type { UseReactQueryParams } from '../useReactQuery/types';
import { useReactQuery } from '../useReactQuery/useReactQuery';

export const useReactQueryList = <Data, Error = ApiErrorResponse, InterceptedData = Data>({
  params,
  ...restConfig
}: UseReactQueryParams<PaginatedResult<Data>, Error, PaginatedResult<InterceptedData>>) => {
  const queryResult = useReactQuery({
    ...restConfig,
    params: {
      offset: 0,
      limit: -1,
      ...(params ?? {})
    },
    initialData: {
      items: [],
      metaData: {
        offset: -1,
        count: 0
      }
    }
  });

  return {
    ...queryResult,
    data: queryResult.data!,
    isLoading: queryResult.isFetching && queryResult.data?.items?.length === 0
  };
};
