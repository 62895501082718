import { CheckCircle, UploadFile, Warning } from '@mui/icons-material';
import { useCallback, useEffect, useMemo } from 'react';
import { LinearProgressWithLabel } from '@linx-ui/shared/components/linearProgressWithLabel';
import { useFileUpload } from '@linx-ui/shared/hooks/api/useFileUpload';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import { UploadStatuses, type GetSignedUrlResult, type UploaderProps } from './types';
import {
  CancelIcon,
  CancelIconCnt,
  FileInfoCnt,
  FileName,
  FileRetryIcon,
  Message,
  ProgressCnt,
  UploaderCnt
} from './Uploader.styled';

const Uploader = <Result,>({
  file,
  entityId,
  headerKeys,
  url,
  onCancel: onUploadCancel,
  onSuccess,
  index,
  folderName = 'files'
}: UploaderProps<Result>) => {
  const { data: signedUrlResult, mutate: getSignedUrl } = useReactMutation<GetSignedUrlResult>({
    url: `${url}/${entityId}/${folderName}`,
    headerKeys,
    headers: { 'Content-Type': 'text/plain' },
    method: 'POST',
    onSuccess: (e) => uploadFile(e.uploadUrl, file)
  });

  const { mutate: setComplete } = useReactMutation<Result>({
    url: `${url}/${entityId}/${folderName}/${signedUrlResult?.fileId}/status?isUploaded=true`,
    headerKeys,
    method: 'PUT',
    onError: () => setUploadStatus(UploadStatuses.WARNING),
    onSuccess: (result) => {
      setUploadStatus(UploadStatuses.SUCCESS);
      onSuccess?.(result);
    }
  });

  const {
    uploadStatus,
    uploadProgress,
    setUploadStatus,
    setUploadProgress,
    abortController,
    setAbortController,
    uploadFile
  } = useFileUpload(() => setComplete(undefined));

  const message = useMemo(() => {
    if (uploadStatus === UploadStatuses.WARNING) {
      return 'File upload was not successful. Please try again!';
    } else if (uploadStatus === UploadStatuses.SUCCESS) {
      return 'File upload was successful.';
    } else {
      return null;
    }
  }, [uploadStatus]);

  const icon = useMemo(() => {
    if (uploadStatus === UploadStatuses.WARNING) {
      return <Warning color="warning" fontSize="large" />;
    } else if (uploadStatus === UploadStatuses.SUCCESS) {
      return <CheckCircle color="success" fontSize="large" />;
    } else {
      return <UploadFile color="secondary" fontSize="large" />;
    }
  }, [uploadStatus]);

  const retry = useMemo(() => {
    return uploadStatus === UploadStatuses.WARNING;
  }, [uploadStatus]);

  const complete = useMemo(() => {
    return uploadStatus === UploadStatuses.SUCCESS;
  }, [uploadStatus]);

  useEffect(() => {
    file && getSignedUrl(file.name);
    return () => {
      abortController.abort();
    };
  }, [file]);

  const onRetry = useCallback(() => {
    setAbortController(new AbortController());
    setUploadStatus(UploadStatuses.SECONDARY);
    setUploadProgress(0);
    file && getSignedUrl(file.name);
  }, [file]);

  const onCancel = useCallback(() => {
    abortController.abort();
    onUploadCancel?.(index);
  }, [abortController, onUploadCancel, index]);

  return (
    <div>
      <UploaderCnt type={uploadStatus}>
        <FileInfoCnt>
          {icon}
          <ProgressCnt>
            <LinearProgressWithLabel color={uploadStatus} value={uploadProgress} />
            <FileName>{file.name}</FileName>
          </ProgressCnt>
        </FileInfoCnt>
        <CancelIconCnt>
          {retry && <FileRetryIcon onClick={onRetry} data-testid="retry-upload-icon" />}
          {!complete && <CancelIcon onClick={onCancel} data-testid="cancel-upload-icon" />}
        </CancelIconCnt>
      </UploaderCnt>
      <Message type={uploadStatus} message={message} />
    </div>
  );
};

export { Uploader };
