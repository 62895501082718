import { FormControlLabel, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';
import type { SwitchWrapperProps } from './types';

const SwitchWrapper = (props: SwitchWrapperProps): JSX.Element => (
  <Controller
    name={props.name}
    control={props.control}
    rules={props.rules}
    defaultValue={props.value ?? false}
    render={({ field: { value, onChange, ...rest } }) => (
      <FormControlLabel
        onFocus={() => props.onFocus?.()}
        label={props.label}
        control={
          <Switch
            checked={value}
            onChange={props.onChange ? props.onChange : onChange}
            disabled={props.disabled}
            color={props.color || 'secondary'}
            {...rest}
          />
        }
      />
    )}
  />
);

export { SwitchWrapper };
