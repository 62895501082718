import { useAuth0 } from '@auth0/auth0-react';
import type { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { useGlobalContext } from '@linx-ui/shared/context';
import { axios } from '@linx-ui/shared/utils/axios';
import type { FetchProps } from '../types';
import { getHeadersFromKeys } from '../../../utils';
/**
 * @deprecated This method should no longer be used. Will be removed in future.
 */
export const useFetch = <T>({
  path,
  tableData = true,
  dataInterceptor,
  headerKeys,
  defaultResult
}: FetchProps<T>): {
  data: T;
  fetchData: (queryParams?: AxiosRequestConfig['params']) => Promise<T | undefined>;
  loading: boolean;
} => {
  const defaultData = ((defaultResult ?? tableData) ? [] : {}) as T;
  const auth = useAuth0();
  const [data, setData] = useState<T>(defaultData);
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackBarState } = useGlobalContext();
  const headers = getHeadersFromKeys(headerKeys);

  const fetchData = async (queryParams: AxiosRequestConfig['params'] = {}) => {
    setLoading(true);
    let qParams = { ...queryParams };
    if (tableData) {
      const defaultPaginationParams = {
        limit: -1,
        offset: 0
      };
      qParams = { ...defaultPaginationParams, ...qParams };
    }
    const token = await auth.getAccessTokenSilently();
    const result: any = await axios.get(path, {
      params: qParams,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (result.error) {
      setSnackBarState({
        open: true,
        severity: 'error',
        messages: [result.message]
      });
      setLoading(false);
    } else {
      const transformedResult = dataInterceptor ? dataInterceptor(result) : defaultInterceptor<T>(result, tableData);
      setData(transformedResult);
      setLoading(false);
      return transformedResult;
    }
  };
  return { data, fetchData, loading };
};

const defaultInterceptor = <T>(result: any, tableData: boolean): T => {
  return tableData ? result.items : result;
};
