import { LinearProgress, Typography, type LinearProgressProps } from '@mui/material';
import { useMemo } from 'react';
import { LabelCnt, MainCnt, ProgressCnt } from './LinearProgressWithLabel.styled';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  const percentage = useMemo(() => `${Math.round(props.value)}%`, [props.value]);

  return (
    <MainCnt>
      <ProgressCnt>
        <LinearProgress variant="determinate" {...props} />
      </ProgressCnt>
      <LabelCnt>
        <Typography variant="body2" color="text.secondary">
          {percentage}
        </Typography>
      </LabelCnt>
    </MainCnt>
  );
}

export { LinearProgressWithLabel };
