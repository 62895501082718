import { type SearchForSuggestionsResult } from '@aws-sdk/client-location';
import { debounce } from '@mui/material';
import { useCallback, useState } from 'react';
import { getAddressDetails, getSuggestions } from '../../adaptors';
import { type Address } from '../../types';

export const useAddressAutosuggestions = () => {
  const [addressSuggetions, setAddressSuggestions] = useState<SearchForSuggestionsResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addressDetails, setAddressDetails] = useState<Address>({} as unknown as Address);

  const getAddressSuggestions = useCallback(
    debounce((keyword: string): void => {
      if (!keyword) {
        setAddressSuggestions([]);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      void getSuggestions(keyword).then((data) => {
        setAddressSuggestions(data!);
        setIsLoading(false);
        return null;
      });
    }, 600),
    []
  );

  const onAddressSelection = (placeId: string) => {
    if (!placeId) {
      return;
    }
    void getAddressDetails(placeId).then((addressDetails) => {
      setAddressDetails(addressDetails);
      return null;
    });
  };

  return {
    isLoading,
    onAddressSelection,
    getAddressSuggestions,
    addressSuggetions,
    addressDetails
  };
};
