import { Grid, styled } from '@mui/material';

const ItemLabel = styled('div')(({ theme, style }) => {
  return {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.15px',
    color: theme.palette.text.secondary,
    ...style
  };
});

const ItemValue = styled('div')(({ theme, style }) => {
  return {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    ...style
  };
});

const GridStyled = styled(Grid)<{ width: number | undefined }>(({ width }) => {
  return {
    width,
    wordBreak: 'break-all'
  };
});
export { ItemLabel, ItemValue, GridStyled };
