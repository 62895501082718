import { Typography, alpha, styled } from '@mui/material';

export const NotificationItemsCnt = styled('div')(() => ({
  height: `calc(100% - 120px)`
}));

export const NotificationBucketCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1.5)
}));

export const NotificationBucketHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(0, 1, 0.5, 1)
}));

export const NoNewNotificationCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '155px',
  gap: theme.spacing(2),
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
  color: theme.palette.text.secondary,
  background: theme.palette.background.paper,
  '.MuiAvatar-root': {
    width: '51px',
    height: '51px',
    background: alpha(theme.palette.warning.light, 0.12)
  },
  '.MuiSvgIcon-root': {
    color: theme.palette.warning.main
  }
}));
