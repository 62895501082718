import { Logout, Menu, MenuOpenOutlined, StoreOutlined } from '@mui/icons-material';
import { Divider, List, useMediaQuery, Drawer as MuiDrawer } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from '@linx-ui/shared/hooks/useRouteMatch';
import { DRAWER_WIDTH } from '@linx-ui/shared/constants';
import { useLogout } from '@linx-ui/shared/hooks';
import { getNavigationItems } from '../utils';
import { PharmacyDropdown } from '../../pharmacyDropdown';
import {
  Drawer,
  BottomList,
  ListItemButtonStyled,
  ListItemIconStyled,
  ListItemStyled,
  ListItemTextStyled,
  ListTitle,
  HideMenuStyled
} from './SideNav.styled';
import { type SideNavProps } from './types';

export const SideNav: React.FC<SideNavProps> = ({
  open,
  handleClose,
  navigationOptions = [],
  showLogout = false,
  showPharmacyDropdown
}: SideNavProps) => {
  const logout = useLogout();
  const navigationItems = useMemo(() => getNavigationItems(navigationOptions), [navigationOptions]);
  const currentTab = useRouteMatch(navigationItems.map((el) => el.absRoute));
  const isSmallScreen = useMediaQuery('(max-width:780px)');

  const handleDrawerClose = () => {
    handleClose();
  };

  const drawer = (
    <>
      <List role="menu">
        {showPharmacyDropdown && open ? (
          <PharmacyDropdown open={open} handleClose={handleClose} />
        ) : (
          <ListItemStyled key="linx-admin" disablePadding>
            <ListItemButtonStyled open={open} onClick={handleClose}>
              <ListItemIconStyled open={open}>
                <StoreOutlined />
              </ListItemIconStyled>
              <ListItemTextStyled primary="Linx Health" open={open} />
            </ListItemButtonStyled>
          </ListItemStyled>
        )}
      </List>

      <Divider />

      {navigationOptions.map((option, index) => (
        <Fragment key={`${option.title}-${index}`}>
          {open && option.title && <ListTitle>{option.title}</ListTitle>}
          <List>
            {option.navigationItems.map((el) => {
              const selected = el.absRoute === currentTab;
              return (
                <ListItemStyled key={el.key} disablePadding>
                  <ListItemButtonStyled selected={selected} open={open} component={Link} to={el.absRoute}>
                    <ListItemIconStyled open={open} selected={selected}>
                      {el.icon}
                    </ListItemIconStyled>
                    <ListItemTextStyled selected={selected} primary={el.title} open={open} />
                  </ListItemButtonStyled>
                </ListItemStyled>
              );
            })}
          </List>
        </Fragment>
      ))}
      <BottomList>
        {showLogout && (
          <ListItemStyled key="logout" disablePadding>
            <ListItemButtonStyled open={open} onClick={logout}>
              <ListItemIconStyled open={open}>
                <Logout />
              </ListItemIconStyled>
              <ListItemTextStyled primary="Logout" open={open} />
            </ListItemButtonStyled>
          </ListItemStyled>
        )}
        <Divider />
        <HideMenuStyled key="hide-menu" disablePadding>
          <ListItemButtonStyled open={open} onClick={handleDrawerClose}>
            <ListItemIconStyled open={open}>{open ? <MenuOpenOutlined /> : <Menu />}</ListItemIconStyled>
            <ListItemTextStyled primary="Hide Menu" open={open} />
          </ListItemButtonStyled>
        </HideMenuStyled>
      </BottomList>
    </>
  );

  return (
    <>
      <Drawer as="aside" key="sidenav" variant="permanent" open={open && !isSmallScreen}>
        {drawer}
      </Drawer>
      <MuiDrawer
        open={open && isSmallScreen}
        sx={{ width: DRAWER_WIDTH, '& .MuiDrawer-paper': { width: DRAWER_WIDTH } }}
      >
        {drawer}
      </MuiDrawer>
    </>
  );
};
