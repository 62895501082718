import type { InfiniteData } from '@tanstack/react-query';

export const resetInfiniteQueryPagination = (oldData?: InfiniteData<any>) => {
  if (!oldData) return undefined;

  return {
    ...oldData,
    pages: oldData.pages.slice(0, 1),
    pageParams: oldData.pageParams.slice(0, 1)
  };
};
