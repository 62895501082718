import { useContext } from 'react';
import { CoreApiContext } from './coreApiContext';
import { type PublicApi } from './types';

const useApiContext = (): { publicApi: PublicApi } => {
  const context = useContext(CoreApiContext) as { publicApi: PublicApi };
  return context;
};

export { useApiContext };
