import { Button, Grid, styled } from '@mui/material';
import React, { Children } from 'react';

const StyledGrid = styled(Grid)(({ style, theme }) => ({
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  ...style
}));

const DetailsGridWrapper = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => {
  const items = Children.toArray(children);
  return (
    <StyledGrid container style={style}>
      {items}
    </StyledGrid>
  );
};

const DetailsGridButtonStyled = styled(Button)(({ theme }) => {
  return {
    color: theme.palette.error.main
  };
});

export { DetailsGridWrapper, DetailsGridButtonStyled };
