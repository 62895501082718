import { GridStyled, ItemLabel, ItemValue } from './GridItem.styled';
import { type GridItemProps } from './types';

const GridItem: React.FC<GridItemProps> = ({
  label,
  value,
  width,
  formatter,
  getStyle,
  labelStyle,
  testId,
  ...rest
}) => {
  const fieldValue = formatter ? formatter(value) : value;
  return (
    <GridStyled item width={width} data-testid={testId} {...rest}>
      {label && <ItemLabel style={labelStyle}>{label}</ItemLabel>}
      <ItemValue style={getStyle?.(value)}>{fieldValue ?? '-'}</ItemValue>
    </GridStyled>
  );
};

export { GridItem };
