import { Button, alpha, styled } from '@mui/material';

export const UnsubscribedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: alpha(theme.palette.text.secondary, 0.04)
  }
}));

export const SubscribedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: alpha(theme.palette.text.primary, 0.04)
  }
}));

export const NotificationActionCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  marginTop: '20px',
  borderTop: `1px solid ${theme.palette.divider}`
}));
