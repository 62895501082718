import type { Pharmacy } from '@linx-ui/shared/types';
import { urls } from '@linx-ui/shared/constants';
import { useReactQuery } from '@linx-ui/shared/hooks/api/useReactQuery';
import type { UseReactQueryParams } from '@linx-ui/shared/hooks/api/useReactQuery/types';
import { usePharmacyId } from '../usePharmacyId';

type Options = Partial<UseReactQueryParams<Pharmacy>> & {
  pharmacyId?: string;
};

export const usePharmacy = (options?: Options) => {
  const { pharmacyId: pharmacyIdParam } = options ?? {};
  const { pharmacyId } = usePharmacyId();
  const id = pharmacyIdParam ?? pharmacyId;

  const { data, ...restResult } = useReactQuery<Pharmacy>({
    url: `${urls.pharmacies}/${id}`,
    enabled: !!id,
    ...options
  });

  return {
    pharmacy: data,
    ...restResult
  };
};
