import { styled } from '@linx-ui/shared/theming';

export const TimeStyled = styled('time')(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.secondary,
  fontWeight: 400,
  lineHeight: '19.92px',
  letterSpacing: '0.4000000059604645px',
  textAlign: 'left'
}));
