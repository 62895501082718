import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider, type PersistedClient } from '@tanstack/react-query-persist-client';
import { del, get, set } from 'idb-keyval';
import type { FC, ReactNode } from 'react';
import { REACT_QUERY_INDEX_DB_KEY } from '@linx-ui/shared/constants';
import { shouldDehydrateQuery } from '@linx-ui/shared/utils/shouldDehydrateQuery';

const MAX_RETRIES = 1;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 1000,
      gcTime: 1000 * 60 * 60 * 24,
      retry: (failureCount: number, error: any) => {
        if (failureCount > MAX_RETRIES || HTTP_STATUS_TO_NOT_RETRY.includes(error.status ?? 0)) {
          return false;
        }

        return true;
      }
    }
  }
});

const persister = {
  persistClient: async (client: PersistedClient) => await set(REACT_QUERY_INDEX_DB_KEY, client).catch(() => undefined),
  restoreClient: async () => await get<PersistedClient>(REACT_QUERY_INDEX_DB_KEY),
  removeClient: async () => await del(REACT_QUERY_INDEX_DB_KEY)
};

export const ReactQueryPersisterContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery
        }
      }}
    >
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  );
};
