import { Check, Close, LockOutlined } from '@mui/icons-material';
import { InputAdornment, Typography } from '@mui/material';
import { theme } from '@linx-ui/shared/theming';

const yesNoFormatter = (value: boolean) => (value ? 'Yes' : 'No');

const TrueFalseFormatter = (value: boolean) => (value ? 'T' : 'F');

const TrueFalseIconFormatter = (value: boolean) => (value ? <Check color="success" /> : <Close color="error" />);

const getInputAdornment = (adornment: string, type: 'startAdornment' | 'endAdornment' = 'startAdornment') => ({
  [type]: (
    <InputAdornment position="start">
      <Typography variant="body1" color={theme.palette.text.secondary}>
        {adornment}
      </Typography>
    </InputAdornment>
  )
});

const getLockFieldProps = () => ({
  inputProps: {
    startAdornment: (
      <InputAdornment position="start">
        <LockOutlined color="action" />
      </InputAdornment>
    )
  },
  disabled: true
});

export { getInputAdornment, getLockFieldProps, TrueFalseFormatter, TrueFalseIconFormatter, yesNoFormatter };
