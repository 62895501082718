import moment from 'moment-timezone';

export const getTimeAgo = (value: string) => {
  const today = moment();
  const createdAt = moment(value);
  const createdAtStartOfDay = moment(value).startOf('day');
  const weekDiff = createdAtStartOfDay.diff(moment().startOf('isoWeek'), 'days');

  if (createdAt.isSame(today, 'day')) {
    const secondsDiff = today.diff(createdAt, 'seconds');
    const minutesDiff = today.diff(createdAt, 'minutes');
    const hoursDiff = today.diff(createdAt, 'hours');
    if (secondsDiff < 60) {
      return `${secondsDiff} seconds ago`;
    } else if (minutesDiff < 60) {
      return `${minutesDiff} minutes ${secondsDiff % 60} seconds ago`;
    } else {
      return `${hoursDiff} hours ${minutesDiff % 60} minutes ago`;
    }
  } else if (weekDiff >= 0 && weekDiff <= 6) {
    const daysDiff = today.startOf('day').diff(createdAtStartOfDay, 'days');
    if (daysDiff === 1) {
      return `Yesterday (${moment(createdAt).format('h:mm A, dddd, MMM D')})`;
    } else {
      return `${daysDiff} days ago (${moment(createdAt).format('h:mm A, dddd, MMM D')})`;
    }
  } else {
    return moment(createdAt).format('h:mm A, MMMM D, yyyy');
  }
};
