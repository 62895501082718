import { useQueryClient, type InfiniteData } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { pharmacyIdKey, urls } from '@linx-ui/shared/constants';
import { getHeadersFromKeys } from '@linx-ui/shared/utils';
import { useInfiniteReactQueryList } from '@linx-ui/shared/hooks/api/useInfiniteReactQueryList';
import type { PaginatedResult } from '@linx-ui/shared/types';
import { resetInfiniteQueryPagination } from '@linx-ui/shared/utils/reactQueryUtils';
import { usePharmacyId } from '@linx-ui/shared/hooks';
import { QUERY_PARAMS_TO_FETCH } from './constants';
import type { Notification, NotificationTabValues } from './types';
import { getFilterParamsFromTabValue } from './utils/getFilterParamsFromTabValue';

export const useNotifications = (tab: NotificationTabValues) => {
  const { pharmacyId } = usePharmacyId();
  const queryClient = useQueryClient();
  const headers = getHeadersFromKeys([pharmacyIdKey]);

  const params = useMemo(() => getFilterParamsFromTabValue(tab), [tab]);

  const result = useInfiniteReactQueryList<Notification>({
    url: urls.notifications,
    headerKeys: [pharmacyIdKey],
    enabled: !!pharmacyId,
    params
  });

  const refetch = useCallback(
    async () =>
      await Promise.all([
        QUERY_PARAMS_TO_FETCH.map(async (params) => {
          queryClient.setQueryData<InfiniteData<PaginatedResult<Notification>>>(
            [urls.notifications, headers, params],
            resetInfiniteQueryPagination
          );
          await queryClient.invalidateQueries({ queryKey: [urls.notifications, headers, params], refetchType: 'all' });
        })
      ]),
    []
  );

  return {
    ...result,
    refetch
  };
};
