import { useCallback, useState } from 'react';

export const useSequentialDialog = (numberOfDialogs: number) => {
  const [dialogState, setDialogState] = useState<boolean[]>(Array(numberOfDialogs).fill(false));

  const nextDialog = useCallback(() => {
    setDialogState((prevState) => {
      const openDialogIndex = prevState.findIndex((dialog) => dialog);
      if (openDialogIndex >= 0) {
        const newState = [...prevState];
        newState[openDialogIndex] = false;

        if (openDialogIndex < numberOfDialogs - 1) {
          newState[openDialogIndex + 1] = true;
        }

        return newState;
      }
      const newState = [...prevState];
      newState[0] = true;
      return newState;
    });
  }, []);

  const prevDialog = useCallback(() => {
    setDialogState((prevState) => {
      const openDialogIndex = prevState.findIndex((dialog) => dialog);
      if (openDialogIndex >= 0) {
        const newState = [...prevState];
        newState[openDialogIndex] = false;

        if (openDialogIndex > 0) {
          newState[openDialogIndex - 1] = true;
        }

        return newState;
      }
      return prevState;
    });
  }, []);

  const closeAllDialog = useCallback(() => {
    setDialogState(Array(numberOfDialogs).fill(false));
  }, []);

  const toggleDialog = useCallback((dialogIndex: number) => {
    setDialogState((prevState) => {
      const newState = [...prevState];
      newState[dialogIndex] = !prevState[dialogIndex];
      return newState;
    });
  }, []);

  return {
    dialogState,
    nextDialog,
    prevDialog,
    toggleDialog,
    closeAllDialog
  };
};
