import { NotAllowedWraper } from './NotAllowed.styled';

const NotAllowed: React.FC = () => {
  return (
    <NotAllowedWraper>
      <h3>Sorry, you are not allowed to access this page!</h3>
    </NotAllowedWraper>
  );
};

export default NotAllowed;
