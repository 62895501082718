import { type ColumnDef } from '@tanstack/react-table';
import { type RecentHistory } from '@linx-ui/shared/types';
import { getDateTimeDisplayValue } from '@linx-ui/shared/utils/dateTimeUtils';

export const getHistoryColumns = <T extends Record<'recentHistory', RecentHistory>>({
  enableSorting = true
}: { enableSorting?: boolean } = {}): {
  header: string;
  columns: Array<ColumnDef<T>>;
} => ({
  header: 'Audit Log',
  columns: [
    {
      accessorFn: (row) => row?.recentHistory?.lastUpdatedByUser,
      header: 'Last Updated By',
      enableSorting
    },
    {
      accessorFn: (row) => getDateTimeDisplayValue(row?.recentHistory?.lastUpdatedTime),
      header: 'Updated At',
      enableSorting
    },
    {
      accessorFn: (row) => row?.recentHistory?.createdByUser,
      header: 'Created By',
      enableSorting
    },
    {
      accessorFn: (row) => getDateTimeDisplayValue(row?.recentHistory?.createdTime),
      header: 'Created At',
      enableSorting
    }
  ]
});
