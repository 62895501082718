import { Tune } from '@mui/icons-material';
import { debounce } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiContext } from '../context';
import { Left, Right, TextFieldStyled, ToolbarContainer } from './Toolbar.styled';
import { ColumnSettings } from './columnSettings';
import { type Props } from './types';

const Toolbar: React.FC<Props> = ({
  enableSearch,
  enableHideColumns = false,
  toolbarActions = [],
  components = {
    HideColumnsIcon: <Tune />
  },
  testId = '',
  searchPlaceholder = 'Search',
  resetColumns,
  toggleColumnVisibility,
  disableQuerySearch
}: Props) => {
  const { publicApi } = useApiContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    publicApi.onSearchChange?.(value);
    !disableQuerySearch && setSearchParams(new URLSearchParams({ search: value }));
  };

  useEffect(() => {
    const value = !disableQuerySearch && searchParams.get('search');
    value && publicApi.onSearchChange?.(value);
  }, [disableQuerySearch]);

  return (
    <ToolbarContainer>
      {/* Left toolbar items */}
      <Left>
        {enableSearch && (
          <TextFieldStyled
            autoFocus
            inputProps={{ 'data-testid': `${testId}-search-box-input` }}
            data-testid={`${testId}-search-box`}
            size="small"
            type="search"
            defaultValue={!disableQuerySearch ? searchParams.get('search') : ''}
            placeholder={searchPlaceholder}
            onChange={debounce(onChange, 600)}
          />
        )}
      </Left>

      {/* Right toolbar items  */}
      <Right>
        {toolbarActions}
        <ColumnSettings
          HideColumnsIcon={components.HideColumnsIcon}
          getAllColumns={publicApi.getAllColumns}
          enableHideColumns={enableHideColumns}
          resetColumns={resetColumns}
          toggleColumnVisibility={toggleColumnVisibility}
        />
      </Right>
    </ToolbarContainer>
  );
};

const CustomDataGridToolbar = memo(Toolbar);

CustomDataGridToolbar.displayName = 'CustomDataGridToolbar';

export { CustomDataGridToolbar };
