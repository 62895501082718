export const ShortcutIds = {
  OPEN_DASHBOARD: 'OPEN_DASHBOARD',
  OPEN_QUEUES: 'OPEN_QUEUES',
  OPEN_PROFILES: 'OPEN_PROFILES',
  OPEN_PRESCRIPTIONS: 'OPEN_PRESCRIPTIONS',
  OPEN_REPORTS: 'OPEN_REPORTS',
  OPEN_SUPPORT: 'OPEN_SUPPORT',
  CURRENT_INVENTORY: 'CURRENT_INVENTORY',
  ERX_MESSAGES: 'ERX_MESSAGES',
  OPEN_RECYCLE_BIN: 'OPEN_RECYCLE_BIN',
  OPEN_DELIVERIES: 'OPEN_DELIVERIES',
  OPEN_USER_MANAGEMENT: 'OPEN_USER_MANAGEMENT',
  OPEN_CONFIGURATIONS: 'OPEN_CONFIGURATIONS',
  SHORTCUT_HELP_MENU: 'SHORTCUT_HELP_MENU',
  OPEN_INTAKE_QUEUE: 'OPEN_INTAKE_QUEUE',
  OPEN_BILLING_QUEUE: 'OPEN_BILLING_QUEUE',
  OPEN_FILLING_QUEUE: 'OPEN_FILLING_QUEUE',
  OPEN_VERIFICATION_QUEUE: 'OPEN_VERIFICATION_QUEUE',
  OPEN_READY_QUEUE: 'OPEN_READY_QUEUE',
  OPEN_UPCOMING_REFILLS_QUEUE: 'OPEN_UPCOMING_REFILLS_QUEUE',
  OPEN_COMPLETED_QUEUE: 'OPEN_COMPLETED_QUEUE',
  OPEN_NOTES_AND_DOCUMENTS: 'OPEN_NOTES_AND_DOCUMENTS',
  LABEL_TEMPLATE_LIST: 'LABEL_TEMPLATE_LIST',
  TAG_LIST: 'TAG_LIST',
  KEYBOARD_SHORTCUTS: 'KEYBOARD_SHORTCUTS',
  CURRENT_INVENTORY_LIST: 'CURRENT_INVENTORY_LIST',
  WHOLESALER_INVOICES_LIST: 'WHOLESALER_INVOICES_LIST',
  CREATE_NEW_ENTITY: 'CREATE_NEW_ENTITY',
  UPDATE_ENTITY: 'UPDATE_ENTITY',
  PLACE_RX_ON_HOLD: 'PLACE_RX_ON_HOLD',
  TRANSFER_OUT: 'TRANSFER_OUT',
  CHANGE_REQUEST: 'CHANGE_REQUEST',
  RENEWAL_REQUEST: 'RENEWAL_REQUEST',
  VERIFY_RX: 'VERIFY_RX',
  VERIFY_NDC: 'VERIFY_NDC',
  SUBMIT_PRIMARY_INS_CLAIM: 'SUBMIT_PRIMARY_INS_CLAIM',
  SUBMIT_SECONDARY_INS_CLAIM: 'SUBMIT_SECONDARY_INS_CLAIM',
  SUBMIT_TERTIARY_INS_CLAIM: 'SUBMIT_TERTIARY_INS_CLAIM',
  PROCESS_AS_CASH: 'PROCESS_AS_CASH',
  PRINT_LABEL: 'PRINT_LABEL',
  OPEN_PREV_RX: 'OPEN_PREV_RX',
  OPEN_NEXT_RX: 'OPEN_NEXT_RX',
  CREATE_COMPOUND_DRUG: 'CREATE_COMPOUND_DRUG',
  CREATE_NEW_PRESCRIBER: 'CREATE_NEW_PRESCRIBER',
  OPEN_PATIENT_PROFILE: 'OPEN_PATIENT_PROFILE',
  OPEN_PRESCRIBER_PROFILE: 'OPEN_PRESCRIBER_PROFILE',
  OPEN_DRUG_PROFILE: 'OPEN_DRUG_PROFILE',
  OPEN_INSURANCE_PROFILE: 'OPEN_INSURANCE_PROFILE',
  OPEN_SIG_PROFILE: 'OPEN_SIG_PROFILE',
  OPEN_FACILITIES_PROFILE: 'OPEN_FACILITIES_PROFILE',
  OPEN_PRICE_PLAN_PROFILE: 'OPEN_PRICE_PLAN_PROFILE',
  QUICK_FIND: 'QUICK_FIND'
} as const;
