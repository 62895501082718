import moment from 'moment-timezone';
import { memo, useEffect, useState, type FC } from 'react';
import { TimeStyled } from './NotificationTimestamp.styled';
import { getTimeAgo } from './getTimeAgo';

export const NotificationTimestamp: FC<{ timestamp: string }> = memo(({ timestamp }) => {
  const [time, setTime] = useState<string>(getTimeAgo(timestamp));

  useEffect(() => {
    const isToday = moment(timestamp).isSame(moment(), 'day');
    if (isToday) {
      const secondsDiff = moment(timestamp).diff(moment(), 'seconds');
      const interval = setInterval(() => setTime(getTimeAgo(timestamp)), secondsDiff < 60 ? 1000 : 60000);
      return () => clearInterval(interval);
    }
  }, [timestamp]);

  return <TimeStyled>{time}</TimeStyled>;
});
NotificationTimestamp.displayName = 'NotificationTimestamp';
