import { Chip, type AlertColor } from '@mui/material';
import { nanoid } from 'nanoid';

const TitleChips: React.FC<{
  labels: Array<{
    value: string;
    color?: AlertColor | 'default';
    variant?: 'filled' | 'outlined';
  }>;
}> = ({ labels }) => {
  return (
    <>
      {labels.map((label) => {
        if (!label.value) {
          return null;
        } else {
          return (
            <Chip
              key={nanoid()}
              size="small"
              label={label.value}
              color={label.color}
              variant={label.variant ?? 'outlined'}
            />
          );
        }
      })}
    </>
  );
};

export { TitleChips };
