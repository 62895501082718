import { styled } from '@mui/material';

const FilesCnt = styled('div', { shouldForwardProp: (prop) => prop !== 'showUploadedFiles' })<{
  showUploadedFiles: boolean;
}>(({ theme, showUploadedFiles }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
  marginTop: theme.spacing(2),
  height: showUploadedFiles ? 'calc(50% - 16px)' : 'calc(100% - 16px)',
  overflow: 'hidden'
}));

const FilesCntInner = styled('div')(() => ({
  maxHeight: '100%',
  overflow: 'auto',
  display: 'flex',
  gap: '16px',
  flexDirection: 'column'
}));

const FilesUploadInfoCnt = styled('div')(() => ({
  height: 'calc(100% - 152px)'
}));

const MainCnt = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

export { FilesCnt, FilesUploadInfoCnt, MainCnt, FilesCntInner };
