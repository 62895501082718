import { useLocation } from 'react-router-dom';
import { AlertMessage } from '@linx-ui/shared/components/alertMessage';
import { NotFoundWraper } from './NotFound.styled';

const NotFound: React.FC = () => {
  const { state } = useLocation();
  return (
    <NotFoundWraper>
      <h3>Sorry, page not found!</h3>
      {state?.message && <AlertMessage alertText={state.message} severity="error" />}
    </NotFoundWraper>
  );
};

export default NotFound;
