import { Box, Skeleton, styled } from '@mui/material';
import { nanoid } from 'nanoid';

const SkeletonStyled = styled(Skeleton)(() => {
  return {
    height: '20px',
    borderRadius: '4px'
  };
});
const BoxStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'last'
})<{ last: boolean }>(({ theme, last }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '52px',
    padding: theme.spacing(1.4375, 1.25),
    borderBottom: !last ? `1px solid ${theme.palette.divider}` : undefined
  };
});

const getSkeletonRows = (rows: number) => {
  const skeletonRows = [];
  for (let i = 0; i < rows; i++) {
    skeletonRows.push(
      <BoxStyled last={i === rows - 1} key={nanoid()}>
        <SkeletonStyled variant="rectangular" />
      </BoxStyled>
    );
  }
  return skeletonRows;
};

const LoadingSkeleton = () => <Box>{getSkeletonRows(3)}</Box>;

export { LoadingSkeleton };
