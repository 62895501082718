import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import type { SelectOption, SelectWrapperProps } from './types';

const SelectWrapper = ({
  name,
  control,
  options,
  getOptionLabel,
  defaultValue,
  fieldModifier,
  isLoading,
  optionLabelKey,
  rules,
  label,
  size,
  multiple,
  disabled,
  startAdornment,
  dataTestId,
  error,
  onFocus,
  renderValue,
  helperText
}: SelectWrapperProps): JSX.Element => {
  const getLabel = useCallback((option: SelectOption) => {
    const labelKey = optionLabelKey ?? 'label';
    return option[labelKey];
  }, []);
  const _defaultValue = defaultValue ?? (multiple ? [] : '');

  return isLoading ? (
    <Skeleton width="100%" height="100%" />
  ) : (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={_defaultValue}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel required={!!rules?.required}>{label}</InputLabel>
          <Select
            {...(fieldModifier ? fieldModifier(field) : field)}
            disableInjectingGlobalStyles
            size={size}
            multiple={multiple}
            startAdornment={startAdornment}
            disabled={disabled}
            required={!!rules?.required}
            onFocus={() => onFocus?.()}
            error={error?.hasError}
            label={label}
            data-testid={dataTestId}
            renderValue={renderValue}
          >
            {options?.map((option: SelectOption) => (
              <MenuItem key={nanoid()} value={option.value} disabled={option.disabled} sx={option.style}>
                {getOptionLabel ? getOptionLabel(option) : getLabel(option)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!error?.message}>{error?.message ?? helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export { SelectWrapper };
