import { ShortcutIds } from '../shortcutIds';
import { Sections } from '../sections';
import type { ShortcutConfig } from '../types';

const CONFIG_ROUTE = '/rx/pharmacy-sc/configurations';

export const ConfigurationSection: ShortcutConfig = [
  {
    id: ShortcutIds.LABEL_TEMPLATE_LIST,
    description: 'Navigate to the Label Templates Listing Page',
    quickFindDescription: 'Configurations > Label Templates',
    shortcut: ['ctrl', 'KeyL'],
    navigate: '/rx/pharmacy-sc/configurations/label-templates/list',
    section: Sections.CONFIGURATION,
    enableFor: CONFIG_ROUTE
  },
  {
    id: ShortcutIds.TAG_LIST,
    description: 'Navigate to the Tags Listing Page',
    quickFindDescription: 'Configurations > Tags',
    shortcut: ['ctrl', 'KeyG'],
    navigate: '/rx/pharmacy-sc/configurations/tags/list',
    section: Sections.CONFIGURATION,
    enableFor: CONFIG_ROUTE
  },
  {
    id: ShortcutIds.KEYBOARD_SHORTCUTS,
    description: 'Navigate to the Keyboard Shortcuts Page',
    quickFindDescription: 'Configurations > Keyboard Shortcuts',
    shortcut: ['ctrl', 'KeyK'],
    navigate: '/rx/pharmacy-sc/configurations/keyboard-shortcuts',
    section: Sections.CONFIGURATION,
    enableFor: CONFIG_ROUTE
  }
];
