import { theme } from '@linx-ui/shared/theming';
import { type WrapperOption } from '@linx-ui/shared/types';
import type { ObjectMap } from './types';
import { NUMBER_FIELD_REGEX, TWO_DECIMAL_PLACES } from './validations';

const addressFormatter = (value: object) => (value ? objPropConcatenator(value, ', ') : value);

const flattenObj = (ob: ObjectMap) => {
  const result: ObjectMap = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + '.' + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};

const nameConcatenator = (data: { firstName: string; middleName?: string; lastName: string } | undefined) => {
  return objPropConcatenator({
    firstName: data?.firstName,
    middleName: data?.middleName,
    lastName: data?.lastName
  });
};

const nameFormatter = (data: any) => {
  return {
    ...data,
    name: nameConcatenator(data)
  };
};

const dataFormatter = (data: any = {}) =>
  Object.keys(data).length
    ? {
        ...flattenObj(data),
        'address.street': addressFormatter({
          addressLine1: data.address?.addressLine1,
          addressLine2: data.address?.addressLine2
        })
      }
    : data;

const zipFormatter = (zip?: string) => {
  if (zip?.length === 10) {
    return zip.split(' ').join('-');
  }
  return zip;
};

const isPositive = (n: number): boolean => n > 0;

const numberFieldChangeHandler =
  (callback: (e: React.ChangeEvent<HTMLInputElement>) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (NUMBER_FIELD_REGEX.test(e.target.value)) {
      callback(e);
    }
  };

export const numberTextFieldChangeHandler =
  (callback: (e: React.ChangeEvent<HTMLInputElement>) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const event: React.ChangeEvent<HTMLInputElement> = {
      ...e,
      // @ts-expect-error
      target: { ...e.target, value: e.target.value === '' ? null : Number(e.target.value) }
    };
    callback(event);
  };

const numberFieldWithTwoDecimalChangeHandler =
  (callback: (e: React.ChangeEvent<HTMLInputElement>) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || TWO_DECIMAL_PLACES.test(e.target.value)) {
      callback(e);
    }
  };

const objPropConcatenator = (value: object, separator = ' ') =>
  Object.values(value)
    .reduce<string>((acc, curr: string) => (curr ? `${acc}${separator}${curr}` : acc), '')
    .slice(1);

const groupBy = <T,>(array: T[], predicate: (value: T, index: number, array: T[]) => string): Record<string, T[]> =>
  array.reduce<Record<string, T[]>>((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {});

const addClearOption = (options: WrapperOption[]): WrapperOption[] => [
  {
    label: 'Clear Selection',
    value: '',
    style: { color: theme.palette.text.disabled }
  },
  ...options
];

const customPrintFunc = async (printWindow: HTMLIFrameElement, containerClass: string) => {
  const printContent = printWindow.contentWindow?.document || printWindow.contentDocument;
  const printedScrollContainer = printContent?.querySelector(containerClass) as unknown as HTMLDivElement;
  printedScrollContainer.style.overflow = 'visible';
  printedScrollContainer.style.height = 'fit-content';
  printedScrollContainer.style.background = 'none';
  printedScrollContainer.style.padding = '1cm 1cm 1cm 1cm';
  printWindow?.contentWindow?.print();
  return true;
};

export {
  addClearOption,
  addressFormatter,
  customPrintFunc,
  dataFormatter,
  flattenObj,
  groupBy,
  isPositive,
  numberFieldChangeHandler,
  objPropConcatenator,
  zipFormatter,
  type ObjectMap,
  numberFieldWithTwoDecimalChangeHandler,
  nameConcatenator,
  nameFormatter
};
