import { Checkbox } from '@mui/material';
import { memo, useMemo, type FC } from 'react';
import { CheckboxLabelWrapper, CheckboxListWrapper } from './CheckboxGroupWrapper.styled';
import type { CheckboxGroupWrapperProps } from './types';

export const CheckboxGroupWrapper: FC<CheckboxGroupWrapperProps> = memo(
  ({ selectedValues, label, onChange, onSingleCheckboxChange, onGroupCheckboxChange, options, readOnly }) => {
    const isAllSelected = useMemo(
      () => options.every(({ value }) => selectedValues.includes(value)),
      [options, selectedValues]
    );
    const isIndeterminate = useMemo(
      () => !isAllSelected && !!options.find(({ value }) => selectedValues.includes(value)),
      [options, selectedValues, isAllSelected]
    );

    const handleGroupChange = (checked: boolean) => {
      onGroupCheckboxChange
        ? onGroupCheckboxChange(
            checked,
            options.map(({ value }) => value)
          )
        : onChange(checked ? options.map(({ value }) => value) : []);
    };
    const handleCheckboxChange = (checked: boolean, value: string) => {
      onSingleCheckboxChange
        ? onSingleCheckboxChange(checked, value)
        : onChange(checked ? [...selectedValues, value] : selectedValues.filter((_value) => _value !== value));
    };

    return (
      <>
        <CheckboxLabelWrapper
          fullWidth
          readOnly={readOnly}
          label={label}
          control={
            <Checkbox
              color="secondary"
              checked={isAllSelected}
              indeterminate={isIndeterminate}
              onChange={(event) => handleGroupChange(event.target.checked)}
            />
          }
        />
        <CheckboxListWrapper>
          {options.map(({ label, value }) => (
            <CheckboxLabelWrapper
              key={value}
              readOnly={readOnly}
              label={label}
              control={
                <Checkbox
                  color="secondary"
                  checked={selectedValues.includes(value)}
                  onChange={(event) => handleCheckboxChange(event.target.checked, value)}
                />
              }
            />
          ))}
        </CheckboxListWrapper>
      </>
    );
  }
);
CheckboxGroupWrapper.displayName = 'CheckboxGroupWrapper';
