import { ArrowForward, ArticleOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { memo, type FC } from 'react';
import { getPrescriptionStatusChipColor } from '@linx-ui/shared/utils/helpers';
import { UserAvatar } from '@linx-ui/shared/components//userAvatar';
import { PRESCRIPTION_STATUS_LABELS } from '@linx-ui/shared/constants';
import { PRESCRIPTION_CATEGORY_MAPPING } from '../../../constants';
import type { PrescriptionNotification } from '../../../types';
import {
  Dot,
  NotificationActions,
  NotificationContentCnt,
  NotificationIconCnt,
  NotificationItemTopCnt,
  NotificationItemTopMiddleCnt,
  NotificationQueueStatus,
  NotificationThemedText,
  NotificationTimestamp,
  StatusChip,
  TypeAndTimeCnt,
  UnreadDot
} from '../../components';

export const PrescriptionStatusNotification: FC<{
  notification: Extract<PrescriptionNotification, { category: 'PRESCRIPTION_STATUS' }>;
}> = memo(({ notification }) => {
  const { from, to } = notification.metadata.eventPayload;
  const fromStatus = PRESCRIPTION_STATUS_LABELS[from?.prescriptionStatus];
  const toStatus = PRESCRIPTION_STATUS_LABELS[to?.prescriptionStatus];
  const rxDetailsLink = `/rx/pharmacy-sc/queues/${to?.queue.toLowerCase()}/${to?.prescriptionId}/details`;

  return (
    <>
      <NotificationItemTopCnt isRead={notification.isRead}>
        <NotificationIconCnt>
          <ArticleOutlined />
        </NotificationIconCnt>
        <NotificationItemTopMiddleCnt>
          <TypeAndTimeCnt>
            <NotificationQueueStatus from={from.queue} to={to.queue} />
            <Dot />
            <NotificationThemedText>{PRESCRIPTION_CATEGORY_MAPPING.PRESCRIPTION_STATUS}</NotificationThemedText>
            <Dot />
            <NotificationTimestamp timestamp={notification.createdTime} />
          </TypeAndTimeCnt>
          <NotificationContentCnt>
            <Typography>Rx#</Typography>
            <Typography fontWeight="bold">{`${to?.rxNumber}|${to?.fillNumber}`}</Typography>
            <Typography>status was changed from</Typography>
            <StatusChip size="small" label={fromStatus} color={getPrescriptionStatusChipColor(fromStatus)} />
            <ArrowForward fontSize="small" color="disabled" />
            <StatusChip size="small" label={toStatus} color={getPrescriptionStatusChipColor(toStatus)} />
            <Typography>by</Typography>
            <UserAvatar userName={to?.recentHistory?.lastUpdatedByUser} />
          </NotificationContentCnt>
        </NotificationItemTopMiddleCnt>
        {!notification.isRead && <UnreadDot data-testid={`${notification.id}-unread-dot`} color="success" />}
      </NotificationItemTopCnt>
      <NotificationActions rxDetailsLink={rxDetailsLink} notification={notification} />
    </>
  );
});
PrescriptionStatusNotification.displayName = 'PrescriptionStatusNotification';
