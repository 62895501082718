import { useAuth0 } from '@auth0/auth0-react';
import { useInfiniteQuery, useQueryClient, type InfiniteData } from '@tanstack/react-query';
import { type Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { type SortingState } from '@tanstack/react-table';
import { isNil, omitBy } from 'lodash';
import { DEFAULT_PAGE_LIMIT } from '@linx-ui/shared/constants';
import { type PaginatedResult } from '@linx-ui/shared/types';
import { axios } from '@linx-ui/shared/utils/axios';
import { getHeadersFromKeys } from '../../../utils';
import type { ApiErrorResponse } from '../useQueryHelpers/types';
import { useQueryHelpers } from '../useQueryHelpers/useQueryHelpers';
import type { InfiniteListState, UseInfiniteReactQueryListParams } from './types';

export const useInfiniteReactQueryList = <Data, Error = ApiErrorResponse, InterceptedData extends Data = Data>(
  config: UseInfiniteReactQueryListParams<PaginatedResult<Data>, Error, InfiniteData<PaginatedResult<InterceptedData>>>
) => {
  const {
    url,
    params,
    errorMsg,
    showErrorToast = true,
    showSuccessToast = true,
    onError,
    headerKeys = [],
    initialData = {
      pages: [],
      pageParams: []
    },
    ...restParams
  } = config;
  const auth = useAuth0();
  const queryClient = useQueryClient();
  const headers = getHeadersFromKeys(headerKeys);
  const { raiseErrorToast, navigateToErrorPage } = useQueryHelpers({ errorMsg, showErrorToast, showSuccessToast });
  const limit = params?.limit ?? DEFAULT_PAGE_LIMIT;
  const [searchSortState, setSearchSortState] = useReducer<Reducer<InfiniteListState, Partial<InfiniteListState>>>(
    (state, newState) => {
      return { ...state, ...newState };
    },
    { searchTxt: '' }
  );
  const finalParams = omitBy(
    { ...params, 'quick-search': searchSortState.searchTxt, ...searchSortState.sortState },
    (v) => isNil(v) || v === ''
  );
  const queryKey = [url, headers, finalParams];

  const hasAllRequiredHeaders = headerKeys.every((value) => Object.keys(headers).includes(value));

  const queryResult = useInfiniteQuery<PaginatedResult<Data>, Error, InfiniteData<PaginatedResult<InterceptedData>>>({
    ...{ ...restParams, enabled: restParams.enabled && hasAllRequiredHeaders && auth.isAuthenticated },
    queryKey,
    initialData,
    initialPageParam: 0,
    queryFn: async ({ pageParam }) => {
      const response = await axios({
        url,
        method: 'GET',
        params: {
          ...finalParams,
          limit,
          offset: pageParam
        },
        headers: { ...headers, Authorization: `Bearer ${await auth.getAccessTokenSilently()}` }
      });
      if ((response as unknown as ApiErrorResponse).error) {
        return await Promise.reject(response);
      }
      return response as unknown as PaginatedResult<Data>;
    },
    getNextPageParam: (lastPage) =>
      lastPage
        ? lastPage.metaData.count < limit || limit === -1
          ? undefined
          : lastPage.metaData.offset + 1
        : undefined
  });

  const { isError, error, hasNextPage, isFetchingNextPage, fetchNextPage } = queryResult;

  useEffect(() => {
    if (isError) {
      raiseErrorToast(error);
      onError?.(error);
      navigateToErrorPage(error);
    }
  }, [isError, error]);

  const refetch = useCallback(async () => {
    queryClient.setQueryData<InfiniteData<PaginatedResult<InterceptedData>>>(queryKey, {
      pages: [],
      pageParams: []
    });
    await queryResult.refetch();
  }, []);

  const onSearch = (value: string) => {
    setSearchSortState({ searchTxt: value });
  };

  const onSort = (sortingState: SortingState) => {
    setSearchSortState({ sortState: { order: sortingState[0].desc ? 'desc' : 'asc', 'sort-by': sortingState[0].id } });
  };

  const items = useMemo(
    () => queryResult.data.pages.reduce<InterceptedData[]>((acc, page) => acc.concat(page.items), []),
    [queryResult.data.pages]
  );

  const handleFetchNext = useCallback(async () => {
    if (hasNextPage && !isFetchingNextPage) await fetchNextPage();
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return {
    ...queryResult,
    items,
    refetch,
    onSearch,
    onSort,
    fetchNextPage: handleFetchNext,
    isLoading: queryResult.isFetching && JSON.stringify(queryResult.data) === JSON.stringify(initialData)
  };
};
