import { useCallback, useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from '../useUpdateEffect';

const useDynamicHeight = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  const getHeight = useCallback(() => {
    let height = 0;
    if (!containerRef.current?.getBoundingClientRect().height) {
      height = 0;
    } else {
      height = containerRef.current?.getBoundingClientRect().height;
    }
    return height;
  }, [containerRef, containerRef.current, containerRef.current?.getBoundingClientRect().height]);

  const resizeObserver = new ResizeObserver(() => {
    setHeight(getHeight());
  });

  useEffect(() => {
    setHeight(getHeight());
    if (containerRef?.current) resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, containerRef.current?.getBoundingClientRect().height]);

  useUpdateEffect(() => {
    setHeight(getHeight());
  }, [containerRef, containerRef.current?.getBoundingClientRect().height]);

  return [containerRef, height, getHeight] as const;
};

export { useDynamicHeight };
