import type { InfiniteData } from '@tanstack/react-query';
import moment from 'moment-timezone';
import type { PaginatedResult } from '@linx-ui/shared/types';
import type { Notification } from '../types';

export const getSegregatedNotifications = (notifications: InfiniteData<PaginatedResult<Notification>>) => {
  const result: Record<string, Notification[]> = {
    Today: [],
    'This Week': [],
    'Last 90 Days': []
  };

  notifications.pages.forEach((page) => {
    page.items.forEach((notification) => {
      const today = moment();
      const createdAt = moment(notification.createdTime);
      const weekDiff = createdAt.diff(moment().startOf('isoWeek'), 'days');

      if (createdAt.isSame(today, 'day')) {
        result.Today.push(notification);
      } else if (weekDiff >= 0 && weekDiff <= 6) {
        result['This Week'].push(notification);
      } else {
        result['Last 90 Days'].push(notification);
      }
    });
  });

  return result;
};
