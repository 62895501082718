import { memo, type FC } from 'react';
import type { Notification } from '@linx-ui/shared/components/notifications/types';
import { PrescriptionStatusNotification } from './prescriptionStatusNotification';
import { RejectionNotification } from './rejectionNotification';
import { WorkflowStatusNotification } from './workflowStatusNotification';

export const PrescriptionNotification: FC<{ notification: Extract<Notification, { type: 'PRESCRIPTION' }> }> = memo(
  ({ notification }) => {
    switch (notification.category) {
      case 'PRESCRIPTION_STATUS':
        return <PrescriptionStatusNotification notification={notification} />;
      case 'WORKFLOW_STATUS':
        return <WorkflowStatusNotification notification={notification} />;
      case 'REJECTION':
        return <RejectionNotification notification={notification} />;
    }
  }
);
PrescriptionNotification.displayName = 'PrescriptionNotification';
