import { ShortcutIds } from '../shortcutIds';
import { Sections } from '../sections';
import type { ShortcutConfig } from '../types';

const INVENTORY_ROUTE = '/rx/pharmacy-sc/inventory';

export const InventorySection: ShortcutConfig = [
  {
    id: ShortcutIds.CURRENT_INVENTORY_LIST,
    description: 'Navigate to the Current Inventory Listing Page',
    quickFindDescription: 'Inventory > Current Inventory Listing',
    shortcut: ['ctrl', 'KeyI'],
    navigate: '/rx/pharmacy-sc/inventory/current-inventory/list',
    section: Sections.INVENTORY,
    enableFor: INVENTORY_ROUTE
  },
  {
    id: ShortcutIds.WHOLESALER_INVOICES_LIST,
    description: 'Navigate to the Wholesaler Invoices Listing Page',
    quickFindDescription: 'Inventory > Wholesaler Invoices Listing',
    shortcut: ['ctrl', 'KeyH'],
    navigate: '/rx/pharmacy-sc/inventory/wholesaler-invoices/list',
    section: Sections.INVENTORY,
    enableFor: INVENTORY_ROUTE
  }
];
