import { Accordion } from '@mui/material';
import { styled } from '@mui/system';

const AccordiansContainer = styled('div')(() => ({
  height: '100%'
}));

const AccordianStyled = styled(Accordion)(({ expanded }) => ({
  '& .MuiCollapse-root': {
    height: expanded ? 'calc(100% - 64px) !important' : undefined
  },
  '& .MuiCollapse-wrapper': {
    height: expanded ? '100% !important' : undefined
  },
  '& .MuiCollapse-wrapperInner': {
    height: expanded ? '100% !important' : undefined
  },
  '& .MuiAccordion-region': {
    height: expanded ? '100% !important' : undefined
  },
  '& .MuiAccordionDetails-root': {
    height: expanded ? '100% !important' : undefined
  }
}));

export { AccordiansContainer, AccordianStyled };
