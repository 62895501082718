import { useAuth0 } from '@auth0/auth0-react';
import { AUTH_CONFIG } from '@linx-ui/shared/constants';
import { useCurrentPageUserViews } from '../useCurrentPageUserViews';

export const useLogout = () => {
  const { logout } = useAuth0();
  const { removePageView } = useCurrentPageUserViews();

  const handleLogout = async () => {
    removePageView();
    await logout({ logoutParams: { returnTo: AUTH_CONFIG.redirectURL } });
  };

  return handleLogout;
};
