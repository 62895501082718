import { Auth0Provider, type AppState } from '@auth0/auth0-react';
import { type FC, type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { del } from 'idb-keyval';
import { AUTH_CONFIG, REACT_QUERY_INDEX_DB_KEY } from '@linx-ui/shared/constants';

export const Auth0ContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = async (appState?: AppState) => {
    localStorage.clear();
    sessionStorage.clear();
    await del(REACT_QUERY_INDEX_DB_KEY);
    if (appState?.returnTo != null) {
      navigate(appState.returnTo);
    } else {
      navigate(window.location.pathname);
    }
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={AUTH_CONFIG.domain!}
      clientId={AUTH_CONFIG.clientId!}
      authorizationParams={{
        connection: AUTH_CONFIG.connection,
        audience: AUTH_CONFIG.aud,
        redirect_uri: AUTH_CONFIG.redirectURL
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
