import { ShortcutIds } from '../shortcutIds';
import { Sections } from '../sections';
import type { ShortcutConfig } from '../types';

const QUEUE_ROUTE = '/rx/pharmacy-sc/queues';

export const PrescriptionSection: ShortcutConfig = [
  {
    id: ShortcutIds.PLACE_RX_ON_HOLD,
    description: 'Place Rx On Hold',
    shortcut: ['F1'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.TRANSFER_OUT,
    description: 'Transfer Out Prescription',
    shortcut: ['F2'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.CHANGE_REQUEST,
    description: 'Rx Change Request',
    shortcut: ['F3'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.RENEWAL_REQUEST,
    description: 'Rx Renewal Request',
    shortcut: ['F4'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.PROCESS_AS_CASH,
    description: 'Process as Cash',
    shortcut: ['F5'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.SUBMIT_PRIMARY_INS_CLAIM,
    description: 'Submit Claim to Primary Insurance',
    shortcut: ['F6'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.SUBMIT_SECONDARY_INS_CLAIM,
    description: 'Submit Claim to Secondary Insurance',
    shortcut: ['F7'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.SUBMIT_TERTIARY_INS_CLAIM,
    description: 'Submit Claim to Tertiary Insurance',
    shortcut: ['F8'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.PRINT_LABEL,
    description: 'Print Label',
    shortcut: ['F9'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.VERIFY_RX,
    description: 'Verify Rx',
    shortcut: ['F10'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.VERIFY_NDC,
    description: 'Verify NDC',
    shortcut: ['F11'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.OPEN_PREV_RX,
    description: 'Open Prev Rx Details',
    shortcut: ['alt', 'shift', 'Numpad9'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.OPEN_NEXT_RX,
    description: 'Open Next Rx Details',
    shortcut: ['alt', 'shift', 'Numpad3'],
    trigger: true,
    section: Sections.PRESCRIPTION,
    enableFor: QUEUE_ROUTE
  }
];
