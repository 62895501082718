import { LocalPharmacyOutlined } from '@mui/icons-material';
import { Autocomplete, Skeleton } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { TextWithEllipis } from '@linx-ui/shared/components/styles';
import { useGlobalContext } from '@linx-ui/shared/context';
import { getPharmacyId, savePharmacyId } from '@linx-ui/shared/utils';
import { type Pharmacy } from '@linx-ui/shared/types';
import { usePharmacies } from '../../hooks';
import { ButtonStyled, DropdownWrapper, TextFieldStyled } from './PharmacyDropdown.styled';
import { type Props } from './types';

export const PharmacyDropdown: React.FC<Props> = memo(({ open, handleClose }: Props) => {
  const globalPharmacyId = getPharmacyId();
  const { setPharmacyId } = useGlobalContext();
  const [pharmacyIds, setPharmacyIds] = useState<string[]>([]);
  const [currentPharmacyId, setCurrentPharmacyId] = useState(globalPharmacyId);
  const { pharmacies, isLoading: isLoadingPharmacies } = usePharmacies();

  useEffect(() => {
    pharmacies && setPharmacyIds(pharmacies.map((pharmacy: Pharmacy) => pharmacy.id!));
  }, [pharmacies]);

  useEffect(() => {
    if (!globalPharmacyId && pharmacyIds.length) {
      handleChange(pharmacyIds[0]);
    } else {
      setPharmacyId(globalPharmacyId!);
    }
  }, [globalPharmacyId, pharmacyIds]);

  const handleChange = (pharmacyId: string) => {
    setPharmacyId(pharmacyId);
    savePharmacyId(pharmacyId);
    setCurrentPharmacyId(pharmacyId);
    window.dispatchEvent(new Event('pharmacy-change'));
  };

  const getOptionLabel = (option: string) => {
    return pharmacies?.find((pharmacy: Pharmacy) => pharmacy.id === option)?.name ?? '';
  };

  const PharmacyMenu = () => {
    if ((pharmacies?.length || 0) > 1) {
      return (
        <Autocomplete
          data-testid="pharmacy-dropdown"
          value={currentPharmacyId!}
          onChange={(_, val) => {
            handleChange(val);
          }}
          disableClearable={true}
          getOptionLabel={getOptionLabel}
          options={pharmacyIds}
          renderInput={(params) => <TextFieldStyled {...params} variant="standard" placeholder="Select Pharmacy" />}
        />
      );
    }
    return <TextWithEllipis>{pharmacies[0]?.name}</TextWithEllipis>;
  };

  return (
    <DropdownWrapper>
      <ButtonStyled onClick={handleClose}>
        <LocalPharmacyOutlined />
      </ButtonStyled>
      {isLoadingPharmacies ? <Skeleton width="100%" height="32px" /> : <PharmacyMenu />}
    </DropdownWrapper>
  );
});
PharmacyDropdown.displayName = 'PharmacyDropdown';
