import { alpha, Card, CardContent, CardHeader, styled } from '@mui/material';
import type { ContainerVariant } from './types';

export const ContainerHeader = styled(CardHeader)<{ variant: ContainerVariant }>(({ theme, variant }) => ({
  alignItems: 'center',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
  backgroundColor:
    variant === 'primary'
      ? alpha(theme.palette.primary.light, 0.08)
      : variant === 'white'
        ? alpha(theme.palette.background.paper, 1)
        : alpha(theme.palette.secondary.light, 0.08),
  color:
    variant === 'primary'
      ? theme.palette.primary.dark
      : variant === 'white'
        ? theme.palette.getContrastText(theme.palette.background.paper)
        : theme.palette.secondary.dark,
  display: 'flex',
  height: '38px',
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  textAlign: 'left'
}));

export const ContainerContent = styled(CardContent)(({ theme }) => ({
  gap: theme.spacing(3),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  padding: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(2)
  }
}));

export const HeaderWrapper = styled('div')(({ theme }) => {
  return {
    position: 'sticky',
    zIndex: 999,
    top: 0,
    backgroundColor: theme.palette.background.paper
  };
});

export const StyledCard = styled(Card)(({ style }) => ({
  ...style,
  flexWrap: 'wrap',
  position: 'relative'
}));
