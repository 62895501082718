import { Grid, styled } from '@mui/material';

export const RowStyled = styled(Grid)(() => ({ gap: '16px' }));

export const GroupStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'last'
})<{ last: boolean }>(({ theme, last }) => ({
  display: 'flex',
  borderRight: !last ? `1px solid ${theme.palette.divider}` : undefined,
  gap: theme.spacing(2),
  flexDirection: 'row',
  paddingRight: theme.spacing(2)
}));

export const TabsAndProfilesCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
  marginRight: theme.spacing(3)
}));
