import { styled } from '@mui/material';

const ParentDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height'
})<{ height: string }>(({ height }) => ({
  maxHeight: height,
  overflow: 'auto'
}));

const ContainerWrapper = styled('div')<{ height: string }>(({ height }) => ({
  height,
  width: '100%',
  position: 'relative'
}));

const ItemContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'start' })<{ start: number }>(
  ({ start }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transform: `translateY(${start}px)`
  })
);

export { ParentDiv, ContainerWrapper, ItemContainer };
