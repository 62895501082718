import React, { createContext, useContext, useEffect, useState } from 'react';
import { CircularLoader } from '@linx-ui/shared/components/loaders';
import { type SnackBarState } from '@linx-ui/shared/components/snackBar';
import type { PharmacyContext } from '@linx-ui/shared/context/types';
import { usePermissions } from '@linx-ui/shared/core/auth/hooks/usePermissions';
import { getPharmacyId, savePharmacyId } from '@linx-ui/shared/utils/browserStorageUtils';
import { type GlobalContextObject } from './types';

const defaultValue: GlobalContextObject = {} as unknown as GlobalContextObject;
export const GlobalContext = createContext<GlobalContextObject>(defaultValue);
export const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    open: false,
    messages: []
  });

  const [pharmacyInformation, setPharmacyInformation] = useState<PharmacyContext>({ state: '' });

  const { authorizedPharmacies, contractId, ...auth } = usePermissions();
  const [pharmacyId, setPharmacyId] = useState<string>();

  useEffect(() => {
    if (!getPharmacyId() && authorizedPharmacies && authorizedPharmacies.length > 0) {
      savePharmacyId(authorizedPharmacies[0]);
    }
  }, [authorizedPharmacies]);

  if (auth.isAuthenticated && !auth.token.length) {
    return <CircularLoader />;
  }

  return (
    <GlobalContext.Provider
      value={{
        snackBarState,
        setSnackBarState,
        contractId,
        pharmacyId,
        setPharmacyId,
        auth,
        pharmacyInformation,
        setPharmacyInformation
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
