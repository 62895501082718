import { Typography } from '@mui/material';
import { LinxLogoCnt } from './LinxLogo.styled';

export const LinxLogo = () => {
  return (
    <LinxLogoCnt>
      <Typography variant="h4" fontWeight="700">
        LINX
      </Typography>
      <Typography variant="h4" fontWeight="400">
        HEALTH
      </Typography>
    </LinxLogoCnt>
  );
};
