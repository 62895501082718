export const getPrescriptionStatusChipColor = (status: string) => {
  switch (status) {
    case 'Incomplete':
    case 'Inactive':
    case 'On Hold':
    case 'Transferred':
      return 'warning';
    default:
      return 'success';
  }
};
