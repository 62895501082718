import { Divider, Grid, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { GridLayoutContainerWithSpacing2 } from '../gridLayout';
import { theme } from '../../theming';

export const DetailSectionWrapper = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    title: string;
    titleColor?: string;
    withoutDivider?: boolean;
  }
>(({ children, title, titleColor = theme.palette.secondary.dark, withoutDivider = false }, ref) => (
  <GridLayoutContainerWithSpacing2 ref={ref}>
    <Grid item>
      <Typography variant="subtitle2" color={titleColor}>
        {title}
      </Typography>
    </Grid>
    {children}
    {!withoutDivider && (
      <Grid item width="100%">
        <Divider sx={{ width: '100%' }} />
      </Grid>
    )}
  </GridLayoutContainerWithSpacing2>
));

DetailSectionWrapper.displayName = 'DetailSectionWrapper';
