import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { useState } from 'react';
import { AccordiansContainer, AccordianStyled } from './Accordians.styled';
import type { AccordianItem, AccordiansProps } from './types';

const Accordians = ({ items, selected, onSelect }: AccordiansProps) => {
  const [expanded, setExpanded] = useState<string | false>(selected);

  const getStyle = (isExpanded: boolean) => {
    return isExpanded ? { height: '100%', overflow: 'hidden' } : undefined;
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    event.stopPropagation();
    if (isExpanded) {
      onSelect?.(panel);
      setExpanded(panel);
    } else {
      setExpanded(false);
    }
  };

  return (
    <AccordiansContainer>
      {items.map((item: AccordianItem, index: number) => {
        return (
          <AccordianStyled
            TransitionProps={{
              timeout: 0
            }}
            key={index}
            sx={getStyle(expanded === item.key)}
            expanded={expanded === item.key}
            onChange={handleChange(item.key)}
          >
            <AccordionSummary
              data-testid={`${item.key}-header-accordian`}
              expandIcon={<ExpandMore />}
              aria-controls={`${item.key}-content`}
              id={`${item.key}-header`}
            >
              {item.title}
            </AccordionSummary>
            <AccordionDetails>{item.component}</AccordionDetails>
          </AccordianStyled>
        );
      })}
    </AccordiansContainer>
  );
};

export { Accordians };
