import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { CancelAction } from '@linx-ui/shared/components/Actions';
import { CheckboxGroupWrapper } from '@linx-ui/shared/components/checkboxGroupWrapper';
import { DrawerContainer } from '@linx-ui/shared/components/drawerContainer';
import { DRAWER_WIDTH_XSM, pharmacyIdKey, PRESCRIPTION_STATUSES, urls } from '@linx-ui/shared/constants';
import { usePostMutation, useReactQuery } from '@linx-ui/shared/hooks/api';
import type { UserPreferences } from '@linx-ui/shared/types/userPreferences';
import { getTriggerBtn } from '@linx-ui/shared/utils/helpers';
import { usePharmacyId } from '@linx-ui/shared/hooks';
import { notificationPreferencesLabels } from './labels';
import { ScrollableCnt } from './NotificationPreferences.styled';
import { REJECTION_OPTIONS } from './options';
import { WorkflowPreferences } from './workflowPreferences';

export const NotificationPreferences = () => {
  const { pharmacyId } = usePharmacyId();
  const { value: isDrawerOpen, toggle } = useBoolean();
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([]);

  const { data: userPreferences, isLoading: isLoadingUserPreferences } = useReactQuery<UserPreferences>({
    url: urls.userPreferences,
    headerKeys: [pharmacyIdKey]
  });

  const { mutate, isPending } = usePostMutation({
    url: urls.userPreferences,
    headerKeys: [pharmacyIdKey]
  });

  useEffect(() => {
    userPreferences?.notification?.prescription &&
      setSelectedPreferences(
        Object.entries(userPreferences.notification.prescription)
          .filter(([_, value]) => value)
          .map(([key]) => key)
      );
  }, [isDrawerOpen, userPreferences]);

  const triggerButton = getTriggerBtn({
    size: 'small',
    variant: 'text',
    testId: 'notification-preference-btn',
    text: notificationPreferencesLabels.triggerButtonTxt,
    style: { color: 'white' }
  });

  const handleSave = () => {
    mutate(
      {
        pharmacyId,
        notification: {
          ...(userPreferences?.notification || {}),
          prescription: Object.fromEntries(selectedPreferences.map((preference) => [preference, true]))
        }
      },
      { onSuccess: toggle }
    );
  };

  const actions = [
    <CancelAction key="cancel" onClick={toggle} btnText={notificationPreferencesLabels.cancelBtn} />,
    <Button key="add" variant="contained" data-testid="submit-btn" onClick={handleSave}>
      {notificationPreferencesLabels.submitBtn}
    </Button>
  ];

  const handleGroupCheckboxChange = useCallback((checked: boolean, preferences: string[]) => {
    setSelectedPreferences((selectedPreferences) =>
      checked
        ? Array.from(new Set([...selectedPreferences, ...preferences]))
        : selectedPreferences.filter((preference) => !preferences.includes(preference))
    );
  }, []);

  const handleSingleCheckboxChange = useCallback(
    (checked: boolean, preferences: string) =>
      setSelectedPreferences((selectedPreferences) =>
        checked
          ? [...selectedPreferences, preferences]
          : selectedPreferences.filter((_preferences) => _preferences !== preferences)
      ),
    []
  );

  return (
    <DrawerContainer
      triggerBtn={triggerButton}
      openDrawer={isDrawerOpen}
      toggle={toggle}
      title={notificationPreferencesLabels.drawerTitle}
      width={DRAWER_WIDTH_XSM}
      actionBtnsRight={actions}
      loader={isLoadingUserPreferences || isPending}
    >
      <ScrollableCnt>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            {notificationPreferencesLabels.workflowSubscriptionTitle}
          </AccordionSummary>
          <AccordionDetails>
            <WorkflowPreferences
              selectedValues={selectedPreferences}
              onGroupCheckboxChange={handleGroupCheckboxChange}
              onSingleCheckboxChange={handleSingleCheckboxChange}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            {notificationPreferencesLabels.prescriptionStatusSubscriptionTitle}
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroupWrapper
              selectedValues={selectedPreferences}
              label="Rx Status Notifications"
              onGroupCheckboxChange={handleGroupCheckboxChange}
              onSingleCheckboxChange={handleSingleCheckboxChange}
              options={PRESCRIPTION_STATUSES.map(({ label, value }) => ({
                label,
                value: `PRESCRIPTION_STATUS/${value}`
              }))}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            {notificationPreferencesLabels.rejectionSubscriptionTitle}
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroupWrapper
              selectedValues={selectedPreferences}
              label="Rejection Notifications"
              onGroupCheckboxChange={handleGroupCheckboxChange}
              onSingleCheckboxChange={handleSingleCheckboxChange}
              options={REJECTION_OPTIONS.map(({ label, value }) => ({
                label,
                value: `REJECTION/${value}`
              }))}
            />
          </AccordionDetails>
        </Accordion>
      </ScrollableCnt>
    </DrawerContainer>
  );
};
