import { Menu, styled } from '@mui/material';

const IconContainer = styled('div')(({ theme }) => {
  return {};
});

const MenuStyled = styled(Menu)(({ theme }) => {
  return {
    maxHeight: '360px'
  };
});

export { IconContainer, MenuStyled };
