import { createContext, useCallback, useContext, useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  defaultShortcuts,
  type KeyboardShortcutWithNavigate,
  type KeyboardShortcutWithTrigger,
  type ShortcutConfig,
  type ShortcutId,
  type ShortcutIds
} from '@linx-ui/shared/constants';
import { getFromLocalStorage, saveToLocalStorage } from '@linx-ui/shared/utils';

export type RegisteredShortcut = {
  [Property in keyof typeof ShortcutIds]?: { callback: VoidFunction; disabled: boolean };
};

export type SavedShortcuts = {
  [Property in keyof typeof ShortcutIds]?: string[];
};

export interface KeyboardContextType {
  getActiveShortcuts: (shouldGetAllNavigation?: boolean) => ShortcutConfig;
  keyboardShortcuts: ShortcutConfig;
  updateShortcut: (id: string, shortcut: string[]) => void;
  shortcutsEnabled: boolean;
  setShortcutsEnabled: (enabled: boolean) => void;
  registerShortcut: (id: ShortcutId, callback: VoidFunction, disabled: boolean) => void;
  unregisterShortcut: (id: ShortcutId) => void;
  activateShortcut: (id: ShortcutId) => void;
}

const KeyboardContext = createContext<KeyboardContextType>({
  keyboardShortcuts: [],
  getActiveShortcuts: () => [],
  updateShortcut: () => null,
  shortcutsEnabled: false,
  setShortcutsEnabled: () => null,
  registerShortcut: () => null,
  unregisterShortcut: () => null,
  activateShortcut: () => null
});

export const KeyboardShortcutsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [registeredShortcuts, setRegisteredShortcuts] = useState<RegisteredShortcut>({});

  const triggerShortcut = useCallback(
    (id: ShortcutId) => {
      const targetShortcut = registeredShortcuts[id];
      !targetShortcut?.disabled && targetShortcut?.callback();
    },
    [registeredShortcuts]
  );

  const [keyboardShortcuts, setKeyboardShortcuts] = useState(defaultShortcuts);
  const [shortcutsEnabled, setShortcutsEnabled] = useState(true);

  useEffect(() => {
    const savedShortcuts: SavedShortcuts | null = getFromLocalStorage('keyboard-shortcuts');
    if (savedShortcuts) {
      setKeyboardShortcuts((shortcuts) =>
        shortcuts.map((s) => {
          if (s.id in savedShortcuts) {
            return { ...s, shortcut: savedShortcuts[s.id]! };
          }
          return s;
        })
      );
    }
  }, []);

  const registerShortcut: KeyboardContextType['registerShortcut'] = (id, callback, disabled) => {
    setRegisteredShortcuts((shortcuts) => {
      return { ...shortcuts, [id]: { callback, disabled } };
    });
  };

  const unregisterShortcut: KeyboardContextType['unregisterShortcut'] = (id) => {
    setRegisteredShortcuts((shortcuts) => {
      const { [id]: _, ...rest } = shortcuts;
      return rest;
    });
  };

  const updateShortcut: KeyboardContextType['updateShortcut'] = (id, shortcut) => {
    setKeyboardShortcuts((shortcuts) => {
      const newShortcuts = shortcuts.map((s) => {
        if (s.id === id) {
          return { ...s, shortcut };
        }
        return s;
      });
      const newShortcutsMap = newShortcuts.reduce((acc, current) => {
        return { ...acc, [current.id]: current.shortcut };
      }, {});
      saveToLocalStorage('keyboard-shortcuts', newShortcutsMap);
      return newShortcuts;
    });
  };

  const eventHandler = useCallback(
    (e: KeyboardEvent) => {
      const { code, ctrlKey, altKey, shiftKey } = e;
      if (!shortcutsEnabled) return;
      if (ctrlKey && shiftKey) return;
      const pressed = [ctrlKey ? 'ctrl' : '', altKey ? 'alt' : '', shiftKey ? 'shift' : '', code]
        .filter((k) => k)
        .sort();

      const existingShortcut = keyboardShortcuts.find(
        (s) => [...s.shortcut].sort().join('+') === pressed.join('+') && location.pathname.includes(s.enableFor)
      );
      if (!existingShortcut) return;

      if ('navigate' in existingShortcut) {
        navigate(existingShortcut.navigate);
        e.preventDefault();
        e.stopPropagation();
      } else if (existingShortcut.trigger) {
        triggerShortcut(existingShortcut.id);
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [keyboardShortcuts, shortcutsEnabled, triggerShortcut, location]
  ) as EventListener;

  const isNavigationShortcut = (
    shortcut: KeyboardShortcutWithTrigger | KeyboardShortcutWithNavigate
  ): shortcut is KeyboardShortcutWithNavigate => {
    return !(shortcut as KeyboardShortcutWithTrigger).trigger;
  };

  const getActiveShortcuts = useCallback(
    (shouldGetAllNavigation?: boolean) =>
      keyboardShortcuts.filter((s) => {
        if (!shouldGetAllNavigation && location.pathname.includes(s.enableFor)) {
          if (!isNavigationShortcut(s)) {
            return !!registeredShortcuts[s.id]?.callback;
          }
          return true;
        }
        if (shouldGetAllNavigation) {
          if (isNavigationShortcut(s)) {
            return true;
          }
          if (location.pathname.includes(s.enableFor)) {
            return !!registeredShortcuts[s.id]?.callback;
          }
        }
        return false;
      }),
    [location, keyboardShortcuts, registeredShortcuts]
  );

  const activateShortcut = useCallback(
    (id: ShortcutId) => {
      const existingShortcut = getActiveShortcuts(true).find((s) => s.id === id);
      if (existingShortcut) {
        if ('navigate' in existingShortcut) {
          navigate(existingShortcut.navigate);
        } else if (existingShortcut.trigger) {
          triggerShortcut(id);
        }
      }
    },
    [getActiveShortcuts, triggerShortcut, navigate]
  );

  useEffect(() => {
    window.addEventListener('keydown', eventHandler);
    return () => {
      window.removeEventListener('keydown', eventHandler);
    };
  }, [eventHandler]);

  return (
    <KeyboardContext.Provider
      value={{
        getActiveShortcuts,
        keyboardShortcuts,
        updateShortcut,
        setShortcutsEnabled,
        shortcutsEnabled,
        registerShortcut,
        unregisterShortcut,
        activateShortcut
      }}
    >
      {children}
    </KeyboardContext.Provider>
  );
};

export const useKeyboard = () => {
  return useContext(KeyboardContext);
};

export const useKeyboardTrigger = (
  id: (typeof ShortcutIds)[keyof typeof ShortcutIds] | undefined,
  callback: VoidFunction | undefined,
  disabled = false
) => {
  const { registerShortcut, unregisterShortcut } = useKeyboard();

  useEffect(() => {
    if (id && callback) {
      registerShortcut(id, callback, disabled);
      return () => {
        unregisterShortcut(id);
      };
    }
  }, [id, disabled]);
};
