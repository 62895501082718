import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useMemo } from 'react';
import { NumberTextField } from '../numberTextField';
import type { TextFieldWrapperProps } from './types';

const TextFieldWrapper = ({ type, ...props }: TextFieldWrapperProps): JSX.Element => {
  const Component = useMemo(() => (type === 'number' ? NumberTextField : TextField), [type]);
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue ?? ''}
      render={({ field: { value, onBlur, onChange, ...rest } }) => (
        <Component
          data-testid={props.dataTestId}
          type={type}
          size={props.size}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          value={value ?? ''}
          InputProps={{ ...props.inputProps, disableInjectingGlobalStyles: true }}
          InputLabelProps={props.InputLabelProps}
          {...rest}
          onChange={props.customOnChange?.(onChange) ?? onChange}
          onBlur={(e) => {
            onBlur();
            props.customOnBlur?.(e.target.value);
          }}
          multiline={props.multiline}
          rows={props.rows}
          onFocus={() => props.onFocus?.()}
          label={props.label}
          error={props.error?.hasError}
          helperText={props.error?.message ?? props?.helperText}
          variant={props.variant || 'outlined'}
          inputProps={{
            maxLength: props.rules?.maxLength,
            role: props.role
          }}
          fullWidth
          required={!!props.rules?.required}
        />
      )}
    />
  );
};
export { TextFieldWrapper };
