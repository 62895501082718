import { Typography } from '@mui/material';

const getDetailsTitle = (value: string) => {
  return (
    <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h5">
      {value}
    </Typography>
  );
};

export { getDetailsTitle };
