import type { Pharmacy } from '@linx-ui/shared/types';
import { urls } from '@linx-ui/shared/constants';
import { useReactQuery } from '@linx-ui/shared/hooks/api/useReactQuery';
import type { UseReactQueryParams } from '@linx-ui/shared/hooks/api/useReactQuery/types';

type Options = Partial<UseReactQueryParams<Pharmacy[]>> & { contractId?: string };

export const useContractPharmacies = (options: Options) => {
  const { enabled = true, contractId, ...restOptions } = options;

  const { data, ...restResult } = useReactQuery<Pharmacy[]>({
    url: `${urls.contracts}/${contractId}/pharmacies`,
    enabled: !!contractId && enabled,
    initialData: [],
    ...restOptions
  });

  return {
    ...restResult,
    pharmacies: data!,
    isLoading: restResult.isFetching && data?.length === 0
  };
};
