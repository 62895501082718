import { styled } from '@mui/material';

const FileUploadContainer = styled('div')<{ focused: boolean }>(({ theme, focused }) => ({
  border: `2px dashed ${!focused ? theme.palette.grey[300] : theme.palette.secondary.main}`,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(4, 0, 4, 0),
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
}));

export { FileUploadContainer };
