import type { ColumnDef, FilterFn, GroupColumnDef } from '@tanstack/react-table';

const getColumnsWithDefaultConfigs = <T extends Record<string, any>>(
  columns: Array<ColumnDef<T> | GroupColumnDef<T>>
) =>
  columns.map((column: ColumnDef<T> | GroupColumnDef<T>) => {
    const modifiedColum: ColumnDef<T> | GroupColumnDef<T> = Object.assign({}, column);
    if (!modifiedColum.sortingFn) {
      modifiedColum.sortingFn = 'text';
    }

    if ((modifiedColum as GroupColumnDef<T>).columns?.length) {
      (modifiedColum as GroupColumnDef<T>).columns = getColumnsWithDefaultConfigs(
        (modifiedColum as GroupColumnDef<T>)?.columns ?? []
      );
    }
    return modifiedColum;
  });

const tableFilterFn: FilterFn<any> = (row, columnId, value) => {
  const mainRowMatched = String(row.getValue(columnId)).toLowerCase().includes(value.toLowerCase());

  const subRowMatched = row.subRows.reduce((ac, subRow) => {
    ac = ac || String(subRow.getValue(columnId)).toLowerCase().includes(value.toLowerCase());
    return ac;
  }, false);

  return mainRowMatched || subRowMatched;
};

export { getColumnsWithDefaultConfigs, tableFilterFn };
