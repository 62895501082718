import { Box, styled } from '@mui/material';

const TableWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default
}));

export { TableWrapper };
