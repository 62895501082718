import { styled } from '@mui/system';

const getSize = (index: number, total: number) => {
  const offset = 64 + (total - 1) * 32;
  const isMiddle = index !== 0 && index !== total - 1;
  return offset + total * 8 + (isMiddle ? 8 : 0);
};

const NotesAndDocumentsCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'total'
})<{ index: number; total: number }>(({ theme, index, total }) => ({
  margin: theme.spacing(1),
  height: `calc(100% - ${getSize(index, total)}px)`
}));

export { NotesAndDocumentsCnt };
