import { GeneralSection } from './sections/general';
import { QueueSection } from './sections/queue';
import { ConfigurationSection } from './sections/configurations';
import type { ShortcutConfig } from './types';
import { InventorySection } from './sections/inventory';
import { profilesSection } from './sections/profiles';
import { PrescriptionSection } from './sections/prescriptions';

export const defaultShortcuts: ShortcutConfig = [
  ...GeneralSection,
  ...PrescriptionSection,
  ...QueueSection,
  ...ConfigurationSection,
  ...InventorySection,
  ...profilesSection
];
