import { styled, TextField } from '@mui/material';

const ToolbarContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    height: '100%'
  };
});

const Left = styled('div')(({ theme }) => {
  return {};
});

const Right = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  };
});

const TextFieldStyled = styled(TextField)(({ theme }) => {
  return {
    width: '325px',
    [theme.breakpoints.down('md')]: {
      width: '201px'
    },
    [theme.breakpoints.up('md')]: {
      width: '325px'
    }
  };
});
export { ToolbarContainer, Left, Right, TextFieldStyled };
