interface FileInputProps {
  title: string;
  subTitle: string;
  icon?: React.ReactNode;
  multiple?: boolean;
  accept?: Record<string, string[]>;
  onFilesChosen?: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  maxSize?: number;
  disabled?: boolean;
}

enum FileRejectionErrorCode {
  FileInvalidType = 'file-invalid-type',
  FileTooLarge = 'file-too-large',
  FileTooSmall = 'file-too-small',
  TooManyFiles = 'too-many-files'
}

interface FileRejection {
  file: File;
  errors: Array<{ message: string; code: FileRejectionErrorCode | string }>;
}

type Accept = Record<string, string[]>;

export { type FileInputProps, FileRejectionErrorCode, type FileRejection, type Accept };
