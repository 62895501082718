import { type Query } from '@tanstack/react-query';

export const shouldDehydrateQuery = (query: Query) => {
  if (query.queryKey[0] && typeof query.queryKey[0] === 'string') {
    const [url, , params] = query.queryKey as [string, Record<string, unknown>, Record<string, unknown>];

    return (
      !!params || // Save the list data
      query.queryKey.length === 1 || // Save the key which is used to save the state
      !!url.match(/\/api\/pharmacies\/[a-z0-9-]+$/) || // Save the pharmacy details
      !!url.match(/\/contracts\/(.+)\/pharmacies/) // Save contract pharmacies
    );
  } else {
    return false;
  }
};
