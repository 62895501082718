import type { NavigationItem, NavigationOption } from '@linx-ui/shared/components';
import type { AllActionType, FeaturesNames, useFeaturePermissions } from '@linx-ui/shared/core';

const filterItems = (
  navigationItems: NavigationItem[],
  hasPermissionToView: (featureName: FeaturesNames) => (actionType: AllActionType) => boolean
): NavigationItem[] =>
  navigationItems
    .filter((item): boolean => {
      if (item.subItems.length > 0) {
        return filterItems(item.subItems, hasPermissionToView).length > 0;
      }
      return item.checkPermission ? hasPermissionToView(item.featureName)(item.actionType) : true;
    })
    .map((item) => ({ ...item, subItems: filterItems(item.subItems, hasPermissionToView) }));

export const filterNavigationOption = (
  navigationOptions: NavigationOption[],
  hasPermissionToView: ReturnType<typeof useFeaturePermissions>
) =>
  navigationOptions
    .filter((option) => filterItems(option.navigationItems, hasPermissionToView).length > 0)
    .map((option) => {
      const navigationItems = filterItems(option.navigationItems, hasPermissionToView);
      return {
        ...option,
        navigationItems
      };
    });
