import { Badge } from '@mui/material';
import { type FC } from 'react';
import { theme } from '@linx-ui/shared/theming';
import { type TabBadgeProps } from './types';

export const TabBadge: FC<TabBadgeProps> = ({ count }) => {
  return (
    <Badge
      badgeContent={count}
      sx={{
        color: theme.palette.primary.contrastText,
        marginLeft: `${theme.spacing(2)} !important`,
        '& .MuiBadge-badge': {
          backgroundColor: theme.palette.primary.main
        }
      }}
    />
  );
};
