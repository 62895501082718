import { styled } from '@mui/material';

export const CheckboxGroupListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(4),
  marginTop: theme.spacing(1),
  '& > div + label': {
    marginTop: theme.spacing(2)
  }
}));
