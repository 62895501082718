import type { Pharmacy, PaginatedResult } from '@linx-ui/shared/types';
import { urls } from '@linx-ui/shared/constants';
import type { UseReactQueryParams } from '@linx-ui/shared/hooks/api/useReactQuery/types';
import { useReactQueryList } from '@linx-ui/shared/hooks/api/useReactQueryList';

type Options = Partial<UseReactQueryParams<PaginatedResult<Pharmacy>>>;

export const useAllPharmacies = (options?: Options) => {
  const { data, ...restResult } = useReactQueryList<Pharmacy>({
    url: urls.pharmacies,
    ...options
  });

  return {
    ...restResult,
    pharmacies: data?.items,
    isLoading: restResult.isFetching && (data?.items?.length || 0) === 0
  };
};
