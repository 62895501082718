import { Dialog } from '@linx-ui/shared/components/dialog';
import { StyledDeleteBtn } from '@linx-ui/shared/components/styles';
import { useDeleteWrapper } from '@linx-ui/shared/hooks/useDeleteWrapper';
import type { DeleteProps } from './types';

const Delete = ({
  title,
  deleteText,
  confirmBtnText = 'Delete',
  confirmationMsg,
  url,
  pharmacyIdKey,
  successToastMsg,
  successToastTitle,
  backRoute,
  disabled,
  icon,
  onSuccess,
  reqBody,
  listQueryParams,
  useOptimisticUpdate,
  cancelBtnText,
  isInfinite = false
}: DeleteProps) => {
  const { deleteEntity } = useDeleteWrapper({
    url,
    isInfinite,
    listQueryParams,
    redirectTo: backRoute,
    successToastTitle,
    successToastMsg,
    useOptimisticUpdate,
    headerKeys: pharmacyIdKey ? [pharmacyIdKey] : [],
    onDelete: onSuccess
  });

  const onConfirmDelete = () => deleteEntity(reqBody);

  return (
    <Dialog
      title={title}
      confirmBtnText={confirmBtnText}
      confirmBtnColor="error"
      onConfirm={onConfirmDelete}
      cancelBtnText={cancelBtnText}
      TriggerComponent={(props: { onClick: () => void }) => (
        <StyledDeleteBtn onClick={props.onClick} disabled={disabled} icon={icon}>
          {deleteText}
        </StyledDeleteBtn>
      )}
    >
      <>{confirmationMsg} ?</>
    </Dialog>
  );
};

export { Delete };
