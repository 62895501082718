import { Button } from '@mui/material';
import { type DrawerTriggerBtn } from '@linx-ui/shared/components';
import { type TriggerBtnProps } from './types';

const getTriggerBtn = ({
  text,
  PrefixIcon,
  variant = 'outlined',
  color = 'primary',
  testId = 'trigger-action-btn',
  ...restProps
}: TriggerBtnProps) => {
  const TriggerBtn: DrawerTriggerBtn = ({ onClick }) => (
    <Button
      onClick={onClick}
      data-testid={testId}
      variant={variant}
      color={color}
      startIcon={PrefixIcon && <PrefixIcon fontSize="small" />}
      {...restProps}
    >
      {text}
    </Button>
  );

  return TriggerBtn;
};

export { getTriggerBtn };
