import { useRef, type FC } from 'react';
import { useVirtual } from 'react-virtual';
import { ContainerWrapper, ItemContainer, ParentDiv } from './DynamicRowVirtualizer.styled';
import { type DynamicRowVirtualizerProps } from './types';

const DynamicRowVirtualizer: FC<DynamicRowVirtualizerProps> = ({ size, Rows, containerHeight }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtual({
    parentRef,
    size,
    overscan: 3
  });
  const { virtualItems } = rowVirtualizer;

  return (
    <ParentDiv height={containerHeight} ref={parentRef}>
      <ContainerWrapper height={`${rowVirtualizer.totalSize}px`}>
        <ItemContainer start={virtualItems[0]?.start}>
          {virtualItems.map((virtualItem) => {
            return <Rows key={virtualItem.index} ref={rowVirtualizer.measure} index={virtualItem.index} />;
          })}
        </ItemContainer>
      </ContainerWrapper>
    </ParentDiv>
  );
};

export { DynamicRowVirtualizer };
