import { Grid } from '@mui/material';
import { Children, type FC } from 'react';
import { type Props } from './types';

const GridLayoutContainer: FC<Props> = ({ children, ...rest }: Props) => {
  const items = Children.toArray(children);
  return (
    <Grid container {...rest}>
      {items.map((mItem) => {
        return mItem;
      })}
    </Grid>
  );
};

const GridLayoutContainerWithSpacing2: FC<Props> = ({ children, spacing = 2, ...rest }: Props) => {
  const items = Children.toArray(children);
  return (
    <Grid container spacing={spacing} {...rest}>
      {items.map((mItem) => {
        return mItem;
      })}
    </Grid>
  );
};

const GridLayoutContainerWithSpacing1: FC<Props> = ({ children, spacing = 1, ...rest }: Props) => {
  const items = Children.toArray(children);
  return (
    <Grid container spacing={spacing} {...rest}>
      {items.map((mItem) => {
        return mItem;
      })}
    </Grid>
  );
};

export { GridLayoutContainer, GridLayoutContainerWithSpacing2, GridLayoutContainerWithSpacing1 };
