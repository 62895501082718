import { memo, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { usePharmacyId } from '@linx-ui/shared/hooks';
import { Content } from '../styles';

const _OutletContainer = () => {
  const excludedPaths = ['keyboard-shortcuts', 'configurations/general', 'pharmacy-details'];
  const navigate = useNavigate();
  const { pharmacyId } = usePharmacyId();
  const { pathname } = useLocation();
  const oldPharmacyId = useRef(pharmacyId);

  useEffect(() => {
    if (oldPharmacyId.current !== pharmacyId) {
      oldPharmacyId.current = pharmacyId;
      const profile = pathname.split('/').slice(0, 4).join('/');
      !excludedPaths.some((value) => pathname.includes(value)) &&
        !pathname.includes('list') &&
        navigate(`${profile}/list`);
    }
  }, [pathname, pharmacyId, oldPharmacyId]);

  return (
    <Content>
      <Outlet></Outlet>
    </Content>
  );
};

export const OutletContainer = memo(_OutletContainer);
