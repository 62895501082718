import { TableHeader, TextStyled } from '../styles';
import { ComingSoonWrapper } from './ComingSoon.styled';

const ComingSoon: React.FC<{ title: string; subTitle: string }> = ({ title, subTitle }) => {
  return (
    <TableHeader>
      <ComingSoonWrapper>
        <TextStyled variant="h5">{title}</TextStyled>
        {subTitle != null && <TextStyled variant="subtitle1">{subTitle}</TextStyled>}
      </ComingSoonWrapper>
    </TableHeader>
  );
};
export { ComingSoon };
