import axios from 'axios';
import { useCallback, useState } from 'react';
import { UploadStatuses } from '@linx-ui/shared/components/uploader/types';

export const useFileUpload = (onSuccess?: () => void) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatuses>(UploadStatuses.SECONDARY);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [abortController, setAbortController] = useState(new AbortController());

  const uploadFile = useCallback(
    (url: string, file: File) => {
      axios
        .put(url, file, {
          headers: { 'Content-Type': file.type },
          signal: abortController.signal,
          onUploadProgress: (e) => {
            e.loaded && e.total && setUploadProgress((e.loaded / e.total) * 100);
          }
        })
        .then(() => {
          onSuccess?.();
          return null;
        })
        .catch((e) => {
          setUploadStatus(UploadStatuses.WARNING);
        });
    },
    [abortController, onSuccess, setUploadStatus]
  );

  return {
    uploadStatus,
    uploadProgress,
    setUploadStatus,
    setUploadProgress,
    abortController,
    setAbortController,
    uploadFile
  };
};
