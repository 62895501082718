import { useQueryClient } from '@tanstack/react-query';
import { getHeadersFromKeys } from '../../../utils/getHeadersFromKeys';

interface QueryParams {
  url: string;
  headerKeys?: string[];
  params?: Record<string, any>;
  data: any;
  isPlaceholderData?: boolean;
}

export const useSetQueryData = () => {
  const queryClient = useQueryClient();

  return ({ url, headerKeys = [], params, data, isPlaceholderData = true }: QueryParams) => {
    const headers = getHeadersFromKeys(headerKeys);
    const queryKey = [url, headers, params];
    const existingQueryData = queryClient.getQueryData(queryKey) ?? {};
    const newQueryData = { ...existingQueryData, ...data };

    isPlaceholderData
      ? queryClient.setQueryDefaults(queryKey, { placeholderData: newQueryData })
      : queryClient.setQueryData(queryKey, newQueryData);
  };
};
