import { DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useCallback, useEffect, useState } from 'react';
import { useKeyboardTrigger } from '@linx-ui/shared/context';
import { LoadingButton } from '../loadingButton';
import { StyledCancelButton } from './Dialog.styled';
import { type DialogProps } from './types';

const DraggableDialog: React.FC<DialogProps> = ({
  isOpen = false,
  isLoading,
  title,
  confirmBtnText,
  confirmBtnColor = 'primary',
  onConfirm,
  onCancel,
  cancelBtnText = 'Cancel',
  TriggerComponent,
  children,
  identifier,
  keyboardShortcut,
  disabled = false
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  useKeyboardTrigger(keyboardShortcut, handleClickOpen, disabled);

  const handleClose = () => {
    onCancel?.();
    setOpen(false);
  };

  const handleConfirmation = async () => {
    await onConfirm?.(identifier);
    setOpen(false);
  };

  return (
    <>
      {TriggerComponent && <TriggerComponent onClick={handleClickOpen} />}
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <StyledCancelButton size="small" onClick={handleClose}>
            {cancelBtnText}
          </StyledCancelButton>
          <LoadingButton
            variant="contained"
            size="small"
            loading={isLoading}
            color={confirmBtnColor}
            onClick={handleConfirmation}
            data-testid="dialog-confirm-action"
          >
            {confirmBtnText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { DraggableDialog };
