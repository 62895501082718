import { useLocation } from 'react-router-dom';
import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { usePermissions } from '@linx-ui/shared/core';
import { db } from '@linx-ui/shared/core/firebase/app';

export const useCurrentPageUserViews = () => {
  const location = useLocation();
  const { userName, userId } = usePermissions();
  const userViewsCollectionName = location.pathname.replaceAll('/', '\\');
  const docRef = useMemo(
    () => userId && db && userViewsCollectionName && doc(db, userViewsCollectionName, userId),
    [userId, userViewsCollectionName]
  );

  const writePageView = useCallback(() => {
    if (docRef) {
      void setDoc(docRef, {
        userId,
        userName,
        lastViewed: moment().utc().format()
      });
    }
  }, [userId, userName, docRef]);

  const removePageView = useCallback(() => {
    if (docRef) {
      void deleteDoc(docRef);
    }
  }, [docRef]);

  return { docRef, writePageView, removePageView, userViewsCollectionName };
};
