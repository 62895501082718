import { Circle } from '@mui/icons-material';
import { Avatar, Chip, alpha, styled } from '@mui/material';

export const NotificationContentCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

export const StatusChip = styled(Chip)<{ color?: 'success' | 'warning' }>(({ theme, color = 'success' }) => ({
  background: alpha(theme.palette[color].light, 0.12),
  color: theme.palette[color].dark
}));

export const NotificationItemTopCnt = styled('div')<{ isRead: boolean }>(({ theme, isRead }) => ({
  cursor: isRead ? 'default' : 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: '100%'
}));

export const NotificationItemTopMiddleCnt = styled('div')(({ theme }) => ({
  flex: 4,
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'column'
}));

export const TypeAndTimeCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

export const NotificationThemedText = styled('span')<{ color?: 'success' | 'secondary' | 'warning' }>(
  ({ theme, color = 'success' }) => ({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '19.92px',
    color: theme.palette[color].main,
    letterSpacing: '0.4000000059604645px',
    textAlign: 'left'
  })
);

export const NotificationIconCnt = styled(Avatar)<{ color?: 'success' | 'secondary' | 'warning' }>(
  ({ theme, color = 'success' }) => ({
    background: alpha(theme.palette[color].light, 0.12),
    color: theme.palette[color].main
  })
);

export const Dot = styled(Circle)(() => ({
  fontSize: '4px'
}));

export const UnreadDot = styled(Circle)(() => ({
  fontSize: '12px'
}));
