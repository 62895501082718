import { StoreOutlined } from '@mui/icons-material';
import { Tab } from '@mui/material';
import { Link } from 'react-router-dom';
import { useRouteMatch } from '@linx-ui/shared/hooks/useRouteMatch';
import {
  AppBarStyled,
  BoxStyled,
  EmptyBoxStyled,
  HeaderTabsStyled,
  IconButtonStyled,
  tabMinHeight,
  ToobarStyled
} from '../../styles';
import { type HeaderProps } from './types';

export const Header: React.FC<HeaderProps> = ({ open, handleClose, items = [], globals = [] }: HeaderProps) => {
  const currentTab = useRouteMatch(items.map((el) => el.route));

  return (
    <BoxStyled>
      <AppBarStyled open={open}>
        <ToobarStyled>
          <IconButtonStyled edge="start" aria-label="open-drawer" onClick={handleClose} open={open}>
            <StoreOutlined />
          </IconButtonStyled>

          {items.length > 0 && (
            <HeaderTabsStyled value={currentTab ?? false}>
              {items.map(({ key, title, route, icon }) => {
                return (
                  <Tab
                    style={tabMinHeight}
                    icon={icon}
                    iconPosition="start"
                    key={key}
                    label={title}
                    value={route}
                    component={Link}
                    to={route}
                  />
                );
              })}
            </HeaderTabsStyled>
          )}

          <EmptyBoxStyled />

          <BoxStyled>
            <>
              {globals.map(({ key, component: Component, componentProps }) => {
                return <Component key={key} {...componentProps} />;
              })}
            </>
          </BoxStyled>
        </ToobarStyled>
      </AppBarStyled>
    </BoxStyled>
  );
};
