import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  type CSSObject,
  type Theme
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { DRAWER_WIDTH } from '@linx-ui/shared/constants';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8.5)} + 1px)`
  }
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})<{ open: boolean }>(({ theme, open }) => {
  return {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  };
});

const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (props) => props !== 'open'
})<any>(({ open, theme }) => ({
  minHeight: 48,
  justifyContent: open === true ? 'initial' : 'center',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  borderRadius: theme.spacing(0.5)
}));

const ListItemIconStyled = styled(ListItemIcon, {
  shouldForwardProp: (props) => props !== 'open'
})<{ open: boolean; selected?: boolean }>(({ theme, open, selected = false }) => ({
  minWidth: 0,
  color: selected ? theme.palette.primary.light : 'inherit',
  marginRight: open ? theme.spacing(3) : 'auto',
  justifyContent: 'center'
}));

const ListItemTextStyled = styled(ListItemText, {
  shouldForwardProp: (props) => props !== 'open'
})<{ open: boolean; selected?: boolean }>(({ theme, open, selected = false }) => ({
  color: selected ? theme.palette.primary.light : 'inherit',
  opacity: open ? 1 : 0
}));

const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (props) => props !== 'open'
})(({ theme }) => ({ display: 'block', paddingRight: theme.spacing(1), paddingLeft: theme.spacing(1) }));

const HideMenuStyled = styled(ListItem, {
  shouldForwardProp: (props) => props !== 'open'
})(({ theme }) => ({
  display: 'block',
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  minHeight: '55.5px'
}));

const BottomList = styled(List)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end'
}));

const ListTitle = styled(
  ListItemText,
  {}
)(({ theme }) => ({
  flex: 0,
  padding: theme.spacing(1, 2)
}));

export {
  closedMixin,
  Drawer,
  BottomList,
  ListItemButtonStyled,
  HideMenuStyled,
  ListItemIconStyled,
  ListItemStyled,
  ListItemTextStyled,
  ListTitle,
  openedMixin
};
