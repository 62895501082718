import { CircularProgress, IconButton } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useReactQuery } from '@linx-ui/shared/hooks/api/useReactQuery';
import { useDeleteWrapper } from '@linx-ui/shared/hooks/useDeleteWrapper';
import { useDownloadFile } from '@linx-ui/shared/hooks/useDownloadFile';
import { theme } from '@linx-ui/shared/theming';
import { getDisplayDateTimeWithSlashFormatter } from '@linx-ui/shared/utils';
import { type ListItemProps } from '../types';
import {
  CancelIconCnt,
  CreatedBy,
  DeleteIcon,
  DownloadIcon,
  FileInfoCnt,
  FileName,
  ListItemCnt,
  ProgressCnt,
  SmallCircularProgress
} from './ListItem.styled';

const ListItem = ({
  fileName,
  folderName,
  recentHistory,
  headerKeys,
  url,
  entityId,
  id,
  onDeleteSuccess
}: ListItemProps) => {
  const { deleteEntity, isDeleting } = useDeleteWrapper({
    url: `${url}/${entityId}/${folderName}/${id}`,
    successToastMsg: 'Deleted successfully!',
    useOptimisticUpdate: false,
    onDelete: onDeleteSuccess,
    headerKeys
  });

  const { data: fileDownloadUrl, isLoading: loadingDownloadUrl } = useReactQuery<string>({
    url: `${url}/${entityId}/${folderName}/${id}`,
    headerKeys
  });

  const uploadedByInfo = useMemo(() => {
    return `Uploaded by ${recentHistory.createdByUser} on ${getDisplayDateTimeWithSlashFormatter(
      recentHistory.createdTime
    )}`;
  }, [recentHistory]);

  const onDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    deleteEntity({});
  };

  const { download, downloading } = useDownloadFile({ fileName });

  const onDownload = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();
      void download(fileDownloadUrl);
    },
    [fileDownloadUrl]
  );

  const onView = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();
      window.open(fileDownloadUrl);
    },
    [fileDownloadUrl]
  );

  return (
    <ListItemCnt onClick={onView}>
      <FileInfoCnt>
        {loadingDownloadUrl ? (
          <CircularProgress size={25} color="secondary" />
        ) : (
          <img src={fileDownloadUrl} alt={fileName} style={{ maxHeight: '48px' }} />
        )}
        <ProgressCnt>
          <FileName>{fileName}</FileName>
          <CreatedBy>{uploadedByInfo}</CreatedBy>
        </ProgressCnt>
      </FileInfoCnt>
      <CancelIconCnt>
        <IconButton onClick={onDownload} disabled={loadingDownloadUrl}>
          {downloading ? (
            <SmallCircularProgress size={theme.spacing(3)} />
          ) : (
            <DownloadIcon data-testid="download-file-icon" />
          )}
        </IconButton>
        <IconButton onClick={onDelete} disabled={isDeleting || loadingDownloadUrl}>
          {isDeleting ? (
            <SmallCircularProgress size={theme.spacing(3)} />
          ) : (
            <DeleteIcon data-testid="delete-file-icon" />
          )}
        </IconButton>
      </CancelIconCnt>
    </ListItemCnt>
  );
};

export { ListItem };
