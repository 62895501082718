import { WORDS } from '@linx-ui/shared/utils';

const validationRules = {
  text: {
    required: { value: true, message: 'Please enter note' },
    maxLength: 1000,
    pattern: {
      value: new RegExp(WORDS, 'm'),
      message: 'Please enter valid note'
    }
  }
};

export { validationRules };
