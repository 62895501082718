import { memo, type FC } from 'react';
import { type Notification } from '../types';
import { NotificationItemCnt } from './NotificationItem.styled';
import { PrescriptionNotification } from './prescriptionNotification';
import { SystemNotification } from './systemNotification';
import { useNotificationUpdateStatus } from './useNotificationUpdateStatus';

export const NotificationItem: FC<{ notification: Notification }> = memo(({ notification }) => {
  const mutate = useNotificationUpdateStatus(notification.id, notification.type);

  const markAsRead = () => !notification.isRead && mutate({ action: 'READ' });

  return (
    <NotificationItemCnt onClick={markAsRead}>
      {notification.type === 'PRESCRIPTION' ? (
        <PrescriptionNotification notification={notification} />
      ) : (
        <SystemNotification notification={notification} />
      )}
    </NotificationItemCnt>
  );
});
NotificationItem.displayName = 'NotificationItem';
