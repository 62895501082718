import { type FC } from 'react';
import { BoldSpaced, TimeStampIcon, TimeStampText } from '@linx-ui/shared/components/styles';
import { getDateTimeDisplayValue } from '@linx-ui/shared/utils';
import { type UpdateDetailsProps } from './types';

const UpdateDetails: FC<UpdateDetailsProps> = ({ hasTimestamp, hasUser, data }: UpdateDetailsProps) => {
  return (
    <TimeStampText component="div">
      {hasTimestamp && (
        <TimeStampText component="div">
          <TimeStampIcon fontSize="small" />
          <TimeStampText variant="caption" component="div">
            Last updated at
            <BoldSpaced>{data?.lastUpdatedTime ? getDateTimeDisplayValue(data?.lastUpdatedTime) : '-'}</BoldSpaced>
          </TimeStampText>
        </TimeStampText>
      )}
      {hasUser && (
        <TimeStampText component="div">
          <TimeStampText variant="caption" component="div">
            by
            <BoldSpaced>{data?.lastUpdatedByUser}</BoldSpaced>
          </TimeStampText>
        </TimeStampText>
      )}
    </TimeStampText>
  );
};

export { UpdateDetails };
