import { Tooltip } from '@mui/material';
import { memo, type FC } from 'react';
import { getInitials } from '@linx-ui/shared/utils';
import { UserAvatarStyled } from './UserAvatar.styled';

export const UserAvatar: FC<{ userName: string; size?: number }> = memo(({ userName, size = 3 }) => {
  return (
    <Tooltip title={userName} placement="bottom-start" arrow>
      <UserAvatarStyled size={size}>{userName ? getInitials(userName) : ''}</UserAvatarStyled>
    </Tooltip>
  );
});
UserAvatar.displayName = 'UserAvatar';
