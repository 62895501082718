import { Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import React from 'react';
import { TreeViewItemWithLeftBorder } from '@linx-ui/shared/components/treeViewItemWithLeftBorder';
import { pascalToCapitalised, type ParsedNode } from '@linx-ui/shared/utils';
import { TextWrapper } from './styles';

const CreateTreeView: React.FC<{
  node: ParsedNode;
  onExpandedItemsChange: (event: React.SyntheticEvent, itemIds: string[]) => void;
  expandedItems?: string[];
}> = ({ node, onExpandedItemsChange, expandedItems }) => {
  return (
    <SimpleTreeView onExpandedItemsChange={onExpandedItemsChange} expandedItems={expandedItems}>
      <CreateTreeViewItem node={node} depth={node.name} />
    </SimpleTreeView>
  );
};

const CreateTreeViewItem = ({ node, depth }: { node: ParsedNode; depth: string }) => {
  return (
    <TreeViewItemWithLeftBorder itemId={depth} label={pascalToCapitalised(node.name)}>
      {node?.children?.map((childNode: ParsedNode, index) => {
        if (childNode?.children?.length) {
          return (
            <CreateTreeViewItem
              key={depth + '.' + childNode.name + index}
              node={childNode}
              depth={depth + '.' + childNode.name + index}
            />
          );
        }
        return (
          <TextWrapper key={`${depth}-${node.name}-${childNode.name}-info`}>
            <Typography display="inline" variant="subtitle1">
              {`${pascalToCapitalised(childNode.name)} : `}
            </Typography>
            <Typography variant="subtitle2" display="inline">
              {childNode.value}
            </Typography>
          </TextWrapper>
        );
      })}
    </TreeViewItemWithLeftBorder>
  );
};
export { CreateTreeView };
