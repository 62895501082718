export const USER_TYPES = {
  CONTRACT_ADMIN: 'ContractAdmin',
  GENERAL_USER: 'GeneralUser',
  LINX_ADMIN: 'LinxAdmin',
  PHARMACIST: 'Pharmacist',
  PHARMACY_ADMIN: 'PharmacyAdmin',
  TECHNICIAN: 'Technician'
} as const;

export type UserTypes = (typeof USER_TYPES)[keyof typeof USER_TYPES];
