import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Greeting } from '../greeting';

export const UserGreeting = () => {
  const [user, setUser] = useState<string>('');
  const [month, setMonth] = useState('');
  const [dayOfMonth, setDayOfMonth] = useState('');
  const [greetingTimeOfDay, setGreetingTimeOfDay] = useState('');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const { user: userDetails } = useAuth0();

  const nthNumber = (number: number) => {
    if (number > 3 && number < 21) return 'th';
    switch (number % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const getDateDetails = () => {
    const d = new Date();
    const day = weekday[d.getDay()];
    setDayOfWeek(day);

    const date = new Date();
    setMonth(date.toLocaleString('default', { month: 'long' }));

    setDayOfMonth(d.getDate() + nthNumber(d.getDate()));
  };

  const getTimeOfDay = () => {
    const d = new Date();
    const t = d.getHours();
    if (t < 12) {
      setGreetingTimeOfDay('morning');
    } else if (t > 12 && t < 18) {
      setGreetingTimeOfDay('afternoon');
    } else {
      setGreetingTimeOfDay('evening');
    }
  };

  useEffect(() => {
    setUser(
      userDetails?.nickname?.split('.')[0]
        ? userDetails?.nickname
            ?.split('.')[0]
            .charAt(0)
            .toUpperCase()
            .concat(userDetails?.nickname?.split('.')[0].slice(1))
        : ''
    );
    getDateDetails();
    getTimeOfDay();
  });

  return (
    <Greeting
      title={`Good ${greetingTimeOfDay}, ${user}!`}
      subTitle={`${dayOfWeek}, ${month} ${dayOfMonth}`}
    ></Greeting>
  );
};
