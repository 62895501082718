import { Grid } from '@mui/material';
import { Children, type FC } from 'react';
import { type Props } from './types';

const GridLayoutItem: FC<Props> = ({ children, ...rest }: Props) => {
  const items = Children.toArray(children);
  return (
    <Grid item {...rest}>
      {items.map((mItem) => {
        return mItem;
      })}
    </Grid>
  );
};

const getGridItems = ({ children, xs, sm, md, lg, xl, ...rest }: Props) => {
  const items = Children.toArray(children);
  return (
    <GridLayoutItem xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...rest}>
      {items.map((mItem) => {
        return mItem;
      })}
    </GridLayoutItem>
  );
};

const GridLayoutItemWithXs12sm12md12: FC<Props> = ({ children, xs = 12, sm = 12, md = 12, ...rest }: Props) => {
  return getGridItems({ children, xs, sm, md, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg4xl4: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 4,
  xl = 4,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg3xl3: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 3,
  xl = 3,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg2xl2: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 2,
  xl = 2,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm6md6lg2xl2: FC<Props> = ({
  children,
  xs = 12,
  sm = 6,
  md = 6,
  lg = 2,
  xl = 2,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg6xl6: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 6,
  xl = 6,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg8xl8: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 8,
  xl = 8,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg5xl5: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 5,
  xl = 5,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg7xl7: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 7,
  xl = 7,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg10xl8: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 10,
  xl = 8,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg10xl9: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 10,
  xl = 9,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};
const GridLayoutItemWithXs12sm12md12lg12xl12: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 12,
  xl = 12,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

const GridLayoutItemWithXs12sm12md12lg9xl9: FC<Props> = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 9,
  xl = 9,
  ...rest
}: Props) => {
  return getGridItems({ children, xs, sm, md, lg, xl, ...rest });
};

export {
  GridLayoutItem,
  GridLayoutItemWithXs12sm12md12,
  GridLayoutItemWithXs12sm12md12lg10xl8,
  GridLayoutItemWithXs12sm12md12lg10xl9,
  GridLayoutItemWithXs12sm12md12lg12xl12,
  GridLayoutItemWithXs12sm12md12lg2xl2,
  GridLayoutItemWithXs12sm12md12lg3xl3,
  GridLayoutItemWithXs12sm12md12lg4xl4,
  GridLayoutItemWithXs12sm12md12lg5xl5,
  GridLayoutItemWithXs12sm12md12lg6xl6,
  GridLayoutItemWithXs12sm12md12lg7xl7,
  GridLayoutItemWithXs12sm12md12lg8xl8,
  GridLayoutItemWithXs12sm12md12lg9xl9,
  GridLayoutItemWithXs12sm6md6lg2xl2
};
