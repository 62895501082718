import { Notifications } from '@mui/icons-material';
import { styled } from '@mui/material';

export const NotificationCnt = styled('div')(() => ({
  height: '100%'
}));

export const NotificationItemsCnt = styled('div')(() => ({
  height: `calc(100% - 120px)`
}));

export const NotificationIcon = styled(Notifications, {
  shouldForwardProp: (prop) => prop !== 'vibrate'
})<{ vibrate?: boolean }>(({ vibrate }) => ({
  '@keyframes bellshake': {
    '0%': { transform: 'rotate(0)' },
    '15%': { transform: 'rotate(10deg)' },
    '30%': { transform: 'rotate(-10deg)' },
    '45%': { transform: 'rotate(8deg)' },
    '60%': { transform: 'rotate(-8deg)' },
    '75%': { transform: 'rotate(4deg)' },
    '85%': { transform: 'rotate(-4deg)' },
    '92%': { transform: 'rotate(2deg)' },
    '100%': { transform: 'rotate(0)' }
  },
  animation: vibrate ? 'bellshake .5s cubic-bezier(.36,.07,.19,.97) both' : 'none'
}));
