import { ArticleOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { memo, type FC } from 'react';
import { PRESCRIPTION_CATEGORY_MAPPING } from '@linx-ui/shared/components/notifications/constants';
import type { PrescriptionNotification } from '@linx-ui/shared/components/notifications/types';
import { UserAvatar } from '@linx-ui/shared/components/userAvatar';
import {
  Dot,
  NotificationActions,
  NotificationContentCnt,
  NotificationIconCnt,
  NotificationItemTopCnt,
  NotificationItemTopMiddleCnt,
  NotificationThemedText,
  NotificationTimestamp,
  TypeAndTimeCnt,
  UnreadDot
} from '../../components';

export const RejectionNotification: FC<{
  notification: Extract<PrescriptionNotification, { category: 'REJECTION' }>;
}> = memo(({ notification }) => {
  const { rxNumber, fillNumber, rejectedBy, prescriptionId } = notification.metadata.eventPayload;
  const rxDetailsLink = `/rx/pharmacy-sc/queues/filling/${prescriptionId}/details`;

  return (
    <>
      <NotificationItemTopCnt isRead={notification.isRead}>
        <NotificationIconCnt color="warning">
          <ArticleOutlined />
        </NotificationIconCnt>
        <NotificationItemTopMiddleCnt>
          <TypeAndTimeCnt>
            <NotificationThemedText color="warning">{PRESCRIPTION_CATEGORY_MAPPING.REJECTION}</NotificationThemedText>
            <Dot color="warning" />
            <NotificationTimestamp timestamp={notification.createdTime} />
          </TypeAndTimeCnt>
          <NotificationContentCnt>
            <Typography>RX#</Typography>
            <Typography fontWeight="bold">{`${rxNumber}|${fillNumber}`}</Typography>
            <Typography>verification has been rejected by</Typography>
            <UserAvatar userName={rejectedBy} />
          </NotificationContentCnt>
        </NotificationItemTopMiddleCnt>
        {!notification.isRead && <UnreadDot data-testid={`${notification.id}-unread-dot`} color="warning" />}
      </NotificationItemTopCnt>
      <NotificationActions color="warning" rxDetailsLink={rxDetailsLink} notification={notification} />
    </>
  );
});
RejectionNotification.displayName = 'RejectionNotification';
