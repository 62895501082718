import { Alert } from '@mui/material';
import { AlertWrapper } from './AlertMessage.styled';
import type { AlertMessageProps } from './types';

const AlertMessage = ({
  alertText,
  severity = 'warning',
  width = 'max-content',
  minWidth = 'max-content'
}: AlertMessageProps): JSX.Element => (
  <AlertWrapper width={width} minWidth={minWidth}>
    <Alert severity={severity}>{alertText}</Alert>
  </AlertWrapper>
);

export { AlertMessage };
