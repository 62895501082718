import { type ColumnDef } from '@tanstack/react-table';
import moment from 'moment-timezone';
import { camelCaseToSpaceSeperated, dashYYYYMMDDToSlashMMDDYYYY, getDateTimeDisplayValue } from '@linx-ui/shared/utils';
import { type History } from './types';

const yyyymmddFormatColumns = ['dateOfBirth'];

const timestampColumns = ['createdTime', 'lastUpdatedTime'];

const columns: Array<ColumnDef<History>> = [
  {
    accessorKey: 'eventTime',
    cell: (info) => getDateTimeDisplayValue(info.getValue() as string),
    header: 'Last updated at',
    size: 140
  },
  {
    accessorKey: 'displayName',
    header: 'Changed By',
    size: 180
  },
  { accessorKey: 'eventType', header: 'Operation', size: 100 },
  {
    accessorFn: (row) => camelCaseToSpaceSeperated(row.propertyName),
    header: 'Field Name',
    size: 180
  },
  {
    accessorFn: (row) => {
      if (row.oldValue && timestampColumns.includes(row.propertyName)) {
        return moment(row.oldValue).format('lll');
      } else if (row.oldValue && yyyymmddFormatColumns.includes(row.propertyName)) {
        return dashYYYYMMDDToSlashMMDDYYYY(row.oldValue);
      } else {
        return row.oldValue;
      }
    },
    header: 'Old Value',
    size: 180
  },
  {
    accessorFn: (row) => {
      if (row.newValue && timestampColumns.includes(row.propertyName)) {
        return moment(row.newValue).format('lll');
      } else if (row.newValue && yyyymmddFormatColumns.includes(row.propertyName)) {
        return dashYYYYMMDDToSlashMMDDYYYY(row.newValue);
      } else {
        return row.newValue;
      }
    },
    header: 'New Value',
    size: 180
  }
];

export { columns };
