import { useEffect, useState } from 'react';
import { getPharmacyId } from '@linx-ui/shared/utils';

const usePharmacyId = () => {
  const [pharmacyId, setPharmacyId] = useState<string | null>(getPharmacyId());

  useEffect(() => {
    const handlePharmacyChange = () => {
      const latestPharmacyId = getPharmacyId();
      setPharmacyId(latestPharmacyId);
    };

    window.addEventListener('pharmacy-change', handlePharmacyChange);

    return () => {
      window.removeEventListener('pharmacy-change', handlePharmacyChange);
    };
  }, []);

  return { pharmacyId };
};

export { usePharmacyId };
