import { Grid } from '@mui/material';
import { memo } from 'react';
import { GridLayoutItem } from '@linx-ui/shared/components/gridLayout';
import { GroupStyled, RowStyled } from '../details/Details.styled';
import { type Action, type ActionRendererProps } from './types';

const _ActionRenderer = ({ actions }: ActionRendererProps) => {
  return (
    <GridLayoutItem container xs={12} md={12} alignItems="center" justifyContent="flex-end" gap="16px">
      {actions?.map((row, rowIndex: number) => (
        <RowStyled container item key={`action-row-${rowIndex}`} justifyContent="flex-end">
          {row.map((group, groupIndex: number) => (
            <GroupStyled key={`action-row-group-${groupIndex}`} last={groupIndex === row.length - 1}>
              {group.map((action: Action) => (
                <Grid key={action.key}>{action.element}</Grid>
              ))}
            </GroupStyled>
          ))}
        </RowStyled>
      ))}
    </GridLayoutItem>
  );
};

export const ActionRenderer = memo(_ActionRenderer) as typeof _ActionRenderer;
