import { Typography } from '@mui/material';
import { LabelStyled, LabelValueContainer, ValueStyled } from './LabelValueFrame.styled';
import type { LabelValueFrameProps } from './types';

const LabelValueFrame = ({ label, value }: LabelValueFrameProps) => {
  return (
    <LabelValueContainer>
      <LabelStyled>
        <Typography variant="body1">{label}</Typography>
      </LabelStyled>
      <ValueStyled>
        <Typography variant="body1">{value ?? '-'}</Typography>
      </ValueStyled>
    </LabelValueContainer>
  );
};

export { LabelValueFrame };
