import set from 'lodash/set';
import { Suspense, type ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { CircularLoader } from '@linx-ui/shared/components/loaders';

interface NestedRouteObject {
  value: ReactElement;
  [key: string]: NestedRouteObject | ReactElement;
}

const routeObjToFragments = (routeObj: NestedRouteObject): ReactElement => {
  const paths = Object.keys(routeObj);
  if (paths.length === 1 && paths[0] === 'value') {
    return <Route path={paths[0]} element={<Suspense fallback={<CircularLoader />}>{routeObj.value}</Suspense>} />;
  }
  return (
    <>
      {Object.keys(routeObj)
        .filter((path) => path !== 'value' && path !== '{fallback}')
        .map((path) => {
          return (
            <Route
              key={path}
              path={path}
              element={<Suspense fallback={<CircularLoader />}>{(routeObj[path] as NestedRouteObject).value}</Suspense>}
            >
              {routeObjToFragments(routeObj[path] as NestedRouteObject)}
            </Route>
          );
        })}
      {Object.keys(routeObj)
        .filter((path) => path === '{fallback}')
        .map((path) => {
          return (
            <Route
              key={path}
              path=""
              element={<Suspense fallback={<CircularLoader />}>{(routeObj[path] as NestedRouteObject).value}</Suspense>}
            />
          );
        })}
    </>
  );
};

export const routesConfigToRoutes = (routesObj: Record<string, ReactElement>) => {
  const newRoutesObj = Object.keys(routesObj).reduce((acc: Record<string, NestedRouteObject>, key) => {
    const objPath = key
      .split('/')
      .filter((el) => el !== '')
      .join('.');
    set(acc, `${objPath}.value`, routesObj[key]);
    return acc;
  }, {});
  return routeObjToFragments(newRoutesObj as NestedRouteObject);
};
