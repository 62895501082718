import { AccountCircle, ArrowBack } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { pharmacyIdKey } from '@linx-ui/shared/constants';
import { useGlobalContext } from '@linx-ui/shared/context';
import { useSelectedPharmacist } from '@linx-ui/shared/hooks/useSelectedPharmacist';
import { useUserTypes } from '@linx-ui/shared/hooks/useUserTypes';
import { isLinxAdmin, removeFromLocalStorage } from '@linx-ui/shared/utils';
import { useLogout, usePharmacyId } from '@linx-ui/shared/hooks';
import { MenuItemStyled, MenuStyled } from '../styles';
import { UserMenuItem } from './UserMenuItem';

const Account = ({
  hideBackToAdminConsole = false,
  hidePharmacyProfile = false
}: {
  hideBackToAdminConsole?: boolean;
  hidePharmacyProfile?: boolean;
}) => {
  const { user } = useAuth0();
  const { pathname } = useLocation();
  const logout = useLogout();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const { getUserTypeName } = useUserTypes();
  const { pharmacist } = useSelectedPharmacist();
  const { auth } = useGlobalContext();
  const linxAdmin = isLinxAdmin(auth);

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    handleMenuClose();
  };

  const backToAdminConsole = () => {
    removeFromLocalStorage(pharmacyIdKey);
    navigate('/rx/admin-sc');
  };

  const onProfileClick = () => {
    navigate(`/rx/${pathname.split('/')[2]}/profile`);
    handleMenuClose();
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const currentPharmacyId = usePharmacyId();

  const showPharmacyProfile = () => {
    navigate(`/rx/pharmacy-sc/pharmacy-details/${currentPharmacyId.pharmacyId}/details`);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        edge="end"
        aria-label="open account button"
        onClick={handleOpen}
        data-testid="open-account-btn"
      >
        <AccountCircle />
      </IconButton>
      <MenuStyled
        anchorEl={anchorEl}
        aria-label="account menu"
        data-testid="account-menu"
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <UserMenuItem
          name={user?.name}
          data-testid="user-name"
          type={isLinxAdmin(auth) ? 'LinxAdmin' : getUserTypeName(auth?.typeId)}
          onClick={linxAdmin ? undefined : onProfileClick}
        />
        {pharmacist && getUserTypeName(auth?.typeId) !== getUserTypeName(pharmacist?.typeId) && (
          <UserMenuItem
            name={`${pharmacist.firstName} ${pharmacist.lastName}`}
            type={getUserTypeName(pharmacist?.typeId)}
          />
        )}
        {!hideBackToAdminConsole && (
          <MenuItemStyled data-testid="back-to-admin-console-btn" onClick={backToAdminConsole}>
            <Typography variant="body2">
              <Button size="small" color="inherit" startIcon={<ArrowBack />}>
                Back to Admin Console
              </Button>
            </Typography>
          </MenuItemStyled>
        )}

        {!hidePharmacyProfile && (
          <MenuItemStyled data-testid="ph-profile-btn" onClick={showPharmacyProfile}>
            <Button size="small" color="inherit">
              Pharmacy Profile
            </Button>
          </MenuItemStyled>
        )}

        <MenuItemStyled data-testid="logout-btn" onClick={handleLogout}>
          <Button size="small" color="inherit">
            Log out
          </Button>
        </MenuItemStyled>
      </MenuStyled>
    </>
  );
};

export { Account };
