import { Box, styled } from '@mui/material';

const LabelValueContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '32px'
}));

const LabelStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderRadius: theme.spacing(0.5, 0, 0, 0.5),
  padding: theme.spacing(0.5, 1, 0.5, 1)
}));

const ValueStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(0, 0.5, 0.5, 0),
  padding: theme.spacing(0.5, 1, 0.5, 1),
  color: theme.palette.text.primary
}));

export { LabelStyled, ValueStyled, LabelValueContainer };
