import { styled } from '@mui/material';

export const CenterAlignedCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

export const NotificationHeaderCnt = styled(CenterAlignedCnt)(({ theme }) => ({
  padding: theme.spacing(3, 3, 0, 3),
  justifyContent: 'space-between'
}));
