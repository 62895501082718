import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useEffect, useRef, useState, type FC } from 'react';
import { Controller } from 'react-hook-form';
import { useAddressAutosuggestions } from '../hooks';
import { type Props } from './types';

export const AddressAutocomplete: FC<Props> = ({
  name,
  control,
  rules,
  value,
  reset,
  testId,
  error,
  onFocus,
  label,
  customAddressUpdator,
  disabled
}) => {
  const { addressSuggetions, addressDetails, getAddressSuggestions, onAddressSelection, isLoading } =
    useAddressAutosuggestions();
  const [inputValue, setInputValue] = useState(value ?? '');
  const isMounted = useRef(false);

  const getOptionLabel = (option: string) => addressSuggetions.find((o) => o.PlaceId === option)?.Text ?? '';

  const onInputChange = (value: string) => {
    setInputValue(value);
    getAddressSuggestions(value);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (customAddressUpdator) {
        customAddressUpdator(addressDetails);
      } else {
        reset((formValues) => {
          return {
            ...formValues,
            address: { ...formValues.address, ...addressDetails }
          };
        });
      }

      setInputValue(addressDetails.addressLine1);
    } else {
      isMounted.current = true;
    }
  }, [addressDetails]);

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={inputValue}
      render={({ field: { onChange, value, ...rest } }) => (
        <Autocomplete
          data-testid={testId}
          autoComplete
          {...rest}
          options={addressSuggetions.map((option) => option.PlaceId) as string[]}
          freeSolo
          forcePopupIcon
          inputValue={inputValue}
          onInputChange={(_, value) => {
            onInputChange(value);
            onChange(value);
          }}
          getOptionLabel={getOptionLabel}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              error={error?.hasError}
              onFocus={() => onFocus?.()}
              helperText={error?.message}
              label={label}
              required={!!rules?.required}
              disabled={disabled}
            />
          )}
          onChange={(_, data) => {
            onAddressSelection(data!);
          }}
        />
      )}
    />
  );
};
