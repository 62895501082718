import { EIGHT_DIGIT_2_DECIMAL_PLACES } from './regex';

export const eightDigitTwoDecimalValidation = (value: string | number, message: string) => {
  if (value) {
    const tempVal = String(value).replace(/^0+/, '');
    if (!EIGHT_DIGIT_2_DECIMAL_PLACES.test(tempVal)) {
      return message;
    }
  }
  return true;
};
