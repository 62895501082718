export {};

declare global {
  interface Number {
    roundUpto: (decimals: number) => number;
    asPrice: (decimals?: number) => string;
  }
}

Number.prototype.roundUpto = function (this: number, decimals: number): number {
  const factor = 10 ** decimals;
  return Math.round(this * factor) / factor;
};

Number.prototype.asPrice = function (this: number, decimals = 2): string {
  return this.toFixed(decimals);
};
