export const hasHighlightedErrors = (touchedFields: any, errors: any): boolean => {
  if (!errors) return false;

  for (const k in touchedFields) {
    if (touchedFields[k] === true && !!errors[k]) return true;
    else if (touchedFields[k] === true && !errors[k]) continue;
    else return hasHighlightedErrors(touchedFields[k], errors[k]);
  }

  return false;
};
