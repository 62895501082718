import { ErrorOutline } from '@mui/icons-material';
import { MuiMarkdown } from 'mui-markdown';
import { memo, type FC } from 'react';
import { SYSTEM_CATEGORY_MAPPING } from '../../constants';
import { NotificationTimestamp } from '../components/notificationTimestamp';
import type { Notification } from '../../types';
import {
  Dot,
  NotificationIconCnt,
  NotificationItemTopCnt,
  NotificationItemTopMiddleCnt,
  NotificationThemedText,
  TypeAndTimeCnt,
  UnreadDot
} from '../components';

export const SystemNotification: FC<{ notification: Extract<Notification, { type: 'SYSTEM' }> }> = memo(
  ({ notification }) => {
    return (
      <NotificationItemTopCnt isRead={notification.isRead}>
        <NotificationIconCnt color="secondary">
          <ErrorOutline />
        </NotificationIconCnt>
        <NotificationItemTopMiddleCnt>
          <TypeAndTimeCnt>
            <NotificationThemedText color="secondary">
              {SYSTEM_CATEGORY_MAPPING[notification.category]}
            </NotificationThemedText>
            <Dot />
            <NotificationTimestamp timestamp={notification.createdTime} />
          </TypeAndTimeCnt>
          <MuiMarkdown>{notification.content}</MuiMarkdown>
        </NotificationItemTopMiddleCnt>
        {!notification.isRead && <UnreadDot data-testid={`${notification.id}-unread-dot`} color="secondary" />}
      </NotificationItemTopCnt>
    );
  }
);
SystemNotification.displayName = 'SystemNotification';
