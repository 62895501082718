import { Divider, styled } from '@mui/material';

const DividerStyled = styled(Divider)(({ theme }) => {
  return {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  };
});

export { DividerStyled };
