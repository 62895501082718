import { useMemo } from 'react';
import type { Pharmacy } from '@linx-ui/shared/types';
import { useGlobalContext } from '@linx-ui/shared/context';
import { usePermissions } from '@linx-ui/shared/core';
import type { UseReactQueryParams } from '@linx-ui/shared/hooks/api/useReactQuery/types';
import { isLinxAdmin } from '@linx-ui/shared/utils';
import { useAllPharmacies } from '../useAllPharmacies';

type Options = Partial<UseReactQueryParams<Pharmacy[]>>;

export const usePharmacies = (options: Options = {}) => {
  const { enabled = true } = options;
  const { auth } = useGlobalContext();
  const isLinxAdminUser = isLinxAdmin(auth);
  const allPharmaciesResult = useAllPharmacies({ enabled });
  const { authorizedPharmacies } = usePermissions();
  const userAssignedPharmacies: Pharmacy[] = useMemo(
    () =>
      allPharmaciesResult.pharmacies?.filter(
        (pharmacy: Pharmacy) => pharmacy.id && (authorizedPharmacies || []).includes(pharmacy.id)
      ) ?? [],
    [authorizedPharmacies, allPharmaciesResult.pharmacies]
  );

  if (isLinxAdminUser) {
    return allPharmaciesResult;
  }

  return {
    ...allPharmaciesResult,
    pharmacies: userAssignedPharmacies
  };
};
