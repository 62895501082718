import { createTheme } from '@mui/material';
import { createStyled } from '@mui/system';

const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  },
  palette: {
    mode: 'light',
    text: {
      primary: '#303C48',
      secondary: '#607890',
      disabled: '#B0BCC8'
    },
    primary: {
      main: '#6E14FF',
      dark: '#4D0EB3',
      light: '#8B43FF',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#1F89F2',
      dark: '#1660A9',
      light: '#4CA1F5',
      contrastText: '#FFFFFF'
    },
    background: {
      paper: '#FFFFFF',
      default: '#F7F8F9',
      lighter: '#EDF6FE',
      darker: '#E9EFF8'
    },
    action: {
      active: '#303C48',
      hover: '#F7F8F9'
    },
    divider: '#DFE4E9'
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#FFF'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: '14px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: 8,
            height: 8
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: 'transparent'
          },
          '&:hover::-webkit-scrollbar-thumb, & *:hover::-webkit-scrollbar-thumb': {
            backgroundColor: '#DFE4E9'
          },
          '&:hover::-webkit-scrollbar-thumb:hover, & *:hover::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#B0BCC8'
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  }
});

const darkTheme = createTheme({
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700
  },
  palette: {
    mode: 'dark',
    background: {
      lighter: '#121212'
    }
  }
});

const styled = createStyled({ defaultTheme: theme });

export { darkTheme, styled, theme };
